import { ReactElement } from 'react';

import { DocumentDownloadRowModel, DocumentDownloadTable } from '@halo-common/components';
import { useCsvCreator } from '@halo-common/hooks';
import { DocumentModel, PortfolioOrderEvaluationDataPoint } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useDownloadS3DocumentMutation, useGenerateHistoricalReportMutation } from '@halo-data-sources/mutations';
import {
  usePortfolioOrderEvaluationQuery,
  useTermsheetDownloadLinkQuery,
  useUserInfoQuery,
} from '@halo-data-sources/queries';
import { DateTime } from 'luxon';

export type V2DocumentTableRow = {
  id: number;
  name: string;
  path: string;
  isDocument: boolean;
  isTermsheet: boolean;
  isValuation: boolean;
};

export type V2PostTradeNoteDetailModalDocumentTableProps = {
  account?: AccountTypeAheadOption | null;
  currency?: number;
  identifierText: string;
  identifier: string;
  termsheetId?: number;
  documents?: Array<DocumentModel>;
};

export const V2PostTradeNoteDetailModalDocumentTable = ({
  account,
  currency,
  identifierText,
  termsheetId,
  identifier,
  documents = [],
}: V2PostTradeNoteDetailModalDocumentTableProps): ReactElement => {
  const { data: user } = useUserInfoQuery();
  const { data: termSheetLink, isPending: termSheetLinkLoading } = useTermsheetDownloadLinkQuery(termsheetId);

  const { mutate: generateReportMutation, isPending: downloadHistory } = useGenerateHistoricalReportMutation();
  const { mutate: downloadDoc } = useDownloadS3DocumentMutation();

  const currencies = user?.whiteLabel.currencies ?? [];

  const handleSubmit = () => {
    const currencyId = currency ?? currencies[0]?.id;

    generateReportMutation({
      termsheetId,
      accountId: account?.account?.id,
      adviseeId: account?.household?.id,
      reportingCurrencyId: currencyId,
      productDescriptor: identifierText,
      assetIdentifier: identifier,
      userId: user?.details.id,
    });
  };

  const { data: evaluation } = usePortfolioOrderEvaluationQuery(termsheetId);
  const csvTitle = identifierText ? `${identifierText} Valuations` : 'Note Valuations';
  const { exportCsv } = useCsvCreator<PortfolioOrderEvaluationDataPoint>({
    fileName: csvTitle,
    data: evaluation,
    columns: [
      { field: 'x', name: 'DATE', mapper: (column) => DateTime.fromSeconds(column.x).toISODate() },
      { field: 'Note Value', name: 'VALUE' },
    ],
    appendDate: true,
  });

  const docRows = documents.map((doc) => {
    return {
      id: doc.id,
      name: doc.name,
      onDownload: () => downloadDoc(doc),
    };
  });

  const rows: Array<DocumentDownloadRowModel> = [
    {
      id: 1,
      name: translations.pdm.postTrade.documentNameTermsheet,
      onDownload: () => {
        if (termSheetLink) window.parent.open(termSheetLink);
      },
    },
    {
      id: 2,
      name: translations.pdm.postTrade.documentNameValuationHistory,
      onDownload: () => void exportCsv(),
    },
    {
      id: 3,
      name: translations.pdm.postTrade.documentNameHistoricalReport,
      onDownload: handleSubmit,
      downloading: downloadHistory,
    },
    ...docRows,
  ];

  return <DocumentDownloadTable loading={termSheetLinkLoading} documents={rows} />;
};

import { ReactElement, useEffect } from 'react';

import { isNewOrgAtom, userCreationOrganizationAtom, userCreationStepAtom } from '@halo-atoms/userCreation';
import { OrganizationModel } from '@halo-common/models';
import { SelectOrgFormFields } from '@halo-common/schemas';
import { OrganizationTypeAhead } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Divider, Stack } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

const createOrgButtonSx = {
  width: 'fit-content',
};

export const SelectOrCreateOrg = (): ReactElement => {
  const handleNext = useSetAtom(userCreationStepAtom.handleNextAtom);
  const [selectedOrg, setSelectedOrg] = useAtom(userCreationOrganizationAtom);
  const setIsNewOrg = useSetAtom(isNewOrgAtom);
  const selectOrgFormMethods = useFormContext<SelectOrgFormFields>();

  const { setValue, formState } = selectOrgFormMethods;
  const { errors } = formState;

  const organizationError = errors?.organization?.message;
  const hasOrganizationError = Boolean(organizationError);

  const textFieldProps = { error: hasOrganizationError, helperText: organizationError };

  const handleChange = (organization: OrganizationModel | null) => {
    setValue('organization', organization, { shouldDirty: true, shouldValidate: true });
    setSelectedOrg(organization);
    setIsNewOrg(false);
  };

  useEffect(() => {
    if (selectedOrg) {
      setValue('organization', selectedOrg, { shouldDirty: true, shouldValidate: true });
    }
  }, [selectedOrg]);

  return (
    <Stack direction="column" spacing={2}>
      <OrganizationTypeAhead onChange={handleChange} TextFieldProps={textFieldProps} defaultValue={selectedOrg} />
      <Divider />
      <Stack direction="column" spacing={1}>
        <LocalizedTypography variant="overline" color="text.secondary">
          {translations.common.or}
        </LocalizedTypography>
        <LocalizedButton
          sx={createOrgButtonSx}
          variant="outlined"
          color="primary"
          onClick={handleNext}
          startIcon={<Iconography iconName="building" />}
        >
          {translations.userAndOrgCreation.createNewOrg}
        </LocalizedButton>
      </Stack>
    </Stack>
  );
};

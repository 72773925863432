import { createdUsersAtom, userCreationOrganizationAtom } from '@halo-atoms/userCreation';
import { UserCreatedModel, OrganizationModel } from '@halo-common/models';
import { CreateUsersFormFields } from '@halo-common/schemas';
import { createUsers } from '@halo-data-sources/clients';
import { FailedUserCreationModel } from '@halo-data-sources/index';
import { ApiUserMapper, UserMapper } from '@halo-data-sources/mappers';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';

export type CreateUsersPayload = () => void;

export type CreatUsersMutationResult = {
  newUsers: Array<UserCreatedModel>;
  failedUserCreations: Array<FailedUserCreationModel>;
};

const createUsersMutationFn = async (payload?: CreateUsersFormFields, org?: OrganizationModel | null) => {
  if (!payload || !org) return null;

  const users = UserMapper.toApiUserCreationUserModel(payload, org);
  const request = { users: [users] };

  const response = await createUsers(request);

  const failedUserCreations = response.failed_user_creations;
  const newUsers = response.new_users.map(({ user_info }) => ApiUserMapper.toNewUserModel(user_info));

  if (failedUserCreations.length > 0 && newUsers.length === 0) throw new Error(failedUserCreations[0]?.error);

  return { newUsers, failedUserCreations };
};

export const useCreateUsersMutation = (
  handleNext: CreateUsersPayload,
): UseMutationResult<CreatUsersMutationResult | null, Error, CreateUsersFormFields | undefined> => {
  const selectedOrganization = useAtomValue(userCreationOrganizationAtom);
  const { enqueueErrorEvent } = useSnackbar();
  const setCreatedUsers = useSetAtom(createdUsersAtom);

  return useMutation({
    mutationFn: (payload) => createUsersMutationFn(payload, selectedOrganization),
    onSuccess: (data) => {
      const newUsers = data?.newUsers ?? [];
      setCreatedUsers(newUsers);
      handleNext();
    },
    onError: (error) => enqueueErrorEvent({ message: error.message }),
  });
};

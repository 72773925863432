import { DEFAULT_INFINITE_QUERY_NEXT_PAGINATION } from '@halo-common/constants';
import { SortModelDirectionEnum, SortModelNullSortEnum } from '@halo-common/enums';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { InfinitePaginationModel, InfiniteQueryOptions, UserRelationshipManagerModel } from '@halo-common/models';
import { searchRelationshipManagers } from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { ApiRelationshipManagerMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { RelationshipManagerQueryKeyFactory } from '@halo-data-sources/queries';

export type GetRelationshipManagersNextPageParamModel = {
  pagination: InfinitePaginationModel;
};

export type GetRelationshipManagersInfiniteResult = {
  relationshipManagers: Array<UserRelationshipManagerModel>;
  pagination: InfinitePaginationModel;
};

const relationshipManagersInfiniteQueryFn = async (
  query?: string,
  pageParam = DEFAULT_INFINITE_QUERY_NEXT_PAGINATION,
) => {
  const request = {
    comparisons: [
      {
        field: 'relationship_managers.first_name',
        op: ApiComparisonOptionEnum.ILIKE,
        value: query ?? '',
      },
    ],
    page: pageParam.next,
    page_length: 20,
    sort: [
      {
        field: 'relationship_managers.first_name',
        direction: SortModelDirectionEnum.asc,
        nulls: SortModelNullSortEnum.last,
      },
    ],
  };

  const response = await searchRelationshipManagers(request);

  const relationshipManagers = response.results
    .map(ApiRelationshipManagerMapper.toRelationshipManager)
    .filter(Boolean) as Array<UserRelationshipManagerModel>;

  const mappedPagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { relationshipManagers, pagination: mappedPagination };
};

export const useRelationshipManagersInfiniteQuery = (
  payload?: string,
  options?: Partial<InfiniteQueryOptions<GetRelationshipManagersInfiniteResult>>,
) =>
  useHaloInfiniteQuery<GetRelationshipManagersInfiniteResult>({
    queryKey: RelationshipManagerQueryKeyFactory.infinite(payload),
    queryFn: ({ pageParam }) => relationshipManagersInfiniteQueryFn(payload, pageParam),
    ...options,
  });

import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type OrganizationClientQueryKeys = {
  all: QueryKeyFactoryFn;
  infinite: QueryKeyFactoryFn;
};

export const OrganizationClientQueryKeyFactory: OrganizationClientQueryKeys = {
  all: () => ['organizations'],
  infinite: (query) => [...OrganizationClientQueryKeyFactory.all(), 'infinite', query],
};

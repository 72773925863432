import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { useAtom } from 'jotai';

export type CurrencyFiltersProps = {
  tag: string;
  filterValues?: (string | number)[];
};

export const CurrencyFilters = ({ tag, filterValues }: CurrencyFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const { data: user } = useUserInfoQuery();

  const sideBarFilters = getSideBarFilters(tag);

  const currencies = sideBarFilters.currencies;
  const whiteLabelCurrencies = user?.whiteLabel.currencies;

  const handleCurrencyChange = (value: string) => {
    const isInFilter = currencies.includes(value);

    if (isInFilter) {
      const updatedCurrencies = currencies.filter((currency) => currency !== value);
      setSideBarFilters({ tag, currencies: updatedCurrencies });
    } else {
      const updatedCurrencies = [...currencies, value];
      setSideBarFilters({ tag, currencies: updatedCurrencies });
    }
  };

  const currencyCheckboxes = whiteLabelCurrencies
    ?.filter((currency) => filterValues?.includes(currency.id) ?? true)
    .map((currency) => ({
      isChecked: currencies.includes(currency.id.toString()),
      name: currency.code,
      value: currency.id.toString(),
    }));

  return (
    <CollapsibleFilters
      title={DynamicTypeFiltersSideBarTitlesDict.currency}
      walkMeClassName="wm-productshelf-filters-currency"
    >
      <GroupedCheckbox onChange={handleCurrencyChange} values={currencyCheckboxes} />
    </CollapsibleFilters>
  );
};

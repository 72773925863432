import { Input } from '@halo-common/components/FormBuilder';
import { DocusignBooleanEnum } from '@halo-common/enums/docusign';
import { translations } from '@halo-common/translations';
import { yesNoStep } from '@halo-modules/app/v2/onboarding/subPages/suitability/utils';

const suitability = translations.onboarding.suitability;

const conditionalYesContent = (
  <Input label={suitability.pleaseElaborate} name="compliance.sanctions_yes_text" multiline rows={4} />
);

export const complaintsSanctionsWarningsStep = yesNoStep({
  id: '2-5',
  title: suitability.complaintsSanctionsWarnings,
  field: 'compliance.sanctions_radio',
  conditionalYesContent,
  isCompleted: (formData) => {
    const compliance = formData?.compliance || {};
    return Boolean(
      compliance.sanctions_radio === DocusignBooleanEnum.NO ||
        (compliance.sanctions_radio === DocusignBooleanEnum.YES && compliance.sanctions_yes_text),
    );
  },
});

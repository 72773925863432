import { Input } from '@halo-common/components/FormBuilder';
import { DocusignComplianceEnum } from '@halo-common/enums/docusign';
import { translations } from '@halo-common/translations';
import { OnboardingSuitabilityForm, SuitabilityStepModel } from '@halo-modules/app/v2/onboarding/subPages/suitability';
import { LocalizedFormControlLabel } from '@halodomination/halo-fe-common';
import { RadioGroup, Radio } from '@mui/material';
import { Controller } from 'react-hook-form';

const suitability = translations.onboarding.suitability;

const FirmComplianceFunctionsForm = () => (
  <OnboardingSuitabilityForm>
    <Controller
      name="compliance.compliance_type_radio"
      render={({ field }) => {
        const value = field.value;
        const isOutsourced = value === DocusignComplianceEnum.OUTSOURCED;
        const outsourcedText = isOutsourced ? (
          <Input
            label={suitability.pleaseElaborateOnYourOutsorcedCompliance}
            name="compliance.compliance_type_outsourced_text"
            multiline
            rows={4}
            sx={{ mt: 1 }}
          />
        ) : null;
        return (
          <RadioGroup {...field}>
            <LocalizedFormControlLabel
              value={DocusignComplianceEnum.IN_HOUSE}
              control={<Radio />}
              label={suitability.inhouseCompliance}
            />
            <LocalizedFormControlLabel
              value={DocusignComplianceEnum.OUTSOURCED}
              control={<Radio />}
              label={suitability.outsourcedCompliance}
            />
            {outsourcedText}
          </RadioGroup>
        );
      }}
    />
  </OnboardingSuitabilityForm>
);

export const complianceFunctionsStep: SuitabilityStepModel = {
  id: '2-1',
  title: suitability.complianceFunctions,
  component: <FirmComplianceFunctionsForm />,
  isCompleted: (formData) => {
    const compliance = formData?.compliance || {};
    return Boolean(
      compliance.compliance_type_radio === DocusignComplianceEnum.IN_HOUSE ||
        (compliance.compliance_type_radio === DocusignComplianceEnum.OUTSOURCED &&
          compliance.compliance_type_outsourced_text),
    );
  },
};

import { ReactElement } from 'react';

import { ProductDetailModalLayoutHeader } from '@halo-common/layouts';
import {
  ExecutionCalendarDetailModalHeaderActions,
  ExecutionCalendarDetailModalHeaderFeatures,
  ExecutionCalendarDetailModalHeaderId,
  ExecutionCalendarDetailModalHeaderSolvableParameter,
} from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { LocalizedTypography } from '@halodomination/halo-fe-common';

export type ExecutionCalendarDetailModalHeaderProps = {
  product?: CalendarModel | null;
  loading?: boolean;
  onClose?: () => void;
  onSubmitIndication?: (product: CalendarModel) => void;
  actionable?: boolean;
};

export const ExecutionCalendarDetailModalHeader = ({
  product,
  loading = true,
  onClose,
  onSubmitIndication,
  actionable = true,
}: ExecutionCalendarDetailModalHeaderProps): ReactElement => {
  const slots = {
    features: <ExecutionCalendarDetailModalHeaderFeatures loading={loading} product={product?.note} />,
    productId: <ExecutionCalendarDetailModalHeaderId product={product} loading={loading} />,
    parameter: (
      <ExecutionCalendarDetailModalHeaderSolvableParameter loading={loading} product={product} disableQuoteRefresh />
    ),
    actions: actionable ? (
      <ExecutionCalendarDetailModalHeaderActions
        product={product}
        onSubmitIndication={onSubmitIndication}
        loading={loading}
      />
    ) : null,
    description: (
      <LocalizedTypography variant="h6" sx={{ pb: 1, maxWidth: 'fit-content' }}>
        {product?.displayName}
      </LocalizedTypography>
    ),
  };

  return <ProductDetailModalLayoutHeader onClose={onClose} slots={slots} />;
};

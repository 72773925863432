import { ReactElement, ReactNode, useCallback, useMemo, useRef } from 'react';

import type { FormBuilderField } from '@halo-common/components';
import { ActionButton, buildSchema, renderField } from '@halo-common/components';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton, Modal } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { FieldValues, FormProvider, SubmitHandler, UseFormProps, useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface ModalFormBuilderProps<TFieldValues extends FieldValues = FieldValues> extends UseFormProps {
  title: string;
  fields: FormBuilderField[];
  schema?: yup.AnyObjectSchema;
  open: boolean;
  onClose: () => void;
  onSubmit: SubmitHandler<TFieldValues>;
  isPending?: boolean;
  actions?: ReactNode;
  children?: ReactNode;
  cancelLabel?: ReactNode;
  submitLabel?: ReactNode;
}

const backButtonSx = {
  marginRight: 'auto !important',
};

export const ModalFormBuilder = <TFieldValues extends FieldValues = FieldValues>({
  title,
  fields,
  open,
  onClose,
  onSubmit,
  isPending = false,
  actions,
  children,
  cancelLabel = translations.common.cancel,
  submitLabel = translations.common.save,
  schema: _schema,
  ...props
}: ModalFormBuilderProps<TFieldValues>): ReactElement => {
  const schema = useMemo(() => buildSchema(fields, _schema), []);
  const resolver = yupResolver(schema);

  const formRef = useRef<HTMLFormElement | null>(null);
  const formMethods = useForm({
    mode: 'onSubmit',
    resolver,
    ...props,
  });

  const { reset: resetForm } = formMethods;

  const handleClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  const triggerSubmit = useCallback(() => formRef.current?.requestSubmit(), []);

  const footer = (
    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
      <LocalizedButton
        onClick={handleClose}
        sx={backButtonSx}
        startIcon={<Iconography color="inherit" iconName="arrow-left" />}
      >
        {cancelLabel}
      </LocalizedButton>
      {actions}
      <ActionButton onClick={triggerSubmit} variant="contained" size="large" color="primary" loading={isPending}>
        {submitLabel}
      </ActionButton>
    </Stack>
  );

  return (
    <FormProvider {...formMethods}>
      <Modal title={title} open={open} onClose={handleClose} footer={footer} size="small">
        {children}
        <form onSubmit={formMethods.handleSubmit(onSubmit as SubmitHandler<FieldValues>)} noValidate ref={formRef}>
          <Stack direction="column" spacing={3}>
            {fields.map(renderField)}
          </Stack>
        </form>
      </Modal>
    </FormProvider>
  );
};

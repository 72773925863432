import { MutableRefObject, useState } from 'react';

import { AuctionStatusEnum } from '@halo-common/enums';
import { AuctionAdminModel } from '@halo-common/models';
import { OrdersSelectors } from '@halo-stores/Orders';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { useSelector } from 'react-redux';

export type UseAuctionTableDataResult = {
  data: Array<AuctionAdminModel>;
  issuersVisible: boolean;
  gridRef: MutableRefObject<GridApiPro>;
  handleDataSwitch: (value: boolean) => void;
};

export const useAuctionTableData = (status: AuctionStatusEnum): UseAuctionTableDataResult => {
  const gridRef = useGridApiRef();

  const [issuersVisible, setIssuersVisible] = useState(false);

  const auctions = useSelector(OrdersSelectors.selectAuctions);

  const auctionsByStatus = auctions[status];
  const data = auctionsByStatus?.slice(0, auctionsByStatus.length);
  const handleDataSwitch = (value: boolean) => void setIssuersVisible(value);

  return {
    data,
    gridRef,
    issuersVisible,
    handleDataSwitch,
  };
};

import { hash } from '@halo-common/utils';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';
import { QueryKey } from '@tanstack/react-query';

type RelationshipManagerQueryKeys = {
  all: QueryKeyFactoryFn;
  infinite: QueryKeyFactoryFn;
};

export const RelationshipManagerQueryKeyFactory: RelationshipManagerQueryKeys = {
  all: (): QueryKey => ['relationship', 'managers'],
  infinite: (query: string): QueryKey => [...RelationshipManagerQueryKeyFactory.all(), 'infinite', hash(query)],
};

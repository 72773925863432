import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiAuraAppResponseModel } from '@halo-data-sources/models';

const AURA_SSO_PATH = '/posttrade/users/aura-sso';

export const getAuraFrameData = async (): Promise<ApiAuraAppResponseModel> => {
  const clientConfiguration = await getClientConfiguration(AURA_SSO_PATH);

  return await request<ApiAuraAppResponseModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

import { MouseEvent, ReactElement, ReactNode } from 'react';

import { ActionButton } from '@halo-common/components';
import { ModalProps, Stack } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';

export type AllocationModalFooterProps = {
  loading?: boolean;
  disabled?: boolean;
  onSubmit: (ev: MouseEvent<HTMLButtonElement>) => void;
  onClose: ModalProps['onClose'];
  children?: ReactNode;
  hidePrimaryAction?: boolean;
};

export const AllocationModalFooter = ({
  loading = false,
  disabled = false,
  onSubmit,
  onClose,
  children,
  hidePrimaryAction = false,
}: AllocationModalFooterProps): ReactElement => {
  const handleClose = (ev: MouseEvent<HTMLButtonElement>) => {
    onClose?.(ev, 'closeButtonClick');
  };

  const primaryAction = !hidePrimaryAction ? (
    <ActionButton variant="contained" size="large" onClick={onSubmit} loading={loading} disabled={disabled}>
      Submit Allocations
    </ActionButton>
  ) : null;

  return (
    <Stack direction="row" justify="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Button variant="outlined" onClick={handleClose} disableRipple disableElevation>
          Close
        </Button>
        {children}
      </Stack>
      {primaryAction}
    </Stack>
  );
};

import { CalendarQueryModel } from '@halo-atoms/calendars';
import { DynamicFiltersMinMaxModel } from '@halo-atoms/common';
import { FixedIncomeQueryModel } from '@halo-atoms/fixedIncome';
import {
  COMBINED_DATE_TIME_FORMAT,
  DEFAULT_INFINITE_QUERY_NEXT_PAGINATION,
  YEAR_MONTH_DAY_DATE_FORMAT,
  YEAR_MONTH_DAY_DATE_PICKER_FORMAT,
} from '@halo-common/constants';
import { AssetIdentifierEnum, CalendarOrderCategoryEnum, SortModelDirectionEnum } from '@halo-common/enums';
import {
  NON_CALL_PERIOD_SLIDER_MAX_VALUE,
  NON_CALL_PERIOD_SLIDER_MIN_VALUE,
  PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE,
  PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE,
  TERM_SLIDER_MAX_VALUE,
  TERM_SLIDER_MIN_VALUE,
  UNDERLYING_SLIDER_MAX_VALUE,
  UNDERLYING_SLIDER_MIN_VALUE,
} from '@halo-common/layouts';
import {
  CalendarFavoriteModel,
  CalendarInventoryModel,
  CalendarIssuerQueryModel,
  CalendarMetaModel,
  CalendarModel,
  CalendarPageModel,
  CalendarPendingModel,
  CalendarPointModel,
  CalendarProductQueryModel,
  CalendarSearchAssetIdModel,
  CalendarSearchAssetModel,
  WholesalerModel,
} from '@halo-common/models';
import { ApiAdminCalendarStatus } from '@halo-data-sources/enums';
import {
  ApiAllocationMapper,
  ApiIssuerMapper,
  ApiOrganizationMapper,
  ApiQuoteMapper,
  ApiTermsheetV2Mapper,
  mapApiDocumentModelToS3DocumentModel,
  mapApiNoteModelToNoteModel,
  mapApiOtherDocumentModelToOtherDocumentModel,
} from '@halo-data-sources/mappers';
import {
  ApiCalendarMetaModel,
  ApiCalendarModel,
  ApiCalendarPageModel,
  ApiCalendarPendingModel,
  ApiCalendarPointModel,
  ApiCalendarSearchAssetIdModel,
  ApiCalendarSearchAssetModel,
  ApiCalendarSortingModel,
  ApiCalendarSortingOptionsModel,
  ApiCalendarUnderlyingSearchRequestModel,
  ApiCalendarWholesalerAssociationModel,
  ApiFixOrderModel,
  ApiGetAdminCalendarsResponse,
  ApiGetCalendarIssuerModel,
  ApiGetCalendarPageResponseModel,
  ApiGetCalendarQueryProductsModel,
  ApiGetCalendarsFiltersRequestModel,
  ApiGetCalendarsMixMaxTypeRequestModel,
  ApiGetCalendarsRequestModel,
  ApiGetCalendarsTradableRequestModel,
  ApiPostAdminCalendarsQueryParams,
  ApiPostCalendarFavoriteResponseModel,
  ApiPostCalendarPageQueryParams,
} from '@halo-data-sources/models';
import { AdminCalendarStatusEnum, AdminCalendarStatusFilter } from '@halo-modules/admin';
import {
  CalendarIssuersQueryParams,
  CalendarPageQueryParams,
  CalendarQueryParams,
  CalendarSorting,
  CalendarSortingOptions,
} from '@halo-stores/Calendar';
import {
  PershingCalendarBatchResultMap,
  PershingCalendarMap,
  PershingCalendarPageBatchResultMap,
  PershingCalendarStatusMap,
  PershingTotalsMap,
} from '@halo-stores/Pershing';
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';

export const ApiCalendarsMapper = {
  toCalendarMetaModel: (model: ApiCalendarMetaModel): CalendarMetaModel => ({
    isTradable: model?.cantrade ?? false,
    interest: model.interest,
    status: model.intereststatus,
  }),
  toCalendarPointModel: (model: ApiCalendarPointModel): CalendarPointModel => ({
    id: uuid(),
    label: model.point,
    value: model.content,
    position: model.position,
  }),
  toWholeSalerModel: (model: ApiCalendarWholesalerAssociationModel): WholesalerModel => ({
    id: model.wholesaler?.id,
    code: model.wholesaler?.code?.toLowerCase(),
    name: model.wholesaler?.name,
    price: model.price,
    salePrice: model.saleprice,
    pages: model.pages?.map(ApiCalendarsMapper.toCalendarPageModel) ?? [],
    fixEnabled: model.wholesaler.fix_enabled,
    inventoryConsumed: model.inventory_consumed,
    inventoryRemaining: model.inventory_remaining,
    inventoryTotal: model.inventory_total,
  }),
  toCalendarPendingModel: (model: ApiCalendarPendingModel): CalendarPendingModel => ({
    pageId: model.calendar_page_id,
    cusip: model.cusip,
    expirationDate: DateTime.fromSQL(model.expiration_date.replace('Z', '')).toISO({ includeOffset: false }) as string,
    id: model.id,
    isin: model.isin,
    wholesalers: model.wholesalers?.map(ApiCalendarsMapper.toWholeSalerModel) ?? [],
  }),
  toCalendarInventoryModel: (model: ApiCalendarModel): CalendarInventoryModel => {
    const hasInventoryConsumed = Boolean(model.ws_inventory_total && model.ws_inventory_consumed);
    const hasWholesalerInventoryConsumed = Boolean(
      model.wholesaler?.inventory_total && model.wholesaler.inventory_consumed,
    );

    const hasNoInventoryConsumed = Boolean(model.ws_inventory_total && !model.ws_inventory_consumed);
    const hasNoWholesalerInventoryConsumed = Boolean(
      model.wholesaler?.inventory_total && !model.wholesaler?.inventory_consumed,
    );

    const calendarInventoryConsumed = hasInventoryConsumed
      ? model.ws_inventory_consumed
      : hasNoInventoryConsumed
        ? 0
        : undefined;

    const wholesalerInventoryConsumed = hasWholesalerInventoryConsumed
      ? model.wholesaler?.inventory_consumed
      : hasNoWholesalerInventoryConsumed
        ? 0
        : undefined;

    const inventoryConsumed = calendarInventoryConsumed ?? wholesalerInventoryConsumed;

    const hasInventoryRemaining = Boolean(model.ws_inventory_total && model.ws_inventory_remaining);
    const hasWholesalerInventoryRemaining = Boolean(
      model.wholesaler?.inventory_total && model.wholesaler?.inventory_remaining,
    );

    const hasNoInventoryRemaining = Boolean(
      model.ws_inventory_total && !model.ws_inventory_remaining && model.ws_inventory_consumed,
    );
    const hasNoWholesalerInventoryRemaining = Boolean(
      model.wholesaler?.inventory_total &&
        !model.wholesaler?.inventory_remaining &&
        model.wholesaler?.inventory_consumed,
    );

    const hasAllInventoryRemaining = Boolean(
      model.ws_inventory_total && !model.ws_inventory_remaining && !model.ws_inventory_consumed,
    );
    const hasAllWholesalerInventoryRemaining = Boolean(
      model.wholesaler?.inventory_total &&
        !model.wholesaler?.inventory_remaining &&
        !model.wholesaler?.inventory_consumed,
    );

    const calendarInventoryRemaining = hasInventoryRemaining
      ? model.ws_inventory_remaining
      : hasNoInventoryRemaining
        ? 0
        : hasAllInventoryRemaining
          ? model.ws_inventory_total
          : undefined;

    const wholesalerInventoryRemaining = hasWholesalerInventoryRemaining
      ? model.wholesaler?.inventory_remaining
      : hasNoWholesalerInventoryRemaining
        ? model.wholesaler?.inventory_remaining
        : hasAllWholesalerInventoryRemaining
          ? model.wholesaler?.inventory_total
          : undefined;

    const inventoryRemaining = calendarInventoryRemaining ?? wholesalerInventoryRemaining;

    const hasCalendarInventory = Boolean(
      typeof calendarInventoryConsumed === 'number' && typeof calendarInventoryRemaining === 'number',
    );

    const inventoryTotal = hasCalendarInventory ? model.ws_inventory_total : model.wholesaler?.inventory_total;

    return { inventoryConsumed, inventoryRemaining, inventoryTotal };
  },
  toCalendarModel: (model: ApiCalendarModel): CalendarModel => {
    const { inventoryConsumed, inventoryRemaining, inventoryTotal } =
      ApiCalendarsMapper.toCalendarInventoryModel(model);

    return {
      approved: Boolean(model.approved),
      additionalInfo: model.additional_info ? mapApiDocumentModelToS3DocumentModel(model.additional_info) : null,
      activeAllocationCount:
        model.num_active_allocations ?? model.allocations?.filter(({ status }) => status !== 'canceled')?.length ?? 0,
      allocations: model.allocations?.map((allocation) => ApiAllocationMapper.toAllocationModel(allocation)) ?? [],
      adminAllocations:
        model.adminallocations?.map((allocation) => ApiAllocationMapper.toAllocationModel(allocation)) ?? [],
      category: CalendarOrderCategoryEnum[model?.category?.toLowerCase() as CalendarOrderCategoryEnum],
      cusip: model.cusip,
      daysCompleted: model.completed_days,
      daysRemaining: model.days_remaining,
      daysTotal: model.total_days,
      description: model.description,
      displayName: model.display_name,
      documents: model.other_documents.map(mapApiOtherDocumentModelToOtherDocumentModel),
      expirationDate: (DateTime.fromSQL(model.expiration_date).isValid
        ? DateTime.fromSQL(model.expiration_date.replace('Z', '')).toISO({ includeOffset: false })
        : model.expiration_date) as string,
      finalTerms: model.final_terms ? mapApiDocumentModelToS3DocumentModel(model.final_terms) : null,
      fundserv: model.fundservcode,
      hasDetails: model.hasdetails,
      id: model.id,
      internalName: model.internal_name,
      inventoryConsumed,
      inventoryRemaining,
      inventoryTotal,
      isActive: model.is_active,
      isin: model.isin,
      isInactive: model.is_inactive,
      isFavorite: model.is_favorite,
      isVisible: model.is_visible,
      issuer: ApiIssuerMapper.toIssuerModel(model.issuer),
      meta: model.meta ? ApiCalendarsMapper.toCalendarMetaModel(model.meta) : null,
      note: mapApiNoteModelToNoteModel(model.note),
      noteV2: model.note_model_extension ? ApiTermsheetV2Mapper.toV2NoteModel(model.note_model_extension) : undefined,
      quote: model.note_quote ? ApiQuoteMapper.toCalendarQuoteModel(model.note_quote) : null,
      originalExpirationDate: model.original_expiration_date,
      page: model.page ? ApiCalendarsMapper.toCalendarPageModel(model.page) : undefined,
      pages: model.pages?.map(ApiCalendarsMapper.toCalendarPageModel) ?? [],
      parameter: model.parameter,
      paused: model.order_placement_disabled,
      points: model.points?.map(ApiCalendarsMapper.toCalendarPointModel) ?? [],
      position: model.position,
      preliminaryTerms: model.preliminary_terms ? mapApiDocumentModelToS3DocumentModel(model.preliminary_terms) : null,
      price: model.price,
      priceDisplay: model.price_display,
      settleDate: model.settle_date ?? null,
      showAsNme: model.show_as_nme,
      startShowingDate: (DateTime.fromSQL(model?.start_showing_date ?? '').isValid
        ? DateTime.fromSQL(model.start_showing_date.replace('Z', '')).toISO({ includeOffset: false })
        : model.start_showing_date) as string,
      status: model.status,
      tradeDate: model.trade_date ? DateTime.fromFormat(model.trade_date, YEAR_MONTH_DAY_DATE_FORMAT).toISO() : null,
      wholesalers: model.wholesalers?.map(ApiCalendarsMapper.toWholeSalerModel) ?? [],
      wholesaler: model.wholesaler ? ApiCalendarsMapper.toWholeSalerModel(model.wholesaler) : null,
    };
  },
  toCalendarPageModel: (model: ApiCalendarPageModel): CalendarPageModel => ({
    id: model.id,
    identifier: model.identifier,
    interestEmail: model.interest_email,
    showCalendarPoints: model.show_calendar_points,
    showInterestButton: model.show_interest_button,
    showViewTermSheet: model.show_view_term_sheet,
    title: model?.title ?? '',
    numberOfChildren: model?.nchildren ?? 0,
    children: [],
    parentId: model.parent_id ?? null,
    organization: model.organization ? ApiOrganizationMapper.toOrganization(model.organization) : null,
  }),
  toCalendarBatchResultMap: (
    results: Array<ApiGetAdminCalendarsResponse>,
    calendars: PershingCalendarStatusMap,
    totals: PershingTotalsMap,
    queryParams?: Array<CalendarQueryParams>,
  ): PershingCalendarBatchResultMap => {
    return results.reduce(
      (map: PershingCalendarBatchResultMap, result, index) => {
        const params = queryParams?.[index];
        const status = params?.status ?? params?.orderStatuses?.[0] ?? AdminCalendarStatusEnum.available;
        const updatedCalendars = result?.calendars?.map(ApiCalendarsMapper.toCalendarModel) ?? [];

        return {
          ...map,
          calendarMap: { ...map.calendarMap, [status]: updatedCalendars },
          calendarTotals: { ...map.calendarTotals, [status]: result?.total ?? 0 },
        };
      },
      { calendarMap: calendars, calendarTotals: totals },
    );
  },
  toCalendarPageBatchResultMap: (
    results: Array<ApiGetCalendarPageResponseModel>,
    calendars: PershingCalendarStatusMap,
    totals: PershingTotalsMap,
    queryParams?: Array<CalendarPageQueryParams>,
  ): PershingCalendarPageBatchResultMap => {
    return results.reduce(
      (map: PershingCalendarPageBatchResultMap, result, index) => {
        const params = queryParams?.[index];

        let status = 'available';
        if (params?.status === 'archived') status = 'archived';
        else if (params?.approved === null) status = 'pending';
        else if (params?.approved === true) status = 'confirmed';
        else if (params?.approved === false) status = 'denied';

        const items: Array<ApiCalendarModel> = result?.page?.items ?? [];
        const updatedAllocationTotals = items.reduce((total, item) => total + (item?.adminallocations?.length ?? 0), 0);
        const updatedCalendars = items.map(ApiCalendarsMapper.toCalendarModel);
        const updatedCalendarTotals = result?.page?.total ?? 0;

        return {
          ...map,
          calendarMap: { ...map.calendarMap, [status]: updatedCalendars },
          calendarTotals: { ...map.calendarTotals, [status]: updatedCalendarTotals },
          allocationTotals: { ...map.allocationTotals, [status]: updatedAllocationTotals },
        };
      },
      { calendarMap: calendars, calendarTotals: totals, allocationTotals: totals },
    );
  },
  toApiPostCalendarPageQueryParams: (
    queryParams?: CalendarPageQueryParams | null,
  ): ApiPostCalendarPageQueryParams | null => {
    if (!queryParams) return null;

    const {
      accountNumber,
      approved,
      cusip,
      closing,
      endDate,
      hasOrders,
      issuerId,
      ip,
      orderStatuses,
      noteType,
      page,
      parent,
      noteTerm,
      startDate,
      status,
      passedFomsCheck,
    } = queryParams;

    const hasApprovedQuery = approved !== undefined;
    const hasPassedFomsCheckQuery = passedFomsCheck !== undefined;
    const hasOrdersQuery = hasOrders !== undefined;
    const approvedQuery = approved?.toString() ?? 'null';

    const startDateQuery = startDate?.toFormat(YEAR_MONTH_DAY_DATE_FORMAT);
    const endDateQuery = endDate?.toFormat(YEAR_MONTH_DAY_DATE_FORMAT);
    const closingDateQuery = closing?.toFormat(YEAR_MONTH_DAY_DATE_FORMAT);
    const statusQuery = CalendarsMapper.toApiAdminCalendarStatus(status);
    const orderStatusQuery = orderStatuses?.map(CalendarsMapper.toApiAdminCalendarStatus).join(',');

    const params: ApiPostCalendarPageQueryParams = { pagesize: '25' };

    if (cusip) params.cusip = cusip;
    if (issuerId) params.issuer = issuerId;
    if (accountNumber) params.account = accountNumber;
    if (ip) params.name = ip.toLowerCase();
    if (noteType) params.noteType = noteType.toLowerCase();
    if (noteTerm) params.noteTerm = noteTerm.toLowerCase();
    if (hasOrdersQuery) params.hasorders = hasOrders.toString();
    if (hasPassedFomsCheckQuery) params.passedfoms = passedFomsCheck.toString();
    if (page) params.page = page.toString();
    if (parent) params.parent = parent.toString();
    if (hasApprovedQuery) params.approved = approvedQuery;
    if (startDateQuery) params.startdate = startDateQuery;
    if (endDateQuery) params.enddate = endDateQuery;
    if (closingDateQuery) params.closing = closingDateQuery;
    if (statusQuery) params.status = statusQuery;
    if (orderStatusQuery) params.orderstatus = orderStatusQuery;

    return params;
  },
  toCalendarFavoriteModel: (response: ApiPostCalendarFavoriteResponseModel): CalendarFavoriteModel => {
    return {
      id: response.calendar_note_id,
      isFavorite: response.is_favorite,
      userId: response.user_id,
    };
  },
  toCalendarAssetIdsSearchModel: (assetId: ApiCalendarSearchAssetIdModel): CalendarSearchAssetIdModel => {
    return {
      type: AssetIdentifierEnum[assetId.type],
      value: assetId.value,
    };
  },
  toCalendarAssetSearchModel: (response: ApiCalendarSearchAssetModel): CalendarSearchAssetModel => {
    return {
      assetIds: response.asset_ids.map(ApiCalendarsMapper.toCalendarAssetIdsSearchModel),
      noteId: response.calendar_note_id,
    };
  },
  toCalendarQueryProductModel: (response: ApiGetCalendarQueryProductsModel): CalendarProductQueryModel => {
    return {
      noteType: response.note_type,
      productType: response.product,
      count: response.count,
    };
  },
  toCalendarIssuerModel: (response: ApiGetCalendarIssuerModel): CalendarIssuerQueryModel => {
    return {
      count: response.count,
      id: response.id,
      name: response.name,
    };
  },
};

export const CalendarsMapper = {
  toCalendarsWithFixOrderBasedAllocations: (
    calendars: Array<CalendarModel>,
    fixOrders: Array<ApiFixOrderModel>,
  ): Array<CalendarModel> => {
    const orderMap = fixOrders.reduce(
      (map, order) => ({
        ...map,
        [order.id]: Boolean(order.children?.length),
      }),
      {} as { [key: number]: boolean },
    );

    return calendars.map((calendar) => ({
      ...calendar,
      adminAllocations:
        calendar?.adminAllocations?.filter((allocation) => {
          const allocationHasFixOrderAssociated = Boolean(allocation.fixId && orderMap[allocation.fixId]);
          return allocationHasFixOrderAssociated;
        }) ?? [],
    }));
  },
  toParentPages: (models: Array<CalendarPageModel>): Array<CalendarPageModel> => {
    const copiedModels = [...models];

    copiedModels.forEach((model) => {
      if (model.parentId) {
        const parentIndex = copiedModels.findIndex((potentialParentPage) => potentialParentPage.id === model.parentId);

        if (parentIndex !== undefined)
          copiedModels[parentIndex].children = [...copiedModels[parentIndex].children, model];
      }
    });

    return copiedModels;
  },
  toApiGetAdminIssuersByCalendarPageParamString: (params?: CalendarIssuersQueryParams): string => {
    if (!params) return '';

    const queryParams: { parent?: string } = {};

    if (params?.parent) queryParams.parent = params.parent.toString();

    const query = new URLSearchParams(queryParams);

    return query.toString() ? `?${query.toString()}` : '';
  },
  toApiAdminCalendarStatus: (status?: AdminCalendarStatusFilter): ApiAdminCalendarStatus | undefined => {
    if (!status) return undefined;

    const mapping: { [key: string]: ApiAdminCalendarStatus } = {
      accepted: 'accepted',
      activePending: 'active+pending',
      archived: 'closed',
      available: 'active',
      canceled: 'canceled',
      confirmed: 'accepted',
      denied: 'rejected',
      filled: 'filled',
      pending: 'pending',
    };

    return mapping[status];
  },

  toApiPostCalendarPageQueryParamsString: (queryParams?: CalendarPageQueryParams | null): string => {
    const params = ApiCalendarsMapper.toApiPostCalendarPageQueryParams(queryParams);

    if (!params) return '';

    const query = new URLSearchParams(params);

    return query.toString() ? `?${query.toString()}` : '';
  },
  toApiPostAdminCalendarsQueryParams: (
    queryParams?: CalendarQueryParams | null,
  ): ApiPostAdminCalendarsQueryParams | null => {
    if (!queryParams) return null;

    const {
      accountNumber,
      approved,
      cusip,
      date,
      endDate,
      hasOrders,
      hasFixOrders,
      issuerId,
      ip,
      orderStatuses,
      organization,
      page,
      pagesize,
      startDate,
      status,
      passedFomsCheck,
    } = queryParams;

    const searchDate = date?.toUTC()?.toFormat(YEAR_MONTH_DAY_DATE_FORMAT);
    const searchStartDate = startDate?.toUTC()?.toFormat(COMBINED_DATE_TIME_FORMAT);
    const searchEndDate = endDate
      ?.toUTC()
      ?.plus({ hours: 23, minutes: 59, seconds: 59 })
      ?.toFormat(COMBINED_DATE_TIME_FORMAT);

    const startDateQuery = searchDate ?? searchStartDate;
    const endDateQuery = searchDate ?? searchEndDate;
    const organizationQuery = organization ?? ip;

    const statusQuery = CalendarsMapper.toApiAdminCalendarStatus(status);
    const orderStatusQuery = orderStatuses?.map(CalendarsMapper.toApiAdminCalendarStatus).join(',');

    const hasApprovedQuery = approved !== undefined;
    const hasFixOrdersQuery = hasFixOrders !== undefined;
    const hasPassedFomsCheckQuery = passedFomsCheck !== undefined;
    const hasOrdersQuery = hasOrders !== undefined;
    const approvedQuery = approved !== null ? approved?.toString() : 'null';

    const params: ApiPostAdminCalendarsQueryParams = { pagesize: '25' };

    if (hasApprovedQuery) params.approved = approvedQuery;
    if (cusip) params.cusip = cusip;
    if (hasFixOrdersQuery) params.fixorders = hasFixOrders.toString();
    if (hasPassedFomsCheckQuery) params.passedfoms = passedFomsCheck.toString();
    if (startDateQuery) params.startdate = startDateQuery;
    if (endDateQuery) params.enddate = endDateQuery;
    if (accountNumber) params.account = accountNumber;
    if (organizationQuery) params.organization = organizationQuery;
    if (issuerId) params.issuer = issuerId.toString();
    if (page) params.page = page.toString();
    if (pagesize) params.pagesize = pagesize.toString();
    if (hasOrdersQuery) params.hasorders = hasOrders.toString();
    if (orderStatusQuery) params.orderstatus = orderStatusQuery;
    if (statusQuery) params.status = statusQuery;

    return params;
  },
  toApiCalendarSortingOption: (option: CalendarSorting): ApiCalendarSortingModel => ({
    field: {
      cusip: 'cusip',
      expirationDate: 'expiration_date',
      settleDate: 'settle_date',
    }[option.field],
    direction: option.direction ?? 'desc',
  }),
  toApiCalendarSortingOptions: (sortingOptions?: CalendarSortingOptions): ApiCalendarSortingOptionsModel => {
    return sortingOptions?.map(CalendarsMapper.toApiCalendarSortingOption) ?? [];
  },
  toUpdatedCalendarMap: (calendars: PershingCalendarMap, calendar: CalendarModel): PershingCalendarMap => {
    return Object.keys(calendars).reduce((map, key) => {
      const copiedCalendars = [...calendars[key]];

      const calendarIndex = copiedCalendars.findIndex((nextCalendar) => nextCalendar?.id === calendar?.id);

      if (calendarIndex !== -1) copiedCalendars?.splice(calendarIndex, 1, calendar);

      return { ...map, [key]: copiedCalendars };
    }, calendars);
  },
  toApiPostAdminCalendarsQueryParamsString: (queryParams?: CalendarQueryParams | null): string => {
    const params = ApiCalendarsMapper.toApiPostCalendarPageQueryParams(queryParams);

    if (!params) return '';

    const query = new URLSearchParams(params);

    return query.toString() ? `?${query.toString()}` : '';
  },
  toCalendarsTermFilterPayload: (
    minMax: DynamicFiltersMinMaxModel | null,
  ): ApiGetCalendarsMixMaxTypeRequestModel | undefined => {
    if (!minMax) return undefined;
    const max = minMax.max > TERM_SLIDER_MAX_VALUE ? TERM_SLIDER_MAX_VALUE : minMax.max;
    const min = minMax.min < TERM_SLIDER_MIN_VALUE ? TERM_SLIDER_MIN_VALUE : minMax.min;
    return { min, max };
  },
  toCalendarsProtectionAmountFilterPayload: (
    minMax: DynamicFiltersMinMaxModel | null,
  ): ApiGetCalendarsMixMaxTypeRequestModel | undefined => {
    if (!minMax) return undefined;
    const max = minMax.max > PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE ? PRINCIPAL_PROTECTION_SLIDER_MAX_VALUE : minMax.max;
    const min = minMax.min < PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE ? PRINCIPAL_PROTECTION_SLIDER_MIN_VALUE : minMax.min;
    return { min, max };
  },
  toCalendarsNonCallPeriodFilterPayload: (
    minMax: DynamicFiltersMinMaxModel | null,
  ): ApiGetCalendarsMixMaxTypeRequestModel | undefined => {
    if (!minMax) return undefined;
    const max = minMax.max > NON_CALL_PERIOD_SLIDER_MAX_VALUE ? NON_CALL_PERIOD_SLIDER_MAX_VALUE : minMax.max;
    const min = minMax.min < NON_CALL_PERIOD_SLIDER_MIN_VALUE ? NON_CALL_PERIOD_SLIDER_MIN_VALUE : minMax.min;
    return { min, max };
  },
  toCalendarsUnderlyingCountFilterPayload: (
    minMax: DynamicFiltersMinMaxModel | null,
  ): ApiGetCalendarsMixMaxTypeRequestModel | undefined => {
    if (!minMax) return undefined;
    const max = minMax.max > UNDERLYING_SLIDER_MAX_VALUE ? UNDERLYING_SLIDER_MAX_VALUE : minMax.max;
    const min = minMax.min < UNDERLYING_SLIDER_MIN_VALUE ? UNDERLYING_SLIDER_MIN_VALUE : minMax.min;
    return { min, max };
  },
  toCalendarOrderCategory: (filters: CalendarQueryModel): Array<string> | undefined => {
    const { checkboxes } = filters;

    const calendarType = [];

    if (checkboxes.advisory) calendarType.push('ADVISORY');
    if (checkboxes.brokerage) calendarType.push('BROKERAGE');
    if (checkboxes.highlighted) calendarType.push('EXCLUSIVE');

    return calendarType.length > 0 ? calendarType : undefined;
  },
  toCalendarsProtectionTypePayload: (filters: CalendarQueryModel): Array<string> | undefined => {
    const { checkboxes } = filters;

    const protectionTypes = [];

    if (checkboxes.softProtection) protectionTypes.push('SOFT');
    if (checkboxes.hardProtection) protectionTypes.push('HARD');
    if (checkboxes.dailyProtection) protectionTypes.push('DAILY');
    if (checkboxes.continuousProtection) protectionTypes.push('CONTINUOUS');

    return protectionTypes.length > 0 ? protectionTypes : undefined;
  },
  toCalendarsCallTypePayload: (filters: CalendarQueryModel): Array<string> | undefined => {
    const { checkboxes } = filters;

    const callTypes = [];

    if (checkboxes.autocallable) callTypes.push('AUTOCALL');
    if (checkboxes.issuerCallable) callTypes.push('ISSUER_CALL');
    if (checkboxes.notCallable) callTypes.push('NON_CALL');

    return callTypes.length > 0 ? callTypes : undefined;
  },
  toCalendarsSettlementTypePayload: (filters: CalendarQueryModel): Array<string> | undefined => {
    const { checkboxes } = filters;

    const settlementTypes = [];

    if (checkboxes.cashSettlement) settlementTypes.push('CASH');
    if (checkboxes.physicalSettlement) settlementTypes.push('PHYSICAL');

    return settlementTypes.length > 0 ? settlementTypes : undefined;
  },
  toCalendarsCouponTypePayload: (filters: CalendarQueryModel): Array<string> | undefined => {
    const { checkboxes } = filters;

    const couponTypes = [];

    if (checkboxes.fixed) couponTypes.push('FIXED');
    if (checkboxes.contingent) couponTypes.push('CONTINGENT');
    if (checkboxes.memory) couponTypes.push('MEMORY');

    return couponTypes.length > 0 ? couponTypes : undefined;
  },
  toCalendarsBasketTypePayload: (filters: CalendarQueryModel): Array<string> | undefined => {
    const { checkboxes } = filters;

    const couponTypes = [];

    if (checkboxes.averageBasket) couponTypes.push('AVERAGE');
    if (checkboxes.singleBasket) couponTypes.push('SINGLE');
    if (checkboxes.worstOfBasket) couponTypes.push('WORST_OF');
    if (checkboxes.weightedBasket) couponTypes.push('WEIGHTED');

    return couponTypes.length > 0 ? couponTypes : undefined;
  },
  toCalendarsCappedPayload: (
    filters: CalendarQueryModel | FixedIncomeQueryModel,
  ): ApiGetCalendarsFiltersRequestModel['is_capped'] => {
    const { checkboxes } = filters;

    if (checkboxes.capped && checkboxes.uncapped) return undefined;
    if (checkboxes.uncapped && !checkboxes.capped) return false;
    if (checkboxes.capped && !checkboxes.uncapped) return true;
  },
  toCalendarsTradablePayload: (query: CalendarQueryModel): ApiGetCalendarsTradableRequestModel | undefined => {
    const { underlyingIds, underlyingMatches, underlyingCount, underlyings } = query;

    const basketTypes = CalendarsMapper.toCalendarsBasketTypePayload(query);

    const hasUnderlyingIds = Boolean(underlyingIds?.length);
    const hasUnderlyingMatches = hasUnderlyingIds && Boolean(underlyingMatches);
    const hasUnderlyingCount = underlyingCount !== null;
    const hasBasketTypes = Boolean(basketTypes?.length);
    const validTradablePayload = hasUnderlyingIds || hasUnderlyingMatches || hasUnderlyingCount || hasBasketTypes;

    if (!validTradablePayload) return undefined;

    return {
      ids: hasUnderlyingIds ? underlyingIds.map((id) => parseInt(id)) : undefined,
      names: underlyings ? underlyings.map(({ name }) => name) : undefined,
      matches: hasUnderlyingMatches ? underlyingMatches : undefined,
      basket_size: CalendarsMapper.toCalendarsUnderlyingCountFilterPayload(underlyingCount),
      basket_style: hasBasketTypes ? basketTypes : undefined,
    };
  },
  toCalendarsRequest: (query: CalendarQueryModel): ApiGetCalendarsRequestModel => {
    const hasCurrencies = query.currencies && query.currencies.length > 0;
    const currencies = hasCurrencies ? query.currencies?.map((currency) => parseInt(currency)) : undefined;

    const tradablePayload = CalendarsMapper.toCalendarsTradablePayload(query);

    const tradables = tradablePayload
      ? {
          ids: tradablePayload.ids,
          matches: tradablePayload.matches,
          basket_size: tradablePayload.basket_size,
          basket_style: tradablePayload.basket_style,
        }
      : undefined;

    const filters: ApiGetCalendarsRequestModel['filters'] = {
      calendar_types: CalendarsMapper.toCalendarOrderCategory(query),
      currencies,
      favorited: query.checkboxes.starred ? query.checkboxes.starred : undefined,
      has_active_allocations: query.checkboxes.submittedIndication ? query.checkboxes.submittedIndication : undefined,
      issuers: query.issuers.length > 0 ? query.issuers.map((issuer) => parseInt(issuer.id)) : undefined,
      product_types: query.productTypes.length > 0 ? query.productTypes.map((productType) => productType) : undefined,
      protection_types: CalendarsMapper.toCalendarsProtectionTypePayload(query),
      tradables,
      end_date: query.endDate,
      start_date: query.startDate,
      term: CalendarsMapper.toCalendarsTermFilterPayload(query.term),
      protection_amount: CalendarsMapper.toCalendarsProtectionAmountFilterPayload(query.protectionAmount),
      non_call_months: CalendarsMapper.toCalendarsNonCallPeriodFilterPayload(query.nonCallPeriod),
      call_types: CalendarsMapper.toCalendarsCallTypePayload(query),
      coupon_types: CalendarsMapper.toCalendarsCouponTypePayload(query),
      is_capped: CalendarsMapper.toCalendarsCappedPayload(query),
      settlement_types: CalendarsMapper.toCalendarsSettlementTypePayload(query),
      sub_asset_classes: query.toggles.propIndices ? { PROP_INDICES: false } : undefined,
      is_visible: query.isVisible,
      show_as_nme: query.showAsNME,
    };

    if (query.approved !== undefined) filters.approved = query.approved;

    const sort = query.sort
      .map((model) => ({
        ...model,
        field: {
          isActive: 'is_active',
          payoff: 'payoff',
          expirationDate: 'expiration_date',
          protection: 'protection_pct',
          protectionRank: 'protection_rank',
          term: 'term',
        }[model.field] as string,
      }))
      .filter((model) => Boolean(model.field));

    return {
      calendar_note_id: query.calendarNoteId ? query.calendarNoteId : undefined,
      filters,
      sort,
      page: query.page ? query.page : 0,
      page_length: query.pageLength ?? 0,
    };
  },
  toFixedIncomeFilterPayload: (query: FixedIncomeQueryModel): ApiGetCalendarsRequestModel => {
    const tradablePayload = CalendarsMapper.toCalendarsTradablePayload(query);

    const tradables = tradablePayload
      ? {
          ids: tradablePayload.ids,
          matches: tradablePayload.matches,
          basket_size: tradablePayload.basket_size,
          basket_style: tradablePayload.basket_style,
        }
      : undefined;

    const sort = query.sort
      .map((model) => ({
        ...model,
        field: {
          isActive: 'is_active',
          payoff: 'payoff',
          expirationDate: 'expiration_date',
          protection: 'protection_pct',
          protectionRank: 'protection_rank',
          term: 'term',
        }[model.field] as string,
      }))
      .filter((model) => Boolean(model.field));

    return {
      calendar_note_id: query.calendarNoteId ? query.calendarNoteId : undefined,
      filters: {
        approved: true,
        is_visible: true,
        show_as_nme: true,
        favorited: query.checkboxes.starred ? query.checkboxes.starred : undefined,
        has_active_allocations: query.checkboxes.submittedIndication ? query.checkboxes.submittedIndication : undefined,
        issuers: query.issuers.length > 0 ? query.issuers.map((issuer) => parseInt(issuer.id)) : undefined,
        tradables,
        end_date: query.endDate,
        start_date: query.startDate,
        term: CalendarsMapper.toCalendarsTermFilterPayload(query.term),
        protection_amount: CalendarsMapper.toCalendarsProtectionAmountFilterPayload(query.protectionAmount),
        call_types: CalendarsMapper.toCalendarsCallTypePayload(query),
        coupon_types: CalendarsMapper.toCalendarsCouponTypePayload(query),
        is_capped: CalendarsMapper.toCalendarsCappedPayload(query),
        sub_asset_classes: query.toggles.propIndices ? { PROP_INDICES: false } : undefined,
      },
      sort,
      page: query.page ? query.page : 0,
      page_length: query.pageLength ?? 0,
    };
  },
  toCalendarUnderlyingQueryRequest: (
    search: string,
    query: CalendarQueryModel,
    pageParam = DEFAULT_INFINITE_QUERY_NEXT_PAGINATION,
  ): ApiCalendarUnderlyingSearchRequestModel => {
    const hasCurrencies = query.currencies && query.currencies.length > 0;
    const currencies = hasCurrencies ? query.currencies?.map((currency) => parseInt(currency)) : undefined;

    const filters: ApiCalendarUnderlyingSearchRequestModel['filters'] = {
      calendar_types: CalendarsMapper.toCalendarOrderCategory(query),
      currencies,
      favorited: query.checkboxes.starred ? query.checkboxes.starred : undefined,
      has_active_allocations: query.checkboxes.submittedIndication ? query.checkboxes.submittedIndication : undefined,
      issuers: query.issuers.length > 0 ? query.issuers.map((issuer) => parseInt(issuer.id)) : undefined,
      product_types: query.productTypes.length > 0 ? query.productTypes.map((productType) => productType) : undefined,
      protection_types: CalendarsMapper.toCalendarsProtectionTypePayload(query),
      search,
      end_date: DateTime.fromFormat(query.endDate, YEAR_MONTH_DAY_DATE_PICKER_FORMAT).toISODate() as string,
      start_date: DateTime.fromFormat(query.startDate, YEAR_MONTH_DAY_DATE_PICKER_FORMAT).toISODate() as string,
      term: CalendarsMapper.toCalendarsTermFilterPayload(query.term),
      protection_amount: CalendarsMapper.toCalendarsProtectionAmountFilterPayload(query.protectionAmount),
      non_call_months: CalendarsMapper.toCalendarsNonCallPeriodFilterPayload(query.nonCallPeriod),
      call_types: CalendarsMapper.toCalendarsCallTypePayload(query),
      coupon_types: CalendarsMapper.toCalendarsCouponTypePayload(query),
      is_capped: CalendarsMapper.toCalendarsCappedPayload(query),
      settlement_types: CalendarsMapper.toCalendarsSettlementTypePayload(query),
      sub_asset_classes: query.toggles.propIndices ? { PROP_INDICES: false } : undefined,
      is_visible: true,
      show_as_nme: false,
    };

    if (query.approved !== undefined) filters.approved = query.approved;

    return {
      calendar_note_id: query.calendarNoteId ? query.calendarNoteId : undefined,
      filters,
      page: pageParam.next,
      page_length: 10,
    };
  },
  toFixedIncomeUnderlyingQueryRequest: (
    search: string,
    query: FixedIncomeQueryModel,
    pageParam = DEFAULT_INFINITE_QUERY_NEXT_PAGINATION,
  ): ApiCalendarUnderlyingSearchRequestModel => {
    return {
      calendar_note_id: query.calendarNoteId ? query.calendarNoteId : undefined,
      filters: {
        favorited: query.checkboxes.starred ? query.checkboxes.starred : undefined,
        has_active_allocations: query.checkboxes.submittedIndication ? query.checkboxes.submittedIndication : undefined,
        issuers: query.issuers.length > 0 ? query.issuers.map((issuer) => parseInt(issuer.id)) : undefined,
        search,
        end_date: DateTime.fromFormat(query.endDate, YEAR_MONTH_DAY_DATE_PICKER_FORMAT).toISODate() as string,
        start_date: DateTime.fromFormat(query.startDate, YEAR_MONTH_DAY_DATE_PICKER_FORMAT).toISODate() as string,
        term: CalendarsMapper.toCalendarsTermFilterPayload(query.term),
        protection_amount: CalendarsMapper.toCalendarsProtectionAmountFilterPayload(query.protectionAmount),
        call_types: CalendarsMapper.toCalendarsCallTypePayload(query),
        coupon_types: CalendarsMapper.toCalendarsCouponTypePayload(query),
        is_capped: CalendarsMapper.toCalendarsCappedPayload(query),
        sub_asset_classes: query.toggles.propIndices ? { PROP_INDICES: false } : undefined,
        is_visible: true,
        show_as_nme: true,
        approved: true,
      },
      sort: [{ field: 'name', direction: SortModelDirectionEnum.desc }],
      page: pageParam.next,
      page_length: 10,
    };
  },
};

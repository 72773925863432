import { forwardRef, HTMLAttributes, ReactElement, Ref, SyntheticEvent, useState } from 'react';

import { rolesInputSelectionAtom } from '@halo-atoms/common';
import { OrganizationModel, UserRoleModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useRolesInfiniteQuery } from '@halo-data-sources/queries';
import { LocalizedTextField, LocalizedTypography, useCombinedStyling } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import {
  Autocomplete,
  AutocompleteChangeReason,
  autocompleteClasses,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  CircularProgress,
  SxProps,
  TextFieldProps,
} from '@mui/material';
import { useAtom } from 'jotai';

const autoCompleteSx = {
  [`.${autocompleteClasses.input}`]: {
    padding: `0 0 0 4px !important`,
    height: 32,
  },
  [`.${autocompleteClasses.inputRoot}`]: {
    gap: 0.5,
    display: 'flex',
    flexWrap: 'wrap',
  },
  [`.${autocompleteClasses.tag}`]: {
    m: 0,
  },
};

const optionSx = {
  '&:hover': {
    backgroundColor: 'grey.300',
  },
};

const baseChipSx = {
  height: '100%',
};

const chipLabelSx = {
  whiteSpace: 'normal',
};

export type RoleTypeAheadOptions = {
  organization?: OrganizationModel;
};

export type RoleTypeAheadProps<T> = {
  id?: string;
  name?: string;
  value?: Array<UserRoleModel>;
  onChange: (selection: Array<T>, reason?: AutocompleteChangeReason) => void;
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  variant?: TextFieldProps['variant'];
  sx?: SxProps<HaloTheme>;
  size?: 'small' | 'medium' | 'large';
  options?: RoleTypeAheadOptions;
  ChipProps?: {
    size?: 'small' | 'medium';
    color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    sx?: SxProps<HaloTheme>;
  };
};

const WrappedRoleTypeAhead = (props: RoleTypeAheadProps<UserRoleModel>, ref: Ref<unknown>): ReactElement => {
  const { value, onChange, size = 'large', ChipProps, sx, options, ...inputProps } = props;

  const [selectedRoles, setSelectedRoles] = useAtom(rolesInputSelectionAtom);
  const [query, setQuery] = useState<string>('');

  const combinedStyling = useCombinedStyling(autoCompleteSx, sx);

  const roles = value ?? selectedRoles;

  const {
    data = { pages: [] },
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useRolesInfiniteQuery({ query, organization: options?.organization });

  const loading = isLoading || isFetchingNextPage;
  const roleOptions = data.pages.flatMap((page) => page.roles.map((role) => role));

  const handleInputChange = (_: unknown, newValue: string) => setQuery(newValue);

  const handleRenderOptionLabel = (option: UserRoleModel) => option.name as string;

  const handleSelection = (
    _: SyntheticEvent<Element, Event>,
    selection: Array<UserRoleModel>,
    reason: AutocompleteChangeReason,
  ) => {
    if (!value) setSelectedRoles(selection);
    onChange?.(selection, reason);
  };

  const inputLoading = isFetching || isFetchingNextPage || isLoading;

  const noOptionsText = inputLoading ? translations.common.searchingEllipsis : translations.common.noResultsFound;

  const chipColor = ChipProps?.color ?? 'secondary';
  const chipSize = ChipProps?.size ?? 'medium';
  const chipSx = ChipProps?.sx;
  const combinedChipSx = useCombinedStyling(baseChipSx, chipSx);

  const handleTagRender = (value: Array<UserRoleModel>, getTagProps: AutocompleteRenderGetTagProps) => {
    const sortedValues = value.sort((roleA, roleB) => roleA.name.localeCompare(roleB.name));

    return sortedValues.map((tag, index) => {
      const label = (
        <LocalizedTypography sx={chipLabelSx} variant="body2">
          {tag.name}
        </LocalizedTypography>
      );
      return (
        <Chip
          {...getTagProps({ index })}
          key={tag.id}
          sx={combinedChipSx}
          color={chipColor}
          size={chipSize}
          label={label}
        />
      );
    });
  };

  const handleRenderInput = (params: AutocompleteRenderInputParams) => (
    <LocalizedTextField
      {...params}
      {...inputProps}
      inputRef={ref}
      label={translations.common.roles}
      fullWidth
      size={size}
      multiline
      InputProps={{
        ...params.InputProps,
        endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
      }}
    />
  );

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: UserRoleModel) => (
    <Box {...props} sx={optionSx} component="li" key={option.id}>
      <LocalizedTypography sx={optionSx}>{option.name}</LocalizedTypography>
    </Box>
  );

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      disableClearable
      disablePortal
      multiple
      openOnFocus
      filterOptions={(options) => options}
      noOptionsText={noOptionsText}
      sx={combinedStyling}
      options={roleOptions}
      getOptionLabel={handleRenderOptionLabel}
      renderTags={handleTagRender}
      renderInput={handleRenderInput}
      onInputChange={handleInputChange}
      onChange={handleSelection}
      value={roles}
      ListboxProps={{
        role: 'list-box',
        onScroll: (event: SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          const isBottom = listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight * 0.5;
          const loadMoreContent = !isLoading && !isFetchingNextPage && hasNextPage && isBottom;
          if (loadMoreContent) void fetchNextPage();
        },
      }}
      renderOption={handleRenderOption}
    />
  );
};

export const RoleTypeAhead = forwardRef<Ref<unknown>, RoleTypeAheadProps<UserRoleModel>>(WrappedRoleTypeAhead);

import { getV2PayoffDataModel } from '@halo-data-sources/clients';
import { mapApiGetV2PayoffDataModelToV2PayoffChartModel } from '@halo-data-sources/mappers/notePayoffMapper';
import { NotePayoffSwitchResult } from '@halo-data-sources/switches';
import { LineChartLineDefinition, LineChartReferenceLine } from '@halodomination/halo-fe-common';

export const v2PayoffSwitchFn = async (
  quoteId?: number,
  protectionPercent?: number,
  noteId?: number,
): Promise<NotePayoffSwitchResult | null> => {
  if (quoteId === undefined || noteId === undefined) return null;

  const response = await getV2PayoffDataModel(quoteId, noteId);

  const { x, y, curveNames } = mapApiGetV2PayoffDataModelToV2PayoffChartModel(response);

  const lines: LineChartLineDefinition[] = curveNames.map((name) => ({
    label: name,
  }));

  const data = x.map((xValue, index) => {
    const dataPoint: { x: number; [key: string]: number } = { x: parseFloat(xValue.toFixed(2)) };

    curveNames.forEach((name, curveIndex) => {
      dataPoint[name] = parseFloat(y[curveIndex][index].toFixed(2));
    });

    return dataPoint;
  });

  const cap = protectionPercent ? -protectionPercent : null;
  const referenceLines: Array<LineChartReferenceLine> | undefined = cap
    ? [{ point: cap, label: 'Protection level', orientation: 'y' }]
    : undefined;

  return { lines, data, references: referenceLines };
};

import { ReactElement } from 'react';

import { Chipography } from '@halo-common/components';
import { ApiUnderlyingModel } from '@halo-data-sources/models';

import { commonAssets } from './CommonUnderlyingAssets';

interface UnderlyingCommonAssetsProps {
  selectedAssets: Array<ApiUnderlyingModel>;
  handleSelectAsset: (asset: ApiUnderlyingModel) => void;
}

export const UnderlyingCommonAssetChips = ({
  selectedAssets,
  handleSelectAsset,
}: UnderlyingCommonAssetsProps): ReactElement => {
  const tickersToRender: Array<ReactElement> = [];
  commonAssets.forEach((commonAsset) => {
    if (!selectedAssets.some((asset) => asset.id === commonAsset.id)) {
      tickersToRender.push(
        <Chipography
          size="small"
          key={commonAsset.id}
          label={commonAsset.name}
          variant="outlined"
          sx={{
            bgcolor: 'common.white',
            color: 'primary.main',
            borderColor: 'primary.main',
            m: 0.5,
            cursor: 'pointer',
          }}
          onClick={() => handleSelectAsset(commonAsset)}
        />,
      );
    }
  });
  return <>{tickersToRender}</>;
};

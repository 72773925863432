import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiCalendarUnderlyingSearchRequestModel,
  ApiQueryRequestModel,
  ApiUnderlyingModel,
  ApiUnderlyingSearchResponseModel,
  UnderlyingTradableRequestModel,
} from '@halo-data-sources/models';

const UNDERLYING_SEARCH_PATH = '/issuer/v1/underlying_search';
const COMMON_TRADABLES_SEARCH_PATH = '/common/tradable/query';
const CALENDAR_TRADABLES_SEARCH_PATH = '/execution/calendar/tradable/search';
const TRADABLES_SEARCH_ROUTE = '/common/tradable/query';

export const searchForUnderlying = async (
  options: Partial<{ symbol: string }> = {},
): Promise<Array<ApiUnderlyingModel>> => {
  const { symbol } = {
    symbol: '',
    ...options,
  };

  const clientConfiguration = await getClientConfiguration(UNDERLYING_SEARCH_PATH);

  const queryStringParameters = new URLSearchParams({ symbol });

  const response = await request<Array<ApiUnderlyingModel>>(
    `${clientConfiguration.basePath}?${queryStringParameters.toString()}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const getUnderlyingSearch = async (
  requestModel: ApiQueryRequestModel,
): Promise<ApiUnderlyingSearchResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_TRADABLES_SEARCH_PATH);

  const response = await request<ApiUnderlyingSearchResponseModel>(clientConfiguration.basePath, {
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...requestModel,
    }),
  });

  return response;
};

export const getCalendarUnderlyingSearch = async (
  requestModel: ApiCalendarUnderlyingSearchRequestModel,
): Promise<ApiUnderlyingSearchResponseModel> => {
  const clientConfiguration = await getClientConfiguration(CALENDAR_TRADABLES_SEARCH_PATH);

  const response = await request<ApiUnderlyingSearchResponseModel>(clientConfiguration.basePath, {
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...requestModel,
    }),
  });

  return response;
};

export const getUnderlyingTradableSearch = async (
  requestModel: UnderlyingTradableRequestModel,
): Promise<ApiUnderlyingSearchResponseModel> => {
  const clientConfiguration = await getClientConfiguration(TRADABLES_SEARCH_ROUTE);

  const response = await request<ApiUnderlyingSearchResponseModel>(clientConfiguration.basePath, {
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...requestModel,
    }),
  });

  return response;
};

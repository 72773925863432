import { ReactElement } from 'react';

import { AuraNoteModal } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { LocalizedTextField } from '@halodomination/halo-fe-common';
import { Stack, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

export const AuraNoteForm = (): ReactElement => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<AuraNoteModal>();

  const { ref: allocationRef, ...allocationInputProps } = register('allocation', {
    required: { value: true, message: translations.aura.common.allocationRequired },
  });
  const { ref: quoteRef, ...quoteInputProps } = register('quote', {
    required: { value: true, message: translations.aura.common.quoteRequired },
  });

  return (
    <form style={{ width: '100%' }}>
      <Stack direction="column" spacing={3}>
        <Stack direction="row" spacing={2}>
          <Stack sx={{ width: '75%' }}>
            <FormControl fullWidth error={Boolean(errors?.category)}>
              <InputLabel>{translations.aura.common.allocationClass}</InputLabel>
              <Controller
                name="category"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: translations.aura.common.categoryRequired,
                  },
                }}
                render={({ field }) => (
                  <Select {...field} label={translations.aura.common.allocationClass}>
                    <MenuItem value="fixed">{translations.aura.common.fixedIncomeLabel}</MenuItem>
                    <MenuItem value="equity">{translations.aura.common.equityLabel}</MenuItem>
                    <MenuItem value="cash">{translations.aura.common.cashLabel}</MenuItem>
                    <MenuItem value="alternative">{translations.aura.common.alternativeLabel}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Stack>
          <Stack sx={{ width: '25%' }}>
            <LocalizedTextField
              fullWidth
              label={translations.aura.common.allocationLabel}
              {...allocationInputProps}
              inputRef={allocationRef}
              inputProps={{ min: 0, max: 100 }}
              error={Boolean(errors?.allocation)}
              helperText={errors?.allocation?.message}
              type="number"
              InputProps={{
                endAdornment: '%',
              }}
            />
          </Stack>
        </Stack>
        <Stack sx={{ width: '45%' }}>
          <LocalizedTextField
            label={translations.aura.common.quoteLabel}
            {...quoteInputProps}
            inputRef={quoteRef}
            error={Boolean(errors?.quote)}
            helperText={errors?.quote?.message}
            type="number"
            InputProps={{
              endAdornment: '%',
            }}
          />
        </Stack>
      </Stack>
    </form>
  );
};

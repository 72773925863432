import { DEFAULT_LOCALE, HALO_ORGANIZATION_DETAILS } from '@halo-common/constants';
import { AssetIdentifierEnum } from '@halo-common/enums';
import {
  SettingSetRepIdModel,
  WhiteLabelBrandModel,
  WhiteLabelImageModel,
  WhiteLabelModel,
  WhiteLabelRepIdSettingSetModel,
} from '@halo-common/models';
import { ApiDocGenMapper, ApiIssuerMapper } from '@halo-data-sources/mappers';
import {
  ApiRepIdSettingSetModel,
  ApiWhiteLabelBrandModel,
  ApiWhiteLabelChartColorModel,
  ApiWhiteLabelImageModel,
  ApiWhiteLabelModel,
  ApiWhiteLabelRepIdSettingSetModel,
  ApiWhiteLabelThemeModel,
} from '@halo-data-sources/models';
import { ChartColors, defaultThemeOptions as HaloBaseTheme, HaloThemeOptions } from '@halodomination/halo-fe-theme';

export const ApiWhiteLabelChartColorMapper = {
  toHaloChartColorModel: (
    chartColor: ApiWhiteLabelChartColorModel | undefined,
    defaultColor: ApiWhiteLabelChartColorModel,
  ): ApiWhiteLabelChartColorModel => {
    const chartMain = chartColor?.main ?? '';
    const chartContrastText = chartColor?.contrastText ?? '';

    const hexLong = /^#[a-fA-F0-9]{6}$/;
    const backgroundColor = hexLong.test(chartMain) ? `${chartMain}26` : defaultColor.background;
    const mainColor = hexLong.test(chartMain) ? chartMain : defaultColor.main;
    const contrastTextColor = hexLong.test(chartContrastText) ? chartContrastText : defaultColor.contrastText;

    return {
      main: mainColor,
      background: backgroundColor,
      contrastText: contrastTextColor,
    };
  },
};

export const ApiWhiteLabelThemeMapper = {
  toHaloChartThemeModel: (theme: ApiWhiteLabelThemeModel): ChartColors => {
    const { charts } = theme;

    const { palette } = HaloBaseTheme;
    const defaultColors = palette.common.charts;

    return {
      accent1: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart01, defaultColors.accent1),
      accent2: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart02, defaultColors.accent2),
      accent3: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart03, defaultColors.accent3),
      accent4: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart04, defaultColors.accent4),
      accent5: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart05, defaultColors.accent5),
      accent6: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart06, defaultColors.accent6),
      accent7: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart07, defaultColors.accent7),
      accent8: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart08, defaultColors.accent8),
      accent9: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart09, defaultColors.accent9),
      accent10: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart10, defaultColors.accent10),
      accent11: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart11, defaultColors.accent11),
      accent12: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart12, defaultColors.accent12),
      accent13: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.chart13, defaultColors.accent13),
      positive: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.['chart-positive'], defaultColors.positive),
      negative: ApiWhiteLabelChartColorMapper.toHaloChartColorModel(charts?.['chart-negative'], defaultColors.negative),
    };
  },
  toHaloThemeModel: (theme: ApiWhiteLabelThemeModel): HaloThemeOptions => {
    const { mode, typography, palette } = HaloBaseTheme;

    const fontFamily = theme?.typography?.fontFamily || typography?.fontFamily;
    const fontWeightLight = theme?.typography?.fontWeightLight || typography?.fontWeightLight;
    const fontWeightRegular = theme?.typography?.fontWeightRegular || typography?.fontWeightRegular;
    const fontWeightMedium = theme?.typography?.fontWeightMedium || typography?.fontWeightMedium;
    const fontWeightBold = theme?.typography?.fontWeightBold || typography?.fontWeightBold;

    const chartColors = ApiWhiteLabelThemeMapper.toHaloChartThemeModel(theme);
    // TODO: REMOVE COMMENT AFTER FEATURE BRANCH TEST
    return {
      mode,
      palette: {
        primary: {
          ...palette.primary,
          main: theme?.palette.primary.main || palette?.primary?.main,
          light: theme?.palette.primary.light || palette?.primary?.light,
          dark: theme?.palette.primary.dark || palette?.primary?.dark,
          background: theme?.palette.primary.background || palette?.primary?.background,
          contrastText: theme?.palette.primary.contrastText || palette?.primary?.contrastText,
        },
        secondary: {
          ...palette.secondary,
          main: theme?.palette.secondary.main || palette?.secondary?.main,
          light: theme?.palette.secondary.light || palette?.secondary?.light,
          dark: theme?.palette.secondary.dark || palette?.secondary?.dark,
          background: theme?.palette.secondary.background || palette?.secondary?.background,
          contrastText: theme?.palette.secondary.contrastText || palette?.secondary?.contrastText,
        },
        success: {
          ...palette.success,
          main: theme?.palette.success.main || palette?.success?.main,
          dark: theme?.palette.success.dark || palette?.success?.dark,
          background: theme?.palette.success.background || palette?.success?.background,
          contrastText: theme?.palette.success.contrastText || palette?.success?.contrastText,
          darkText: theme?.palette.success.darkText || palette?.success?.darkText,
        },
        info: {
          ...palette.info,
          main: theme?.palette.info.main || palette?.info?.main,
          dark: theme?.palette.info.dark || palette?.info?.dark,
          background: theme?.palette.info.background || palette?.info?.background,
          contrastText: theme?.palette.info.contrastText || palette?.info?.contrastText,
          darkText: theme?.palette.info.darkText || palette?.info?.darkText,
        },
        warning: {
          ...palette.warning,
          main: theme?.palette.warning.main || palette?.warning?.main,
          dark: theme?.palette.warning.dark || palette?.warning?.dark,
          background: theme?.palette.warning.background || palette?.warning?.background,
          contrastText: theme?.palette.warning.contrastText || palette?.warning?.contrastText,
          darkText: theme?.palette.warning.darkText || palette?.warning?.darkText,
        },
        error: {
          ...palette.error,
          main: theme?.palette.error.main || palette?.error?.main,
          dark: theme?.palette.error.dark || palette?.error?.dark,
          background: theme?.palette.error.background || palette?.error?.background,
          contrastText: theme?.palette.error.contrastText || palette?.error?.contrastText,
          darkText: theme?.palette.error.darkText || palette?.error?.darkText,
        },
        common: {
          ...palette.common,
          charts: chartColors,
        },
      },
      typography: {
        ...theme?.typography,
        fontFamily,
        fontWeightLight,
        fontWeightRegular,
        fontWeightMedium,
        fontWeightBold,
      },
    };
  },
};

export const ApiWhiteLabelImageMapper = {
  toWhiteLabelImage: (model: ApiWhiteLabelImageModel): WhiteLabelImageModel => ({
    bucket: model.bucket,
    fileName: model.filename,
    id: model.id,
    path: model.path,
    altPath: model.path_,
  }),
};

export const ApiWhiteLabelBrandMapper = {
  toWhiteLabelBrand: (brand: ApiWhiteLabelBrandModel): WhiteLabelBrandModel => ({
    name: brand.name,
    onboardingBackground: brand.onboarding_background
      ? ApiWhiteLabelImageMapper.toWhiteLabelImage(brand.onboarding_background)
      : null,
    isOnboardingBackgroundLight: brand.onboarding_background_light,
    logoDark: brand.logo_dark ? ApiWhiteLabelImageMapper.toWhiteLabelImage(brand.logo_dark) : null,
    logoLight: brand.logo_light ? ApiWhiteLabelImageMapper.toWhiteLabelImage(brand.logo_light) : null,
    subtitles: brand.subtitles,
    showPoweredByHalo: brand.powered_by_halo_disclaimer,
  }),
};

export const ApiRepIdSettingMapper = (data: ApiRepIdSettingSetModel): SettingSetRepIdModel => ({
  allowMultiple: data.allow_multiple,
  displayName: data.display_name,
  id: data.id,
  level: data.level,
  maxCharacters: data.max_characters,
  minCharacters: data.min_characters,
  type: data.type,
  required: data.required,
  rules: {
    register: {
      display: data.collect_on_sign_up,
    },
    profile: {
      display: data.display_on_account_profile,
      edit: data.edit_on_account_profile,
    },
  },
});

export const ApiWhiteLabelMapper = {
  toRepIdSettingSet: (data: ApiWhiteLabelRepIdSettingSetModel): WhiteLabelRepIdSettingSetModel => ({
    id: data.id,
    name: data.name,
    repIdSettingSet: data.rep_id_settings ? data.rep_id_settings.map(ApiRepIdSettingMapper) : undefined,
  }),
  toWhiteLabel: (whiteLabel: ApiWhiteLabelModel): WhiteLabelModel => {
    const whiteLabelCurrencies = whiteLabel.currencies ?? [];
    const whiteLabelIssuers = whiteLabel.issuers ?? [];
    const currency = whiteLabel.default_currency;
    const performanceReports = whiteLabel.portfolio_performance_reports;

    const defaultCurrency = currency
      ? { id: currency.id, code: currency.iso_code, symbol: currency.symbol, name: currency.name }
      : undefined;

    const portfolioPerformanceReports = performanceReports
      ? performanceReports.map(ApiDocGenMapper.toPortfolioPerformanceReport)
      : [];

    const baseCurrencies = whiteLabelCurrencies.map(({ iso_code, ...currency }) => ({ ...currency, code: iso_code }));
    const filteredCurrencies = baseCurrencies.filter((currency) => currency.id !== defaultCurrency?.id);
    const currencies = defaultCurrency ? [defaultCurrency, ...filteredCurrencies] : [...baseCurrencies];
    const issuers = whiteLabelIssuers.map(ApiIssuerMapper.toIssuerModel);

    const availableLocales = whiteLabel.localization_provider?.available_locales.map((locales) => {
      if (locales.includes(DEFAULT_LOCALE)) return DEFAULT_LOCALE;
      return locales;
    });

    const repIdSettingSet = whiteLabel.rep_id_setting_set
      ? ApiWhiteLabelMapper.toRepIdSettingSet(whiteLabel.rep_id_setting_set)
      : null;

    return {
      accountSaveAccess: whiteLabel.account_save_access,
      assetIdentifiers: whiteLabel.asset_identifiers.map((id) => AssetIdentifierEnum[id]),
      brand: ApiWhiteLabelBrandMapper.toWhiteLabelBrand(whiteLabel.brand),
      currencies,
      dateFormat: whiteLabel.date_format,
      defaultCurrency,
      filterAccountDropdown: whiteLabel.filter_account_dropdown,
      favicon: whiteLabel.favicon,
      footerContent: whiteLabel.footer_content,
      id: whiteLabel.id,
      issuers,
      localizationPublicKey: whiteLabel.localization_provider?.transifex_native_token ?? null,
      availableLocales,
      userFacingName: whiteLabel.user_facing_name ?? HALO_ORGANIZATION_DETAILS.name,
      indicativeTermsheets: whiteLabel.indicative_termsheets,
      name: whiteLabel.brand.name,
      noteProposals: whiteLabel.note_proposals,
      onboardingRoles: whiteLabel.onboarding_roles,
      performanceReportErgId: whiteLabel.performance_report_ergid,
      portfolioPerformanceReports,
      positionSaveAccess: whiteLabel.position_save_access,
      primaryAssetIdentifier: AssetIdentifierEnum[whiteLabel.primary_asset_identifier],
      repIdSettingSet,
      timezoneIANA: whiteLabel.timezone,
      timezoneName: whiteLabel.timezone_name,
      titleTag: whiteLabel.title_tag,
    };
  },
};

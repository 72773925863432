import { ReactElement } from 'react';

import { translations } from '@halo-common/translations';
import { ApiUnderlyingModel } from '@halo-data-sources/models';
import { Iconography, LocalizedTypography } from '@halodomination/halo-fe-common';
import { useHaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Stack, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

interface UnderlyingWeightedInputsProps {
  weightedAssets: Record<number, number>;
  selectedAssets: Array<ApiUnderlyingModel>;
  onWeightChange: (assetId: number, weight: number) => void;
}

export const UnderlyingWeightedInputs = ({
  weightedAssets,
  selectedAssets,
  onWeightChange,
}: UnderlyingWeightedInputsProps): ReactElement => {
  const { theme } = useHaloTheme();

  const weightedSum = Object.values(weightedAssets).reduce((acc, current) => {
    return Number(current.toFixed(1)) + Number(acc.toFixed(1));
  }, 0);
  const showError = weightedSum !== 100;

  const isNotWholeNumber = weightedSum % 1 !== 0;
  const formattedWeightedSum = `  =  ${isNotWholeNumber ? weightedSum.toFixed(1) : weightedSum}% `;

  return (
    <Box sx={{ pb: 2, pt: 1 }}>
      <Stack direction="row" alignItems="center">
        <Stack direction="row" flexWrap="wrap" sx={{ maxWidth: '330px', gap: 1, mr: 1 }}>
          {selectedAssets.map((asset) => (
            <NumericFormat
              key={asset.id}
              value={weightedAssets[asset.id]}
              onValueChange={(values) =>
                values.floatValue ? onWeightChange(asset.id, Number(values.floatValue?.toFixed(1))) : null
              }
              customInput={TextField}
              label={asset.name}
              suffix="%"
              size="medium"
              sx={{ maxWidth: '75px' }}
            />
          ))}
        </Stack>
        <>
          {formattedWeightedSum}
          <Box sx={{ pl: 1 }}>
            {showError ? (
              <Iconography color="error.dark" iconName="circle-exclamation" />
            ) : (
              <Iconography color="success.main" iconName="circle-check" />
            )}
          </Box>
        </>
      </Stack>
      {showError && (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            backgroundColor: theme.palette.error.background,
            borderRadius: 1,
            p: 1,
            mt: 1,
          }}
        >
          <Iconography color={theme.palette.error.dark} iconName="circle-exclamation" />
          <LocalizedTypography sx={{ color: theme.palette.error.dark, pl: 1 }}>
            {translations.noteCreate.weightsAddUp}
          </LocalizedTypography>
        </Stack>
      )}
    </Box>
  );
};

import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { FormControlLabel, Switch } from '@mui/material';
import { useAtom } from 'jotai';

const labelSx = {
  fontSize: '0.875rem',
};

const toggleSx = {
  marginLeft: 0.25,
};

export type CalendarAssetTypeFiltersProps = {
  tag: string;
};

export const CalendarAssetTypeFilters = ({ tag }: CalendarAssetTypeFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleSwitchChange = () => {
    setSideBarFilters({ tag, toggle: 'propIndices' });
  };

  return (
    <CollapsibleFilters
      title={DynamicTypeFiltersSideBarTitlesDict.calendarAssetType}
      walkMeClassName="wm-productshelf-filters-togglebaskets"
    >
      <FormControlLabel
        sx={toggleSx}
        label={
          <LocalizedTypography sx={labelSx} variant="body2">
            {translations.dynamicFilters.common.proprietaryIndices}
          </LocalizedTypography>
        }
        control={<Switch checked={!sideBarFilters.toggles.propIndices} onChange={handleSwitchChange} size="small" />}
      />
    </CollapsibleFilters>
  );
};

import { ReactElement } from 'react';

import { ProductNoteTypeChip } from '@halo-common/components';
// import { useNoteTypeQuery } from '@halo-data-sources/queries';
import { Dropdown, useCombinedStyling } from '@halodomination/halo-fe-common';
import type { DropdownItem, DropdownProps } from '@halodomination/halo-fe-common';

const dropdownSx = {
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
};

type NoteTypeDropdownProps = Omit<DropdownProps, 'dropdownItems' | 'menuProps'>;

export const NoteTypeDropdown = ({
  label,
  size = 'large',
  value,
  onChange,
  sx,
}: NoteTypeDropdownProps): ReactElement => {
  // TODO: backend endpoint not created yet. Use mocks or hardcoded notes.
  // const { data } = useNoteTypeQuery({ enabled: false });
  const noteTypes = [
    { type: 'Catapult' },
    { type: 'Income' },
    { type: 'Growth' },
    { subType: 'Snowball' },
    { type: 'Digital' },
    { subType: 'Digital Plus' },
    { type: 'Absolute' },
    { subType: 'PPN' },
  ];

  const dropdownItems: Array<DropdownItem> = noteTypes.map((noteType) => {
    return noteType?.type
      ? {
          label: <ProductNoteTypeChip key={noteType.type} type={noteType.type} />,
          value: noteType.type,
        }
      : ({
          label: <ProductNoteTypeChip key={noteType.subType} subtype={noteType.subType} />,
          value: noteType.subType,
        } as DropdownItem);
  });

  const combinedSx = useCombinedStyling(dropdownSx, sx);

  return (
    <Dropdown
      sx={combinedSx}
      label={label}
      size={size}
      value={value}
      onChange={onChange}
      dropdownItems={dropdownItems}
      menuProps={{
        PaperProps: {
          sx: {
            mt: 3,
          },
        },
      }}
    />
  );
};

import { HTMLAttributes, ReactElement, SyntheticEvent, useMemo, useState } from 'react';

import { OrganizationModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useOrganizationsInfiniteQuery } from '@halo-data-sources/queries/organization';
import { LocalizedTextField, LocalizedTextFieldProps } from '@halodomination/halo-fe-common';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  Typography,
  debounce,
  inputBaseClasses,
} from '@mui/material';

const endAdornmentSx = { display: 'flex' };
const loadingIndicatorSx = { marginRight: 1 };
const inputSx = {
  [`.${inputBaseClasses.root}`]: {
    paddingLeft: 2,
  },
};

const optionSx = {
  '&:hover': {
    backgroundColor: 'grey.300',
  },
  '&:focus': {
    backgroundColor: 'grey.300',
  },
};

export type OrganizationTypeAheadProps = Omit<
  AutocompleteProps<OrganizationModel, undefined, boolean, undefined>,
  'options' | 'renderInput' | 'onChange' | 'ref'
> & {
  onChange?: (organizations: OrganizationModel | null) => void;
  TextFieldProps?: LocalizedTextFieldProps;
};

export const OrganizationTypeAhead = ({
  TextFieldProps,
  onChange,
  defaultValue,
  ...props
}: OrganizationTypeAheadProps): ReactElement => {
  const [query, setQuery] = useState('');

  const { data, isFetched, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useOrganizationsInfiniteQuery(query);

  const organizations = data?.pages.flatMap((page) => page.organizations) ?? [];

  const debouncedSearchHandler = useMemo(() => debounce((value) => setQuery(value), 500), []);

  const handleSearch = (_: unknown, value: string) => {
    debouncedSearchHandler(value);
  };

  const handleChange = (_: unknown, option: OrganizationModel | null) => {
    onChange?.(option);
  };

  const getOptionLabel = (option: OrganizationModel) => option.name;

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: OrganizationModel) => (
    <Box {...props} sx={optionSx} component="li" key={option.id}>
      <Typography sx={optionSx}>{getOptionLabel(option)}</Typography>
    </Box>
  );

  const noOptionsText = isFetched ? translations.common.noResultsFound : translations.common.searchingEllipsis;

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const textFieldInputLoadingIndicator = isLoading ? <CircularProgress sx={loadingIndicatorSx} size={20} /> : null;

    const textFieldInputProps = {
      ...params.InputProps,
      endAdornment: (
        <Box sx={endAdornmentSx}>
          {textFieldInputLoadingIndicator}
          {params.InputProps.endAdornment}
        </Box>
      ),
    };

    return (
      <LocalizedTextField
        {...params}
        label={translations.userAndOrgCreation.selectOrganization}
        multiline={props.multiple}
        size="large"
        sx={inputSx}
        InputProps={textFieldInputProps}
        {...TextFieldProps}
      />
    );
  };

  return (
    <Autocomplete<OrganizationModel, undefined, boolean, undefined>
      {...props}
      selectOnFocus
      defaultValue={defaultValue}
      noOptionsText={noOptionsText}
      options={organizations}
      onChange={handleChange}
      renderInput={renderInput}
      onInputChange={handleSearch}
      getOptionLabel={getOptionLabel}
      renderOption={handleRenderOption}
      ListboxProps={{
        role: 'list-box',
        onScroll: (event: SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          const isBottom = listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight * 0.5;
          const loadMoreContent = !isLoading && !isFetchingNextPage && hasNextPage && isBottom;
          if (loadMoreContent) void fetchNextPage();
        },
      }}
    />
  );
};

import { ChangeEvent, ReactElement } from 'react';

import { selectedStrategyAtom } from '@halo-atoms/sma';
import { AccountFormField } from '@halo-common/formComponents';
import { AccountTypeAheadOption, AccountTypeAheadSelectionReasons } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { SMAAllocationModalFormModel } from '@halo-modules/app';
import { CurrencyInput, Iconography, IconographyProps, LocalizedTextField } from '@halodomination/halo-fe-common';
import { Box, Checkbox, FormControlLabel, IconButton, Stack, Typography, TypographyProps } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useController, useFormContext } from 'react-hook-form';

const checkboxSx = {
  paddingRight: 8,
};

const checkboxErrorTypoProps: TypographyProps = {
  color: 'error.main',
  fontSize: '12px',
  ml: 2,
};

export type SMAAllocationRowProps = {
  id: number;
  checkboxLabel?: string;
  onCreateAccount: (id: number) => void;
  onDelete: (id: number) => void;
};

export const SMAAllocationRow = ({
  id,
  checkboxLabel,
  onCreateAccount,
  onDelete,
}: SMAAllocationRowProps): ReactElement => {
  const selectedStrategy = useAtomValue(selectedStrategyAtom);

  const {
    control,
    formState: { errors },
    getValues,
    reset,
  } = useFormContext<SMAAllocationModalFormModel>();

  const accountFieldName: `allocations.${number}.accountOption` = `allocations.${id}.accountOption`;
  const checkboxFieldName: `allocations.${number}.checkbox` = `allocations.${id}.checkbox`;
  const amountFieldName: `allocations.${number}.amount` = `allocations.${id}.amount`;
  const reasonableRestrictionsFieldName: `allocations.${number}.reasonableRestrictions` = `allocations.${id}.reasonableRestrictions`;

  const { field: amountField } = useController({ control, name: amountFieldName });
  const { field: checkboxField } = useController({ control, name: checkboxFieldName });
  const { field: reasonableRestrictionsField } = useController({ control, name: reasonableRestrictionsFieldName });

  const { ref: checkboxFieldRef, value: checkboxFieldValue, ...checkboxFieldProps } = checkboxField;

  const accountError = errors?.allocations?.[id]?.accountOption?.message;
  const hasAccountError = Boolean(accountError);

  const hasAmountError = Boolean(errors?.allocations?.[id]?.amount);
  const amountErrorType = errors?.allocations?.[id]?.amount?.type;
  const amountErrorMessages: Record<string, string> = {
    'multiple-of-thousand': translations.sma.multipleOfThousands,
    typeError: translations.sma.minimumThousand,
    nullable: translations.sma.minimumThousand,
    required: translations.sma.minimumThousand,
  };

  const amountHelperText = amountErrorType ? amountErrorMessages[amountErrorType] : '';

  const checkboxError = errors?.allocations?.[id]?.checkbox?.message;
  const hasCheckboxError = Boolean(checkboxError);

  const accountFieldSx = { flexBasis: '60%', marginTop: hasAccountError ? 2 : 0 };
  const amountFieldSx = { flexBasis: '40%', marginTop: hasAmountError ? 2 : 0 };
  const allocationFieldContainerSx = { py: checkboxLabel ? 0 : 2, width: '100%' };
  const iconButtonContainerSx = { py: checkboxLabel ? 0 : 2 };
  const iconButtonSx = { height: 30 };

  const actions = [
    {
      icon: 'plus' as IconographyProps['iconName'],
      label: translations.allocationsModal.common.accountTypeAheadCreateAccountOption,
      clearedOnSelection: true,
    },
  ];

  const onDeleteClick = () => {
    const shouldResetFormFields = getValues().allocations.length === 1;
    if (shouldResetFormFields) reset({ allocations: [{ accountOption: null, amount: null, checkbox: false }] });
    else onDelete(id);
  };

  const handleAccountChange = (_: AccountTypeAheadOption | null, reason?: AccountTypeAheadSelectionReasons) => {
    const useCreateAccount = reason === 'action';
    if (useCreateAccount) onCreateAccount(id);
  };

  const checkboxErrorIndication = hasCheckboxError ? (
    <Typography {...checkboxErrorTypoProps}>{checkboxError}</Typography>
  ) : null;

  const checkbox = checkboxLabel ? (
    <Stack direction="column">
      <FormControlLabel
        {...checkboxFieldProps}
        inputRef={checkboxFieldRef}
        control={<Checkbox checked={checkboxFieldValue} />}
        label={checkboxLabel}
        sx={checkboxSx}
      />
      {checkboxErrorIndication}
    </Stack>
  ) : null;

  return (
    <Stack direction="column" spacing={3}>
      <Stack direction="row" spacing={1}>
        <Stack direction="column" sx={allocationFieldContainerSx} spacing={2}>
          <Stack direction="row" spacing={3}>
            <AccountFormField
              control={control}
              actions={actions}
              onSelect={handleAccountChange}
              name={accountFieldName}
              error={hasAccountError}
              helperText={accountError ?? ''}
              label={translations.common.accountNumberOrName}
              sx={accountFieldSx}
              rules={{
                disableHouseholds: true,
                custodianIds: selectedStrategy?.custodians.map((custodian) => custodian.id),
              }}
            />
            <CurrencyInput
              {...amountField}
              inputRef={amountField.ref}
              size="large"
              fullWidth
              valueType="string"
              allowNegative={false}
              helperText={amountHelperText}
              sx={amountFieldSx}
              label={translations.sma.allocationAmount}
              error={hasAmountError}
            />
          </Stack>
          {checkbox}
          <LocalizedTextField
            {...reasonableRestrictionsField}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              reasonableRestrictionsField.onChange(event.target.value);
            }}
            placeholder={translations.sma.reasonableRestrictions}
            helperText={translations.sma.investmentRestriction}
            fullWidth
            multiline
            size="large"
          />
        </Stack>
        <Box sx={iconButtonContainerSx}>
          <IconButton sx={iconButtonSx} onClick={onDeleteClick}>
            <Iconography iconName="trash-alt" />
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  );
};

import { ApiUnderlyingModel } from '@halo-data-sources/models';

export const commonAssets: Array<ApiUnderlyingModel> = [
  {
    asset: 'index',
    asset_type: 'index',
    bloomberg: 'SPX',
    cusip: '',
    description: 'S&P 500 Index',
    exchange: null,
    id: 105,
    isin: '',
    name: 'SPX',
    reuters: '.SPX',
    svg: null,
  },
  {
    asset: 'index',
    asset_type: '',
    bloomberg: 'RTY',
    cusip: '',
    description: 'Russell 2000 Index',
    exchange: null,
    id: 108,
    isin: '',
    name: 'RUT',
    reuters: '.RUT',
    svg: '/assets/images/svg/tiles/RUT.svg',
  },
  {
    asset: 'index',
    asset_type: 'index',
    bloomberg: 'NDX',
    cusip: '',
    description: 'NASDAQ-100',
    exchange: null,
    id: 107,
    isin: '',
    name: 'NDX',
    reuters: '.NDX',
    svg: null,
  },
  {
    asset: 'index',
    asset_type: 'index',
    bloomberg: 'SX5E',
    cusip: '',
    description: 'EURO STOXX 50 Index',
    exchange: null,
    id: 106,
    isin: '',
    name: 'SX5E',
    reuters: '.STOXX50E',
    svg: null,
  },
  {
    asset: 'stock',
    asset_type: 'stock',
    bloomberg: 'AAPL UW',
    cusip: '',
    description: 'Apple Inc.',
    exchange: {
      code: 'XNGS',
      description: 'NASDAQ Global Select Market',
      id: 21,
      isocountry: 'US',
    },
    id: 11,
    isin: 'US0378331005',
    name: 'AAPL',
    reuters: 'AAPL.OQ',
    svg: null,
  },
];

import {
  LifecycleAllEventTypeEnum,
  LifecycleConditionalEventTypeEnum,
  LifecycleEventTypeEnum,
  PortfolioCashflowEventEnum,
  PortfolioEventSortDropdownValueEnum,
} from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import type { GetPortfolioLifecycleEventsQueryFilterModel } from '@halo-data-sources/queries';
import { DateTime } from 'luxon';

const namespace = 'wm-dashboard-lifecycle';

const generateDateRange = (
  startOffset: { years?: number; days?: number } = {},
  endOffset: { years?: number; days?: number } = {},
) => ({
  startDate: DateTime.now().plus(startOffset).toFormat('yyyy-LL-dd'),
  endDate: DateTime.now().plus(endOffset).toFormat('yyyy-LL-dd'),
});

export type PortfolioLifecycleWidgetTab = {
  id: string;
  label: string;
  namespace?: string;
  filters: Partial<GetPortfolioLifecycleEventsQueryFilterModel>;
  activeCategory?: string | string[];
};

export const portfolioLifecycleWidgetTabs: PortfolioLifecycleWidgetTab[] = [
  {
    id: 'all',
    label: translations.dashboard.portfolioLifecycle.allEvents,
    filters: generateDateRange({}, { days: 90 }),
    namespace,
  },
  {
    id: 'maturity',
    label: translations.dashboard.portfolioLifecycle.maturity,
    filters: {
      eventTypes: LifecycleEventTypeEnum.EXPIRATION,
      ...generateDateRange({}, { years: 3 }),
    },
    activeCategory: PortfolioCashflowEventEnum.Expiration,
    namespace,
  },
  {
    id: 'coupons',
    label: translations.dashboard.portfolioLifecycle.allCoupons,
    filters: {
      eventTypes: LifecycleAllEventTypeEnum.ALL_COUPONS,
      ...generateDateRange({}, { years: 3 }),
    },
    activeCategory: [
      PortfolioCashflowEventEnum.FixedCoupon,
      PortfolioCashflowEventEnum.ConditionalCoupon,
      PortfolioCashflowEventEnum.MemoryCoupon,
    ],
    namespace,
  },
  {
    id: 'autocall',
    label: translations.dashboard.portfolioLifecycle.autocallAboveCallLevel,
    filters: {
      eventTypes: LifecycleConditionalEventTypeEnum.ABOVE_CALL_LEVEL,
      ...generateDateRange({}, { years: 3 }),
    },
    activeCategory: PortfolioCashflowEventEnum.Autocall,
    namespace,
  },
  {
    id: 'recentEvents',
    label: translations.dashboard.portfolioLifecycle.recentEvents,
    filters: {
      ...generateDateRange({ years: -1 }, { days: -1 }),
      sortField: PortfolioEventSortDropdownValueEnum.DESC_EVENT_DATE,
    },
    namespace,
  },
];

import { NoteTypeEnum } from '@halo-common/enums';
import { useQuoteSelector } from '@halo-common/hooks';
import { NoteModel } from '@halo-common/models';
import { NoteQueryKeyFactory } from '@halo-data-sources/queries';
import { LineChartDataPoint, LineChartLineDefinition, LineChartReferenceLine } from '@halodomination/halo-fe-common';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { payoffSwitchFn } from './payoffSwitchFn';
import { v2PayoffSwitchFn } from './v2PayoffSwitchFn';

export type NotePayoffSwitchResult = {
  lines: Array<LineChartLineDefinition>;
  data: Array<LineChartDataPoint>;
  references?: Array<LineChartReferenceLine>;
};

type GetNotePayoffSwitchFnParams = {
  quoteId?: number;
  protectionPercent?: number;
  noteId?: number;
  productType?: string;
};

const getNotePayoffSwitchFn = async ({
  quoteId,
  protectionPercent,
  noteId,
  productType,
}: GetNotePayoffSwitchFnParams) => {
  const isGrowthNote = productType === NoteTypeEnum.Growth;
  const isAbsoluteNote = productType === NoteTypeEnum.Absolute;
  const isIncomeNote = productType === NoteTypeEnum.Income;

  if (isGrowthNote || isAbsoluteNote) return v2PayoffSwitchFn(quoteId, protectionPercent, noteId);
  if (isIncomeNote) return payoffSwitchFn(quoteId, protectionPercent);
  return null;
};

export const useNotePayoffSwitch = (
  product?: NoteModel | null,
): UseQueryResult<NotePayoffSwitchResult | null, Error> => {
  const noteId = product?.id;
  const quote = useQuoteSelector(noteId);
  const quoteId = quote?.id;

  const productType = product?.type;
  const protectionPercent = product?.protectionPercent;

  return useQuery<NotePayoffSwitchResult | null, Error>({
    queryKey: NoteQueryKeyFactory.payoff(productType, quoteId, noteId),
    queryFn: () => getNotePayoffSwitchFn({ quoteId, protectionPercent, noteId, productType }),
    refetchOnMount: false,
  });
};

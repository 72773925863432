import { ReactElement } from 'react';

import { LexicalTypography, UnderlyingGlyphs } from '@halo-common/components';
import { ProductDetailPanelSection } from '@halo-common/layouts';
import { UnderlyingGlyphWeights, UnderlyingModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { Skeleton } from '@mui/material';

export type ProductDetailPanelUnderlyingSectionProps = {
  underlyings?: Array<UnderlyingModel>;
  weights?: UnderlyingGlyphWeights | null;
  loading: boolean;
};

export const ProductDetailPanelUnderlyingSection = ({
  underlyings = [],
  weights,
  loading,
}: ProductDetailPanelUnderlyingSectionProps): ReactElement | null => {
  const hideSection = !loading && !underlyings.length;

  if (hideSection) return null;

  let basketType = translations.underlying.basketTypeSingle;
  if (weights) basketType = translations.underlying.basketTypeWeighted;
  else if (underlyings.length > 1) basketType = translations.underlying.basketTypeWorst;

  const basketTypeContent = loading ? (
    <Skeleton width={50} height={20} />
  ) : (
    <LexicalTypography variant="body2" color="textSecondary" translationKey={basketType}>
      {basketType}
    </LexicalTypography>
  );

  return (
    <ProductDetailPanelSection title={translations.pdm.common.underlyingSection} loading={loading}>
      {basketTypeContent}
      <UnderlyingGlyphs
        underlyings={underlyings}
        weights={weights}
        centered
        scrollable={false}
        StackProps={{ justifyContent: 'flex-start' }}
        loading={loading}
      />
    </ProductDetailPanelSection>
  );
};

import { ReactElement, useState, ChangeEvent } from 'react';

import { useDebounced } from '@halo-common/hooks';
import { LocalizedTextField, LocalizedTextFieldProps } from '@halodomination/halo-fe-common';

export interface DebouncedTextFieldProps extends LocalizedTextFieldProps {
  wait?: number;
}

export const DebouncedTextField = ({ wait = 300, onChange, ...props }: DebouncedTextFieldProps): ReactElement => {
  const [value, setValue] = useState(props.value);

  const debouncedOnChange = useDebounced(onChange, wait);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    debouncedOnChange(event);
  };

  return <LocalizedTextField {...props} value={value} onChange={handleChange} />;
};

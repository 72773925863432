import { ReactElement } from 'react';

import { DEFAULT_LOCALE } from '@halo-common/constants';
import { useLegacyRouter } from '@halo-common/providers';
import { translations } from '@halo-common/translations';
import { formatLocale } from '@halo-common/utils';
import { USER_LOCAL_KEY, useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedMenuItem, useCombinedStyling } from '@halodomination/halo-fe-common';
import { FormControl, InputLabel, Select, SelectChangeEvent, Skeleton, SxProps } from '@mui/material';
import { useT, useTX } from '@transifex/react';

const formControlSx = {
  height: 40,
  width: 220,
};

export type LocaleDropdownPropTypes = {
  selectSx?: SxProps;
  containerSx?: SxProps;
};

export const LocaleDropdown = ({ containerSx, selectSx }: LocaleDropdownPropTypes): ReactElement | null => {
  const t = useT();
  const tx = useTX();

  const legacyRouter = useLegacyRouter();

  const { data: user, isPending } = useUserInfoQuery();

  const combinedContainerSx = useCombinedStyling(formControlSx, containerSx);

  if (isPending) return <Skeleton sx={containerSx} height={40} width={220} />;

  const urlParams = new URLSearchParams(window.location.search);
  const urlLocale = urlParams.get('locale') ?? window.localStorage.getItem(USER_LOCAL_KEY) ?? DEFAULT_LOCALE;

  const availableLocales = user?.whiteLabel.availableLocales ?? [];
  const hasLocales = availableLocales?.length > 1;

  const handleRenderValue = (value: string) => {
    const displayedValue = formatLocale(value, urlLocale);
    return t(displayedValue);
  };

  const handleLocaleChange = (ev: SelectChangeEvent<string>) => {
    const locale = ev.target.value;
    const query = new URLSearchParams({ locale });
    legacyRouter.combinedQueryParams(query);
    window.localStorage.setItem(USER_LOCAL_KEY, locale);
    void tx.setCurrentLocale(locale);
  };

  const menuItems = availableLocales?.map((locales) => {
    const formattedLocale = formatLocale(locales, urlLocale);

    return (
      <LocalizedMenuItem key={locales} value={locales}>
        {formattedLocale}
      </LocalizedMenuItem>
    );
  });

  return hasLocales ? (
    <FormControl variant="standard" sx={combinedContainerSx}>
      <InputLabel id="locale-dropdown-label-id" htmlFor="locale-dropdown">
        {translations.common.language}
      </InputLabel>
      <Select
        fullWidth
        labelId="locale-dropdown-label-id"
        id="locale-dropdown"
        value={urlLocale}
        onChange={handleLocaleChange}
        renderValue={handleRenderValue}
        label={translations.common.language}
        sx={selectSx}
      >
        {menuItems}
      </Select>
    </FormControl>
  ) : null;
};

import { ReactElement } from 'react';

import { ModuleTopicContentType, ModuleTopicModel } from '@halo-common/models';
import {
  EducationDefinition,
  EducationDivider,
  EducationHeadline,
  EducationImage,
  EducationList,
  EducationParagraph,
  EducationVideo,
} from '@halo-modules/app';
import { Box, Paper, Stack } from '@mui/material';

const contentPaperSx = { boxShadow: 4, px: 9, py: 5 };
const containerSx = { width: '100%' };

export type EducationTopicProps = {
  rows?: ModuleTopicModel['content'];
};

export const EducationTopic = ({ rows = [] }: EducationTopicProps): ReactElement => {
  const getEducationComponent = (topic?: ModuleTopicContentType) => {
    if (!topic) return null;

    switch (topic.type) {
      case 'divider':
        return <EducationDivider />;
      case 'definition':
        return (
          <EducationDefinition iconName={topic.icon} title={topic.termName} videoUrl={topic.includeVideo}>
            {topic.definitionContent}
          </EducationDefinition>
        );
      case 'header':
        return <EducationHeadline>{topic.header}</EducationHeadline>;
      case 'image':
        return (
          <EducationImage
            title={topic.title}
            imageUrl={topic.imageUpload}
            maxWidth={topic.imageMaxWidth}
            maxHeight={topic.imageMaxHeight}
          />
        );
      case 'list':
        return <EducationList title={topic.title} items={topic.list} bullet={topic.listStyle} />;
      case 'paragraph':
        return (
          <EducationParagraph title={topic.title} bold={topic.bold} italics={topic.italicized}>
            {topic.paragraphContent}
          </EducationParagraph>
        );
      case 'video':
        return <EducationVideo title={topic.title} videoUrl={topic.videoUpload} caption={topic.captionContent} />;
    }
  };

  const contentRows = rows.map((columns = [], rowIndex) => {
    const key = `education-topic-row-${rowIndex}`;

    const hasMultipleColumns = columns.length > 1;

    const topics = columns.map((topic, index) => (
      <Box key={`education-top-column-${index}`} sx={{ flex: 1 }}>
        {getEducationComponent(topic)}
      </Box>
    ));

    const content = hasMultipleColumns ? topics : topics[0];
    const spacing = hasMultipleColumns ? 3 : 0;

    return (
      <Stack key={key} justifyContent="space-evenly" direction="row" spacing={spacing}>
        {content}
      </Stack>
    );
  });

  return (
    <Paper sx={contentPaperSx}>
      <Stack direction="column" spacing={3} sx={containerSx}>
        {contentRows}
      </Stack>
    </Paper>
  );
};

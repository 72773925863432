import { ReactElement } from 'react';

import { useCommonUserSetting } from '@halo-common/hooks';
import { useRecentQuotesQuery } from '@halo-data-sources/queries';
import { AuraNote, AuraNotesListEmptyMessage } from '@halo-modules/app';
import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';

const listContainerSx = {
  background: 'linear-gradient(270deg, #0C1851 0%, #050F20 100%)',
};

const listSx = {
  maxWidth: 1250,
  m: 'auto',
  p: 4,
};

export const AuraNotesList = (): ReactElement => {
  const data = useCommonUserSetting('aura_note_ids', 'Aura');

  const noteIds = Array.isArray(data) ? data : [];

  useRecentQuotesQuery(noteIds);

  if (isEmpty(noteIds)) return <AuraNotesListEmptyMessage />;

  return (
    <Stack sx={listContainerSx}>
      <Stack direction="row" justifyContent="center" gap={4} flexWrap="wrap" sx={listSx}>
        {noteIds.map((id) => (
          <AuraNote key={id} id={id} />
        ))}
      </Stack>
    </Stack>
  );
};

import { getUnderlyingTradableSearch } from '@halo-data-sources/clients';
import { ApiUnderlyingSearchResponseModel, UnderlyingTradableRequestModel } from '@halo-data-sources/models';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { UnderlyingQueryKeyFactory } from '../keys';

const getUnderlyingTradableFn = async (request: UnderlyingTradableRequestModel) => {
  const response = await getUnderlyingTradableSearch(request);

  return response;
};

export const useUnderlyingTradableQuery = (
  request: UnderlyingTradableRequestModel,
  enabled = true,
): UseQueryResult<ApiUnderlyingSearchResponseModel, Error> =>
  useQuery<ApiUnderlyingSearchResponseModel, Error>({
    queryKey: UnderlyingQueryKeyFactory.search(),
    queryFn: () => getUnderlyingTradableFn(request),
    enabled: enabled,
  });

import { ReactElement } from 'react';

import { userCreationStepAtom } from '@halo-atoms/userCreation';
import { ActionButton } from '@halo-common/components';
import { CreateOrgFormFields, CreateUsersFormFields, SelectOrgFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { useCreateOrgMutation, useCreateUsersMutation } from '@halo-data-sources/mutations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { UseFormHandleSubmit } from 'react-hook-form';

export type CreateUserModalFooterContinueButtonProps = {
  onReset: () => void;
  onClose: () => void;
  onSubmitUser: UseFormHandleSubmit<CreateUsersFormFields, undefined>;
  onSubmitOrg: UseFormHandleSubmit<CreateOrgFormFields, undefined>;
  onSelectOrg: UseFormHandleSubmit<SelectOrgFormFields, undefined>;
};

export const CreateUserModalFooterContinueButton = ({
  onReset,
  onClose,
  onSubmitUser,
  onSubmitOrg,
  onSelectOrg,
}: CreateUserModalFooterContinueButtonProps): ReactElement => {
  const step = useAtomValue(userCreationStepAtom.currentPageAtom);
  const handleNext = useSetAtom(userCreationStepAtom.handleNextAtom);
  const setStep = useSetAtom(userCreationStepAtom.setCurrentPageAtom);

  const { isPending: isUserCreateLoading, mutate: mutateUserCreation } = useCreateUsersMutation(handleNext);
  const { isPending: isOrgCreateLoading, mutate: mutateOrgCreation } = useCreateOrgMutation(handleNext);

  const handleUserSubmit = onSubmitUser((data: CreateUsersFormFields) => {
    mutateUserCreation(data);
  });

  const handleOrgSubmit = onSubmitOrg((data: CreateOrgFormFields) => {
    mutateOrgCreation(data);
  });

  const handleOrgSelectNext = onSelectOrg(() => {
    setStep(2);
  });

  const createAnotherIcon = <Iconography iconName="user-plus" color="primary.main" />;

  const orgCreateOrSelectContinueButton = (
    <LocalizedButton variant="contained" color="primary" onClick={handleOrgSelectNext} size="large">
      {translations.userAndOrgCreation.nextCreateUser}
    </LocalizedButton>
  );

  const orgCreateButton = (
    <ActionButton
      variant="contained"
      color="primary"
      onClick={handleOrgSubmit}
      size="large"
      loading={isOrgCreateLoading}
    >
      {translations.userAndOrgCreation.nextCreateUser}
    </ActionButton>
  );

  const createUserFormContinueButton = (
    <ActionButton
      id="user-create-button"
      variant="contained"
      color="primary"
      loading={isUserCreateLoading}
      onClick={handleUserSubmit}
      size="large"
    >
      {translations.userAndOrgCreation.createUser}
    </ActionButton>
  );

  const createUserSuccessContinueButton = (
    <Stack direction="row" spacing={2}>
      <LocalizedButton variant="outlined" color="primary" onClick={onReset} startIcon={createAnotherIcon} size="large">
        {translations.common.createAnother}
      </LocalizedButton>
      <LocalizedButton variant="contained" color="primary" onClick={onClose} size="large">
        {translations.common.done}
      </LocalizedButton>
    </Stack>
  );

  const continueButtons = [
    orgCreateOrSelectContinueButton,
    orgCreateButton,
    createUserFormContinueButton,
    createUserSuccessContinueButton,
  ];

  return continueButtons[step];
};

import { ReactElement, useMemo } from 'react';

import { addPositionsToAccountModalAtom } from '@halo-atoms/portfolio';
import { AssetIdLocationEnum } from '@halo-common/enums';
import { AddPositionsToAccountFormFields, addPositionsToAccountFormSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { useCreatePositionsMutation } from '@halo-data-sources/mutations';
import { usePortfolioAssetExistenceQuery, useUserInfoQuery } from '@halo-data-sources/queries';
import {
  AddPositionsToAccountChipField,
  AddPositionsToAccountFooterBackButton,
  AddPositionsToAccountFooterContinueButton,
  AllocateNotionalModalLayout,
  SuccessAddPositionsToAccountsModalLayout,
  useAssetIdListText,
} from '@halo-modules/app';
import { ChipModel, ElementSelector, Modal } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

const footerSx = {
  width: '100%',
};

export const AddPositionsToAccountModal = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();

  const [addPositionsToAccountModal, setAddPositionsToAccountModal] = useAtom(addPositionsToAccountModalAtom);

  const { selectedAccountAtom, modalStepMap, open, selectedAssetsAtom } = addPositionsToAccountModal;
  const { handleResetAtom, currentPageAtom, handleNextAtom } = modalStepMap;

  const handleReset = useSetAtom(handleResetAtom);
  const handleNext = useSetAtom(handleNextAtom);
  const step = useAtomValue(currentPageAtom);

  const assetIds = selectedAssetsAtom.map((asset) => asset.label);
  const { data: assets } = usePortfolioAssetExistenceQuery(assetIds);

  const assetList = assets ?? [];

  const {
    data: transactions,
    mutate: createPositions,
    isPending: isCreatePositionLoading,
  } = useCreatePositionsMutation(handleNext);

  const sortedAssetList = useMemo(() => {
    const queuedPositions = assetList.filter((asset) => asset?.location === AssetIdLocationEnum.edgar);
    const positions = assetList.filter((asset) => asset?.location !== AssetIdLocationEnum.edgar);

    return [...positions, ...queuedPositions];
  }, [assetList]);

  const enhancedTransactions = transactions?.map((transaction, index) => {
    return {
      ...transaction,
      assetId: sortedAssetList[index]?.assetId,
      assetIdType: sortedAssetList[index]?.assetIdType,
    };
  });

  const defaultPositionFields = assetList.map((asset) => ({
    termsheetId: asset.termsheetId ?? -1,
    accountId: selectedAccountAtom?.accountId ?? '',
    notional: undefined,
  }));

  const methods = useForm<AddPositionsToAccountFormFields>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    resolver: yupResolver<AddPositionsToAccountFormFields>(addPositionsToAccountFormSchema),
    defaultValues: {
      positions: defaultPositionFields,
    },
  });

  const name = selectedAccountAtom?.name;
  const accountId = selectedAccountAtom?.accountId;
  const custodianName = selectedAccountAtom?.custodian?.name;
  const accounts = selectedAccountAtom ? [selectedAccountAtom] : undefined;

  const hasAccountInfo = name && accountId && custodianName;
  const fullAccountName = hasAccountInfo ? `${name} - ${custodianName} #${accountId}` : name;
  const accountText = fullAccountName ?? translations.portfolio.common.yourAccountTitle;

  const whiteLabel = userInfo?.whiteLabel;
  const assetIdentifiers = whiteLabel?.assetIdentifiers;
  const assetIdTextList = useAssetIdListText(assetIdentifiers, true);

  const hasQueuedTransactions = enhancedTransactions?.some((transaction) => transaction.isQueued);

  const stepOneTitle = translations.portfolio.addPositionsToAccountModal.stepOneTitle;
  const stepThreeTitle = hasQueuedTransactions
    ? translations.portfolio.addPositionsToAccountModal.stepThreeSubtitle
    : undefined;

  const localization = { title: { dynamicContent: { assetIds: assetIdTextList, account: accountText } } };

  const modalPropList = [
    {
      icon: 'search',
      title: stepOneTitle,
      subtitle: translations.portfolio.addPositionsToAccountModal.stepOneSubtitle,
      Localization: localization,
    },
    {
      icon: 'exchange',
      title: translations.portfolio.addPositionsToAccountModal.stepTwoTitle,
    },
    {
      icon: 'check',
      title: translations.portfolio.addPositionsToAccountModal.stepThreeTitle,
      subtitle: stepThreeTitle,
    },
  ];

  const { reset } = methods;

  const handleModalClose = () => {
    reset();
    handleReset();
    setAddPositionsToAccountModal();
  };

  const handleAddAssets = (assetIds: Array<ChipModel>) => {
    setAddPositionsToAccountModal({ addedAssets: assetIds });
  };

  const handleRemoveAssets = (assetIds: Array<ChipModel>) => {
    setAddPositionsToAccountModal({ removedAssets: assetIds });
  };

  const modalFooter = (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={footerSx}>
      <AddPositionsToAccountFooterBackButton />
      <AddPositionsToAccountFooterContinueButton
        handleCreatePositions={createPositions}
        isLoading={isCreatePositionLoading}
      />
    </Stack>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          {...modalPropList[step]}
          overline={translations.portfolio.addPositionsToAccountModal.overline}
          open={open}
          onClose={handleModalClose}
          footer={modalFooter}
        >
          <ElementSelector selected={step}>
            <AddPositionsToAccountChipField
              assets={selectedAssetsAtom}
              onAdd={handleAddAssets}
              onRemove={handleRemoveAssets}
            />
            <AllocateNotionalModalLayout />
            <SuccessAddPositionsToAccountsModalLayout transactions={enhancedTransactions} accounts={accounts} />
          </ElementSelector>
        </Modal>
      </form>
    </FormProvider>
  );
};

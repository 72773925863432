import { ReactElement } from 'react';

import { userCreationStepAtom } from '@halo-atoms/userCreation';
import { CreateOrgFormFields, SelectOrgFormFields } from '@halo-common/schemas';
import { CreateBulkUsersModalFooterContinueButton, CreateUserModalFooterBackButton } from '@halo-modules/admin';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { UseFormHandleSubmit } from 'react-hook-form';

export type CreateBulkUsersModalFooterProps = {
  onReset: () => void;
  onClose: () => void;
  onSubmitOrg: UseFormHandleSubmit<CreateOrgFormFields, undefined>;
  onSelectOrg: UseFormHandleSubmit<SelectOrgFormFields, undefined>;
};

export const CreateBulkUsersModalFooter = ({
  onReset,
  onClose,
  onSubmitOrg,
  onSelectOrg,
}: CreateBulkUsersModalFooterProps): ReactElement => {
  const step = useAtomValue(userCreationStepAtom.currentPageAtom);
  const justifyContent = step === 3 ? 'flex-end' : 'space-between';

  return (
    <Stack direction="row" justifyContent={justifyContent}>
      <CreateUserModalFooterBackButton onClose={onClose} />
      <CreateBulkUsersModalFooterContinueButton
        onReset={onReset}
        onClose={onClose}
        onSubmitOrg={onSubmitOrg}
        onSelectOrg={onSelectOrg}
      />
    </Stack>
  );
};

import { ReactElement, useEffect, useMemo, useState } from 'react';

import { RepSettingArrayField, RepSettingInputFieldSchema } from '@halo-common/formComponents';
import { updateUserInfoSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { useUpdateUserInfoMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { AccountCard, PasswordChangeFormModal, UserLocaleDropdown } from '@halo-modules/app';
import { LocalizedButton, LocalizedTextField, LocalizedTypography } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

const fieldContainerSx = {
  px: 3,
  pt: 2,
  pb: 4,
  width: '100%',
};

const textFieldSx = {
  maxWidth: 338,
  '& input[disabled]': {
    background: 'background.default',
  },
};

const changePasswordSx = {
  ml: 1,
};

const inputProps = {
  maxLength: 64,
};

const repSettingContainerSx = {
  maxWidth: 338,
};

export type ProfileFormFields = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  repSettingArrayField: RepSettingInputFieldSchema['repSettingArrayField'];
};

export const UserProfileDetailsSection = (): ReactElement => {
  const { data: user } = useUserInfoQuery();

  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const userSettingSets = user?.details.repIds ?? [];
  const whiteLabelSettingSets = user?.whiteLabel?.repIdSettingSet?.repIdSettingSet ?? [];

  const settingSets = useMemo(
    () =>
      whiteLabelSettingSets
        .filter(({ rules }) => rules.profile.display)
        .map((setting) => {
          const userSetting = userSettingSets?.filter(
            (uss) => uss.type === setting.type && uss.level === setting.level,
          );
          const values = userSetting?.map((uss) => uss.value) ?? [];
          return { ...setting, values };
        }),
    [userSettingSets, whiteLabelSettingSets],
  );

  const firstName = user?.details.firstName ?? '';
  const lastName = user?.details.lastName ?? '';
  const phone = user?.details.phone ?? '';
  const email = user?.details?.email ?? '';

  const defaultFormState = { firstName, lastName, phone };

  const subtitle = translations.profile.details.detailsSubtitle;

  const formMethods = useForm<ProfileFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    defaultValues: defaultFormState,
    resolver: yupResolver(updateUserInfoSchema(settingSets)),
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    getValues,
    formState: { isDirty, errors },
  } = formMethods;

  const formValues = getValues();

  const { mutate, isPending: updatingUserInfo } = useUpdateUserInfoMutation({
    onSuccess: () => {
      reset(formValues);
    },
  });

  const { ref: firstNameRef, ...firstNameProps } = register('firstName', { required: true });
  const { ref: lastNameRef, ...lastNameProps } = register('lastName', { required: true });
  const { ref: phoneRef, ...phoneProps } = register('phone');

  const firstNameLabelProps = { shrink: !!watch(firstNameProps.name) };
  const lastNameLabelProps = { shrink: !!watch(lastNameProps.name) };
  const phoneLabelProps = { shrink: !!watch(phoneProps.name) };

  const disabled = !isDirty || updatingUserInfo;

  const firstNameError = errors?.firstName;
  const lastNameError = errors?.lastName;
  const phoneError = errors?.phone;

  const handleOpenModal = () => setOpenPasswordModal(true);
  const handleCloseModal = () => setOpenPasswordModal(false);
  const handleSubmitForm = (data: ProfileFormFields) => mutate(data);

  const repSettingsDivider = settingSets?.length ? <Divider /> : null;
  const repSettings = settingSets?.length ? (
    <Stack sx={repSettingContainerSx} spacing={2} direction="column">
      {settingSets.map((setting, index) => (
        <RepSettingArrayField
          key={setting.id}
          fieldIndex={index}
          allowMultiple={setting.allowMultiple}
          id={setting.id}
          required={setting.required}
          inputLabel={setting.displayName}
          minLength={setting.minCharacters}
          maxLength={setting.maxCharacters}
          disabled={!setting.rules.profile.edit}
        />
      ))}
    </Stack>
  ) : null;

  useEffect(() => {
    setValue('firstName', firstName ?? '');
    setValue('lastName', lastName ?? '');
    setValue('phone', phone ?? '');

    if (settingSets?.length) {
      settingSets.forEach((settingSet, fieldIndex) => {
        const fieldId: `repSettingArrayField.${number}` = `repSettingArrayField.${fieldIndex}`;
        const currentValue = getValues(fieldId);
        const fieldValues = settingSet.values.length
          ? settingSet.values.map((value) => ({ repSettingId: settingSet.id, value }))
          : [{ repSettingId: settingSet.id, value: '' }];
        if (!currentValue?.length) {
          setValue(fieldId, fieldValues);
        }
      });
    }
  }, [firstName, lastName, phone, settingSets]);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <AccountCard title={translations.common.account} subtitle={subtitle}>
            <Stack sx={fieldContainerSx} direction="column" spacing={2}>
              <Stack direction="column">
                <LocalizedTypography variant="caption">{translations.common.email}</LocalizedTypography>
                <Typography>{email}</Typography>
              </Stack>
              <LocalizedTextField
                {...firstNameProps}
                inputRef={firstNameRef}
                fullWidth
                label={translations.profile.details.firstName}
                sx={textFieldSx}
                error={Boolean(firstNameError)}
                disabled={updatingUserInfo}
                helperText={firstNameError?.message}
                slotProps={{ htmlInput: inputProps, inputLabel: firstNameLabelProps }}
              />
              <LocalizedTextField
                {...lastNameProps}
                fullWidth
                inputRef={lastNameRef}
                label={translations.profile.details.lastName}
                sx={textFieldSx}
                error={Boolean(lastNameError)}
                disabled={updatingUserInfo}
                helperText={lastNameError?.message}
                slotProps={{ htmlInput: inputProps, inputLabel: lastNameLabelProps }}
              />
              <LocalizedTextField
                {...phoneProps}
                inputRef={phoneRef}
                label={translations.profile.details.phoneNumber}
                sx={textFieldSx}
                fullWidth
                error={Boolean(phoneError)}
                disabled={updatingUserInfo}
                helperText={phoneError?.message}
                slotProps={{ htmlInput: inputProps, inputLabel: phoneLabelProps }}
              />
              {repSettingsDivider}
              {repSettings}
              <Stack direction="column" spacing={2}>
                <Stack direction="row">
                  <LocalizedButton type="submit" variant="contained" color="primary" disabled={disabled}>
                    {translations.common.save}
                  </LocalizedButton>
                  <LocalizedButton variant="text" color="primary" sx={changePasswordSx} onClick={handleOpenModal}>
                    {translations.profile.details.changePassword}
                  </LocalizedButton>
                </Stack>
                <UserLocaleDropdown />
              </Stack>
            </Stack>
          </AccountCard>
        </form>
      </FormProvider>
      <PasswordChangeFormModal open={openPasswordModal} onClose={handleCloseModal} />
    </>
  );
};

import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiGetHistoricalQuotesResponse,
  ApiGetV2PayoffDataModel,
  ApiGetNoteIncomeOutcomesModel,
  ApiGetNoteIncomeStatsModel,
  ApiGetNoteLookbackDataModel,
  ApiGetNoteModel,
  ApiGetNotePayoffDataModel,
  ApiGetNotePricerFieldsModel,
  ApiGetNoteProbabilityBreachModel,
  ApiGetNoteRollingReturnsModel,
  ApiGetNoteTypesModel,
  ApiGetNoteUnderlyingPerformanceModel,
  ApiGetRecentlyCreatedNotesModel,
  ApiNoteModel,
  ApiRFQModel,
} from '@halo-data-sources/models';

const ISSUER_V1_PATH = '/issuer/v1';
const NOTE_PATH = `${ISSUER_V1_PATH}/note`;

const PRE_TRADE_PATH = '/pretrade';
const PRE_TRADE_NOTES_PATH = `${PRE_TRADE_PATH}/notes`;
const PRE_TRADE_PAYOFF_PATH = `${PRE_TRADE_PATH}/nme/payoff`;
const FINGERPRINT_PATH = '/fingerprint';

export const getRecentlyCreateNotes = async (): Promise<ApiGetRecentlyCreatedNotesModel> => {
  const clientConfiguration = await getClientConfiguration(PRE_TRADE_NOTES_PATH);

  const response = await request<ApiGetRecentlyCreatedNotesModel>(`${clientConfiguration.basePath}/recently-created`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getNote = async (id: string | number): Promise<ApiGetNoteModel> => {
  const clientConfiguration = await getClientConfiguration(NOTE_PATH);

  const response = await request<ApiGetNoteModel>(`${clientConfiguration.basePath}/${id}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getQuotesNoteHistory = async (rfqId: number): Promise<{ rfqs: ApiRFQModel[] }> => {
  const clientConfiguration = await getClientConfiguration(NOTE_PATH);

  const response = await request<{ rfqs: ApiRFQModel[] }>(
    `${clientConfiguration.basePath}/rfq/history?rfq_id=${rfqId}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const postNoteRFQ = async (noteId: number): Promise<ApiNoteModel> => {
  const clientConfiguration = await getClientConfiguration(NOTE_PATH);

  const response = await request<ApiNoteModel>(`${clientConfiguration.basePath}/rfq/${noteId}`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  return response;
};

export const getNoteRollingReturns = async (query?: string): Promise<ApiGetNoteRollingReturnsModel> => {
  const clientConfiguration = await getClientConfiguration(NOTE_PATH);

  const response = await request<ApiGetNoteRollingReturnsModel>(`${clientConfiguration.basePath}/rolling${query}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getNoteUnderlyingPerformance = async (
  underlyings: string,
): Promise<ApiGetNoteUnderlyingPerformanceModel> => {
  const clientConfiguration = await getClientConfiguration(ISSUER_V1_PATH);

  const response = await request<ApiGetNoteUnderlyingPerformanceModel>(
    `${clientConfiguration.basePath}/quotesret/${underlyings}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const getNoteIncomeOutcomes = async (noteId: number): Promise<ApiGetNoteIncomeOutcomesModel> => {
  const clientConfiguration = await getClientConfiguration(NOTE_PATH);

  const response = await request<ApiGetNoteIncomeOutcomesModel>(
    `${clientConfiguration.basePath}/income/outcomes?id=${noteId}&alldates=True`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const getNoteLookbackData = async (quoteId: number): Promise<ApiGetNoteLookbackDataModel> => {
  const clientConfiguration = await getClientConfiguration(ISSUER_V1_PATH);

  const response = await request<ApiGetNoteLookbackDataModel>(
    `${clientConfiguration.basePath}/quote/backtest?note_quote=${quoteId}&autoroll=true`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const getNoteProbabilityBreach = async (noteId: number): Promise<ApiGetNoteProbabilityBreachModel> => {
  const clientConfiguration = await getClientConfiguration(NOTE_PATH);

  const response = await request<ApiGetNoteProbabilityBreachModel>(
    `${clientConfiguration.basePath}/pbreach/${noteId}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const getNoteIncomeStats = async (noteId: number): Promise<ApiGetNoteIncomeStatsModel> => {
  const clientConfiguration = await getClientConfiguration(NOTE_PATH);

  const response = await request<ApiGetNoteIncomeStatsModel>(`${clientConfiguration.basePath}/income-stats/${noteId}`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

export const getNotePayoffDataModel = async (noteQuoteId: number): Promise<ApiGetNotePayoffDataModel> => {
  const clientConfiguration = await getClientConfiguration(ISSUER_V1_PATH);

  const response = await request<ApiGetNotePayoffDataModel>(
    `${clientConfiguration.basePath}/quote/payoff/${noteQuoteId}`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const getV2PayoffDataModel = async (noteQuoteId: number, noteId: number): Promise<ApiGetV2PayoffDataModel> => {
  const clientConfiguration = await getClientConfiguration(PRE_TRADE_PAYOFF_PATH);

  const response = await request<ApiGetV2PayoffDataModel>(clientConfiguration.basePath, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      quote_id: noteQuoteId,
      note_id: noteId,
    }),
  });

  return response;
};

export const getWatchlistHistoricalQuotes = async (noteId: number): Promise<ApiGetHistoricalQuotesResponse> => {
  const clientConfiguration = await getClientConfiguration(PRE_TRADE_NOTES_PATH);

  const response = await request<ApiGetHistoricalQuotesResponse>(
    `${clientConfiguration.basePath}/${noteId}/historical-quotes`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const getNotePricerFields = async (): Promise<ApiGetNotePricerFieldsModel> => {
  const clientConfiguration = await getClientConfiguration(ISSUER_V1_PATH);

  const response = await request<ApiGetNotePricerFieldsModel>(
    `${clientConfiguration.basePath}/dealable/pricers/fields`,
    {
      ...clientConfiguration.requestInit,
      method: 'GET',
    },
  );

  return response;
};

export const getNoteTypes = async (): Promise<Array<ApiGetNoteTypesModel>> => {
  const clientConfiguration = await getClientConfiguration(FINGERPRINT_PATH);

  const response = await request<Array<ApiGetNoteTypesModel>>(`${clientConfiguration.basePath}/notetype`, {
    ...clientConfiguration.requestInit,
    method: 'GET',
  });

  return response;
};

import { ReactElement, ReactNode, useState } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { Iconography } from '@halodomination/halo-fe-common';
import type { HaloTheme } from '@halodomination/halo-fe-theme';
import { Collapse, IconButton, Stack, SxProps } from '@mui/material';

const containerSx = {
  marginBottom: 2,
};

const labelSx = {
  cursor: 'pointer',
};

export type CollapsibleFiltersProps = {
  children: ReactNode;
  title: string;
  walkMeClassName?: string;
};

export const CollapsibleFilters = ({ children, title, walkMeClassName }: CollapsibleFiltersProps): ReactElement => {
  const [isCollapsed, setCollapsed] = useState(true);

  const handleCollapseClick = () => {
    setCollapsed(!isCollapsed);
  };

  const iconSx: SxProps<HaloTheme> = {
    transform: `rotateZ(${isCollapsed ? 180 : 0}deg)`,
    transition: (theme) =>
      theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
  };

  return (
    <Stack direction="column" sx={containerSx} className={walkMeClassName}>
      <Stack direction="row" alignItems="center" component="label" sx={labelSx}>
        <IconButton onClick={handleCollapseClick} aria-label="expand" size="medium">
          <Iconography iconName="chevron-down" sx={iconSx} />
        </IconButton>
        <LexicalTypography variant="subtitle2">{title}</LexicalTypography>
      </Stack>
      <Collapse in={isCollapsed}>{children}</Collapse>
    </Stack>
  );
};

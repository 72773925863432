import { ReactElement } from 'react';

import { UnderlyingGlyph } from '@halo-common/components';
import { UnderlyingGlyphWeights, UnderlyingModel } from '@halo-common/models';
import { useCombinedStyling } from '@halodomination/halo-fe-common';
import { Skeleton, Stack, StackProps, SxProps } from '@mui/material';

export type UnderlyingGlyphsProps = {
  size?: 'small' | 'large';
  scrollable?: boolean;
  sx?: SxProps;
  tooltip?: boolean;
  underlyings?: Array<UnderlyingModel>;
  weights?: UnderlyingGlyphWeights | null;
  StackProps?: Partial<StackProps>;
  loading?: boolean;
  centered?: boolean;
};

export const UnderlyingGlyphs = ({
  loading = false,
  scrollable = false,
  size = 'small',
  StackProps,
  sx,
  tooltip = false,
  underlyings = [],
  weights,
  centered = false,
}: UnderlyingGlyphsProps): ReactElement => {
  const containerSx = {
    overflowX: scrollable ? 'scroll' : 'unset',
    justifyContent: centered ? 'center' : 'flex-start',
    py: 1,
    width: '100%',
  };

  const underlyingRowSx = {
    alignItems: 'center',
    flexWrap: !scrollable ? 'wrap' : 'unset',
    width: 'fit-content',
  };

  const underlyingStackSx = useCombinedStyling(underlyingRowSx, sx);

  if (loading) {
    const loadingGlyphs = Array.from(Array(3), (_, id) => ({ ticker: id.toString() })).map(({ ticker }) => (
      <Skeleton key={ticker} width={50} height={50} variant="rounded" />
    ));

    return (
      <Stack direction="row" sx={underlyingStackSx} spacing={1} {...StackProps}>
        {loadingGlyphs}
      </Stack>
    );
  }

  const glyphs = [...underlyings]
    .sort((a: UnderlyingModel, b: UnderlyingModel) => {
      const isWorstOf = !weights;
      const alphaSort = a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      if (isWorstOf) return alphaSort;
      else return weights[a.name] < weights[b.name] ? 1 : weights[a.name] > weights[b.name] ? -1 : alphaSort;
    })
    .map(({ name, ...underlying }: UnderlyingModel) => {
      const weight = weights?.[name];
      return <UnderlyingGlyph {...underlying} key={name} ticker={name} tooltip={tooltip} weight={weight} size={size} />;
    });

  return (
    <Stack direction="row" sx={containerSx}>
      <Stack direction="row" sx={underlyingStackSx} spacing={1} useFlexGap {...StackProps}>
        {glyphs}
      </Stack>
    </Stack>
  );
};

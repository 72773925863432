import { ReactElement } from 'react';

import { CalendarProductCardFavoriteBtn, FixedIncomeProductCardFavoriteBtn } from '@halo-common/components';
import {
  useCalendarIndicationButtonText,
  useModalState,
  usePrimaryAssetIdentifier,
  useProductProgress,
} from '@halo-common/hooks';
import { MUST_COMPLETE_ONBOARDING_MODAL } from '@halo-common/modals';
import { CalendarModel } from '@halo-common/models';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedButton, ProgressBar } from '@halodomination/halo-fe-common';
import { Skeleton, Stack } from '@mui/material';
import { DateTime } from 'luxon';

const containerSx = {
  minWidth: 425,
};

const progressBarSx = {
  width: 250,
};

export type ExecutionCalendarDetailModalHeaderActionsProps = {
  product?: CalendarModel | null;
  onSubmitIndication?: (product: CalendarModel) => void;
  loading?: boolean;
};

export const ExecutionCalendarDetailModalHeaderActions = ({
  product,
  onSubmitIndication,
  loading = true,
}: ExecutionCalendarDetailModalHeaderActionsProps): ReactElement | null => {
  const { data: userInfo } = useUserInfoQuery();

  const { setOpen } = useModalState(MUST_COMPLETE_ONBOARDING_MODAL);

  const indicationButtonText = useCalendarIndicationButtonText(product?.activeAllocationCount, product?.expirationDate);

  const { percentFilled, variant, label, dynamicContent } = useProductProgress({ calendar: product });
  const { identifier, identifierType, fallback } = usePrimaryAssetIdentifier(product);

  const parsedIdentifierText = identifier ? identifier : fallback.identifier;
  const parsedIdentifierType = identifier ? identifierType : fallback.identifierType;

  const isOnboardingCompleted = userInfo?.organization?.isOnboardingCompleted;

  if (loading) {
    return (
      <Stack direction="row" spacing={1}>
        <Skeleton width={100} height={42} />
      </Stack>
    );
  }

  if (!product || !onSubmitIndication) return null;

  const allocationsEnabled = userInfo?.settings.enableCalendarAllocations;
  const hasAllocations = product.activeAllocationCount > 0;
  const isExpired = product?.expirationDate < DateTime.utc().toISO();
  const isClosed = !hasAllocations && isExpired;
  const allocationsButtonDisabled = isClosed || !allocationsEnabled;

  const openOnboardingModal = () => setOpen(true);

  const handleSubmitIndication = () => {
    if (!isOnboardingCompleted) openOnboardingModal();
    else onSubmitIndication(product);
  };

  const favoriteButton = product.showAsNme ? (
    <FixedIncomeProductCardFavoriteBtn
      calendarId={product?.id}
      isFavorite={product?.isFavorite}
      assetIdentifier={parsedIdentifierText}
      assetIdentifierType={parsedIdentifierType}
    />
  ) : (
    <CalendarProductCardFavoriteBtn
      calendarId={product?.id}
      isFavorite={product?.isFavorite}
      assetIdentifier={parsedIdentifierText}
      assetIdentifierType={parsedIdentifierType}
    />
  );

  return (
    <Stack direction="row" sx={containerSx} alignItems="center" flexWrap="wrap" spacing={2}>
      <ProgressBar
        variant={variant}
        label={label}
        percentFilled={percentFilled}
        sx={progressBarSx}
        dynamicContent={dynamicContent}
      />
      <LocalizedButton
        type="button"
        variant="contained"
        color="primary"
        onClick={handleSubmitIndication}
        disabled={allocationsButtonDisabled}
      >
        {indicationButtonText}
      </LocalizedButton>
      {favoriteButton}
    </Stack>
  );
};

export const AURA_TRANSLATIONS_COMMON = {
  categoryRequired: 'Category required',
  allocationRequired: 'Allocation required',
  quoteRequired: 'Quote required',
  allocationClass: 'Allocation Class',
  allocationLabel: 'Allocation',
  quoteLabel: 'Quote',
  addLabel: 'Add',
  finishLabel: 'Finish',
  addedLabel: 'Added',
  modalTitle: 'Add Note',
  fixedIncomeLabel: 'Fixed Income',
  equityLabel: 'Equity',
  cashLabel: 'Cash',
  alternativeLabel: 'Alternative',
  categoryWarning: 'Category is required',
  increaseAllocation: 'Increase allocation',
  decreaseAllocation: 'Decrease allocation',
  allocationIsRequired: 'Allocation is required',
};

import { UserCreatedModel, OrganizationModel } from '@halo-common/models';
import { createStepAtoms } from '@halodomination/halo-fe-common';
import { atom } from 'jotai';

export const userCreationStepAtom = createStepAtoms({ max: 4 });

export const userCreationOrganizationAtom = atom<OrganizationModel | undefined | null>(undefined);

export const createdUsersAtom = atom<Array<UserCreatedModel>>([]);

export const isNewOrgAtom = atom<boolean>(false);

import { ChangeEvent, KeyboardEvent, ReactElement } from 'react';

import { useOrderTicketContentPicker } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { Collapsible, LocalizedTextField, Stack } from '@halodomination/halo-fe-common';
import { useFormContext } from 'react-hook-form';

import { AccountTypeDropdown } from './AccountTypeDropdown';
import { CapacityTypeDropdown } from './CapacityTypeDropdown';
import { DiscretionDropdown } from './DiscretionDropdown';
import { SolicitedDropdown } from './SolicitedDropdown';
import { TicketDetails } from './TicketDetails';
import { TTORepCodeTypeAhead } from './TTORepCodeTypeAhead';

export type TicketSectionProps = {
  bulk?: boolean;
};

export const TicketSection = ({ bulk = false }: TicketSectionProps): ReactElement => {
  const orderTicketContent = useOrderTicketContentPicker();
  const { formState, register, setValue } = useFormContext();

  const { errors } = formState;
  const { ref: quantityRef, ...quantityProps } = register('quantity');

  const quantityError = errors?.quantity?.message as string;
  const hasQuantityError = Boolean(quantityError);

  const handleChangeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, '');
    setValue('quantity', Number(sanitizedValue));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = /^[0-9]|Tab|Backspace|Delete|Enter|Left|Right$/;
    if (!allowedKeys.test(event.key)) event.preventDefault();
  };

  const accountTypeDropdown = orderTicketContent.showAccountTypeDropdown ? <AccountTypeDropdown /> : null;
  const solicitedDropdown = orderTicketContent.showSolicitedDropdown ? <SolicitedDropdown bulk={bulk} /> : null;
  const discretionDropdown = orderTicketContent.showDiscretionDropdown ? <DiscretionDropdown bulk={bulk} /> : null;
  const ttoRepCodeTypeAhead = orderTicketContent.showTTORepCodeTypeAhead ? <TTORepCodeTypeAhead /> : null;

  const capacityField = bulk ? <CapacityTypeDropdown /> : null;
  const quantityField = !bulk ? (
    <LocalizedTextField
      {...quantityProps}
      onChange={handleChangeQuantity}
      onKeyDown={handleKeyDown}
      slotProps={{ htmlInput: { min: 0, step: 1 } }}
      inputRef={quantityRef}
      type="text"
      label={translations.allocationsModal.common.quantityInThousands}
      fullWidth
      size="large"
      helperText={quantityError}
      error={hasQuantityError}
    />
  ) : null;

  return (
    <Collapsible title="Ticket" defaultExpanded alwaysExpanded={bulk}>
      <Stack direction="column" spacing={3}>
        <TicketDetails />
        <Stack direction="row" spacing={2} xs={12} sm={4} wrap="wrap">
          {accountTypeDropdown}
          {solicitedDropdown}
          {discretionDropdown}
          {quantityField}
          {ttoRepCodeTypeAhead}
          {capacityField}
        </Stack>
      </Stack>
    </Collapsible>
  );
};

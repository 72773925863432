import { isNewOrgAtom, userCreationOrganizationAtom } from '@halo-atoms/userCreation';
import { OrganizationModel } from '@halo-common/models';
import { CreateOrgFormFields } from '@halo-common/schemas';
import { createOrganization } from '@halo-data-sources/clients';
import { ApiOrganizationMapper, OrganizationMapper } from '@halo-data-sources/mappers';
import { useSnackbar } from '@halodomination/halo-fe-common';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

export type CreateOrganizationPayload = () => void;

const createOrgMutationFn = async (payload?: CreateOrgFormFields) => {
  if (!payload) return null;

  const request = OrganizationMapper.toCreateOrganizationRequest(payload);

  const response = await createOrganization(request);

  const newOrg = ApiOrganizationMapper.toOrganization(response);

  return newOrg;
};

export const useCreateOrgMutation = (
  handleNext: CreateOrganizationPayload,
): UseMutationResult<OrganizationModel | null, Error, CreateOrgFormFields | undefined> => {
  const { enqueueErrorEvent } = useSnackbar();
  const setSelectedOrg = useSetAtom(userCreationOrganizationAtom);
  const setIsNewOrg = useSetAtom(isNewOrgAtom);

  return useMutation({
    mutationFn: (payload) => createOrgMutationFn(payload),
    onSuccess: (data) => {
      setSelectedOrg(data ?? undefined);
      setIsNewOrg(true);
      handleNext();
    },
    onError: (error) => enqueueErrorEvent({ message: error.message }),
  });
};

import { ReactElement, useEffect, useState } from 'react';

import { rolesInputSelectionAtom } from '@halo-atoms/common';
import { isNewOrgAtom, userCreationOrganizationAtom } from '@halo-atoms/userCreation';
import { UserRoleModel } from '@halo-common/models';
import { CreateUsersFormFields } from '@halo-common/schemas';
import { RoleTypeAhead } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { LocalizedAlert, LocalizedTextField, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';

const chipSx = {
  color: 'primary.main',
  backgroundColor: 'primary.background',
  '& .MuiChip-deleteIcon': {
    color: 'primary.main',
  },
};

export const CreateUserForm = (): ReactElement => {
  const { data: userInfo } = useUserInfoQuery();
  const formMethods = useFormContext<CreateUsersFormFields>();
  const selectedOrg = useAtomValue(userCreationOrganizationAtom);
  const isNewOrg = useAtomValue(isNewOrgAtom);
  const setSelectedRoles = useSetAtom(rolesInputSelectionAtom);
  const [rolesChanged, setRolesChanged] = useState<boolean>(false);

  const whiteLabel = userInfo?.whiteLabel;
  const onboardingRoles = whiteLabel?.onboardingRoles ?? [];

  const relationshipManager = selectedOrg?.relationshipManager;
  const relationshipManagerName = relationshipManager?.name;

  const orgName = selectedOrg?.name;

  const { register, formState, setValue } = formMethods;
  const { errors } = formState;

  const firstNameError = errors?.firstName?.message;
  const hasFirstNameError = Boolean(firstNameError);

  const lastNameError = errors?.lastName?.message;
  const hasLastNameError = Boolean(lastNameError);

  const emailError = errors?.email?.message;
  const hasEmailError = Boolean(emailError);

  const hasDefaultRoles = Boolean(onboardingRoles.length);
  const enableRolesPrefilledText = hasDefaultRoles && !rolesChanged;

  const rolesError = errors?.roles?.message;
  const hasRolesError = Boolean(rolesError);
  const rolesHelperText = hasRolesError
    ? rolesError
    : enableRolesPrefilledText
      ? translations.userAndOrgCreation.prefilledFromOrg
      : undefined;

  const { ref: firstNameRef, ...firstNameProps } = register('firstName');
  const { ref: lastNameRef, ...lastNameProps } = register('lastName');
  const { ref: emailRef, ...emailProps } = register('email');

  const dynamicContent = { org: selectedOrg?.name };
  const alert = isNewOrg ? (
    <LocalizedAlert severity="success" variant="filled" dynamicContent={dynamicContent}>
      {translations.userAndOrgCreation.successOrgCreation}
    </LocalizedAlert>
  ) : null;

  const handleRoleChange = (selection: Array<UserRoleModel>) => {
    setRolesChanged(true);
    const roleIds = selection.map((role) => role.id);
    setValue('roles', roleIds, { shouldDirty: true });
  };

  useEffect(() => {
    if (onboardingRoles.length) {
      setSelectedRoles(onboardingRoles);
      const roleIds = onboardingRoles.map((role) => role.id);
      setValue('roles', roleIds, { shouldDirty: true });
    }
  }, [onboardingRoles]);

  return (
    <Stack direction="column" spacing={2}>
      {alert}
      <form>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            <Stack direction="column" spacing={1}>
              <LocalizedTypography variant="caption" color="text.secondary">
                {translations.common.organization}
              </LocalizedTypography>
              <Typography variant="subtitle1" color="text.primary">
                {orgName}
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1}>
              <LocalizedTypography variant="caption" color="text.secondary">
                {translations.common.relationshipManager}
              </LocalizedTypography>
              <Typography variant="subtitle1" color="text.primary">
                {relationshipManagerName}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <LocalizedTextField
              {...firstNameProps}
              ref={firstNameRef}
              label={translations.common.firstName}
              error={hasFirstNameError}
              helperText={firstNameError}
              fullWidth
            />
            <LocalizedTextField
              {...lastNameProps}
              ref={lastNameRef}
              label={translations.common.lastName}
              error={hasLastNameError}
              helperText={lastNameError}
              fullWidth
            />
          </Stack>
          <LocalizedTextField
            {...emailProps}
            ref={emailRef}
            label={translations.common.emailAddress}
            error={hasEmailError}
            helperText={emailError}
            fullWidth
          />
          <RoleTypeAhead
            ChipProps={{ sx: chipSx, size: 'small' }}
            onChange={handleRoleChange}
            error={hasRolesError}
            helperText={rolesHelperText}
          />
        </Stack>
      </form>
    </Stack>
  );
};

import { ReactElement } from 'react';

import { Iconography } from '@halodomination/halo-fe-common';
import { Rating } from '@mui/material';

export type FavoriteButtonProps = {
  onClick: (favorite: boolean) => void;
  checked: boolean;
  className?: string;
};

export const FavoriteButton = ({ onClick, checked, className }: FavoriteButtonProps): ReactElement => {
  const handleRatingChange = () => onClick(!checked);

  const ratingValue = checked ? 1 : 0;

  return (
    <Rating
      icon={<Iconography iconName="star" color="common.favorite" size="1.5x" />}
      emptyIcon={<Iconography prefix="far" iconName="star" color="grey.400" size="1.5x" />}
      onChange={handleRatingChange}
      value={ratingValue}
      max={1}
      size="large"
      className={className}
    />
  );
};

import { ReactElement, useEffect } from 'react';

import { AuraNoteForm } from '@halo-common/forms';
import { useQuoteSelector } from '@halo-common/hooks';
import { AuraNoteModal, NoteModel } from '@halo-common/models';
import { addAuraNoteSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton, Modal } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

const contentStyling = {
  backgroundColor: 'background.paper',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const ellipsisSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '800px',
};

const replaceUnderscoreWithSpace = (inputString?: string): string => {
  return inputString ? inputString.replace(/_/g, ' ') : '';
};

export type AddAuraNoteModalProps = {
  open: boolean;
  onClose: () => void;
  product?: NoteModel | null;
  iframeRef: React.RefObject<HTMLIFrameElement>;
};

export const AddAuraNoteModal = ({ open, product, onClose, iframeRef }: AddAuraNoteModalProps): ReactElement => {
  const selectedQuote = useQuoteSelector(product?.id);

  const formMethods = useForm<AuraNoteModal>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(addAuraNoteSchema),
    defaultValues: {
      category: 'fixed',
      quote: null,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
  } = formMethods;

  const onSubmit = (data: AuraNoteModal) => {
    const updatedNote = {
      note_id: product?.id,
      note_display_name: product?.name,
      quote_value: data?.quote,
      allocation_percent: data?.allocation,
      allocation_class: data?.category,
    };

    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        JSON.stringify({
          add_note: updatedNote,
        }),
        '*',
      );
    }

    handleBack();
  };

  const handleBack = () => {
    reset();
    onClose();
  };

  const isDisabled = !isValid;

  const subTitle = <Typography sx={ellipsisSx}>{replaceUnderscoreWithSpace(product?.name)}</Typography>;

  const footer = (
    <Stack direction="row" justifyContent="space-between">
      <LocalizedButton
        onClick={handleBack}
        variant="text"
        color="primary"
        startIcon={<Iconography iconName="arrow-left" />}
      >
        {translations.common.cancel}
      </LocalizedButton>
      <LocalizedButton
        startIcon={isSubmitting && <CircularProgress size="1rem" />}
        disabled={isDisabled}
        color="primary"
        variant="contained"
        onClick={handleSubmit(onSubmit)}
      >
        {translations.aura.common.finishLabel}
      </LocalizedButton>
    </Stack>
  );

  const modalContent = (
    <FormProvider {...formMethods}>
      <AuraNoteForm />
    </FormProvider>
  );

  useEffect(() => {
    reset({
      category: 'fixed',
      quote: selectedQuote?.value,
    });
  }, [selectedQuote]);

  return (
    <Modal
      onClose={onClose}
      open={open}
      ContentProps={{ sx: contentStyling }}
      title={translations.aura.common.modalTitle}
      subtitle={subTitle}
      footer={footer}
      size="medium"
    >
      {modalContent}
    </Modal>
  );
};

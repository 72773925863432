import { ReactElement, useState } from 'react';

import { selectedStrategyAtom } from '@halo-atoms/sma';
import { SMAStrategyModel } from '@halo-common/models';
import { AccountTypeAheadOption } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { useSMAAllocationsSubmitMutation } from '@halo-data-sources/mutations';
import { useSMAAllocationsQuery } from '@halo-data-sources/queries';
import { SMAAllocationModalContainer, SMAAllocationModalContainerProps } from '@halo-modules/app';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import { array as yupArray, boolean as yupBoolean, object as yupObject, string as yupString } from 'yup';

const SMAAllocationModalFormSchema = () => {
  return yupObject().shape({
    allocations: yupArray()
      .defined(translations.messages.requiredField)
      .of(
        yupObject().shape({
          accountOption: yupObject()
            .required(translations.sma.selectAccount)
            .test('valid-account-option', translations.messages.requiredField, (value?: AccountTypeAheadOption) =>
              Boolean(value?.account),
            ),
          amount: yupString()
            .required(translations.messages.requiredField)
            .max(100000000, 'Allocation must not exceed $100,000,000.')
            .test(
              'multiple-of-thousand',
              'Allocations must be entered in quantity of thousands.',
              (value: string | undefined) => Number(value) % 1000 === 0 && Number(value) !== 0,
            ),
          checkbox: yupBoolean(),
          reasonableRestrictions: yupString(),
        }),
      ),
    advisorName: yupString(),
    checkAdv: yupBoolean()
      .required(translations.messages.requiredField)
      .default(false)
      .test((value) => value),
    checkInvestmentObj: yupBoolean()
      .required(translations.messages.requiredField)
      .default(false)
      .test((value) => value),
  });
};

export type SMAAllocationModalFormModel = {
  allocations: Array<{
    accountOption: AccountTypeAheadOption | null;
    amount?: string | null | undefined;
    checkbox?: boolean;
    reasonableRestrictions?: string;
  }>;
  advisorName?: string;
  checkAdv: boolean;
  checkInvestmentObj: boolean;
};

export type SMAAllocationModalProps = Pick<SMAAllocationModalContainerProps, 'open' | 'onClose'> & {
  strategy: SMAStrategyModel;
};

export const SMAAllocationModal = (props: SMAAllocationModalProps): ReactElement => {
  const [tab, setTab] = useState(0);

  const selectedStrategy = useAtomValue(selectedStrategyAtom);

  const { data: allAllocations, isPending: allocationsLoading } = useSMAAllocationsQuery();
  const { mutate: postSMAAllocations, isPending: submitLoading, isSuccess } = useSMAAllocationsSubmitMutation();

  const validation = selectedStrategy?.minimumInvestmentAmount
    ? yupResolver<SMAAllocationModalFormModel>(SMAAllocationModalFormSchema())
    : undefined;

  const formMethods = useForm<SMAAllocationModalFormModel>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: validation,
    defaultValues: {
      allocations: [{ accountOption: null, amount: null, checkbox: false }],
      checkAdv: false,
      checkInvestmentObj: false,
    },
  });

  let allocationModalStatus: SMAAllocationModalContainerProps['status'] = 'idle';
  if (allocationsLoading) allocationModalStatus = 'loading';
  else if (isSuccess) allocationModalStatus = 'success';

  const strategy = props.strategy;
  const allocations = allAllocations?.filter((allocation) => allocation.strategyId === strategy.id) ?? [];
  const hidePrimaryAction = tab === 1;

  const handleSubmit = (data: SMAAllocationModalFormModel) => {
    const formattedData = data.allocations.map((allocation) => ({
      account_id: Number(allocation?.accountOption?.account?.id),
      amount: Number(allocation.amount),
      sma_strategy_id: strategy.id,
      reasonable_restrictions: allocation.reasonableRestrictions,
      advisor_name: data.advisorName,
    }));

    postSMAAllocations(formattedData);
  };

  return (
    <FormProvider {...formMethods}>
      <SMAAllocationModalContainer
        {...props}
        allocations={allocations}
        title={translations.sma.submitAllocation}
        subtitle={strategy.name}
        onSubmit={handleSubmit}
        status={allocationModalStatus}
        hidePrimaryAction={hidePrimaryAction}
        TableProps={{ hideActions: true }}
        onCancelAllocation={() => {
          // NO_OP
        }}
        TabsProps={{ value: tab, onChange: setTab }}
        FooterProps={{ loading: submitLoading }}
      />
    </FormProvider>
  );
};

import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiUserAuctionDefaults,
  ApiAdminAuctionPutFinalPrelimTermsResponseModel,
  ApiAdminAuctionPutPrelimTermsRequestModel,
  ApiAdminAuctionPutPrelimTermsResponseModel,
  ApiUserAuctionDefaultsRequest,
} from '@halo-data-sources/models';

const EXECUTION_AUCTION_PATH = '/execution/auction';
const EXECUTION_AUCTION_ISSUER_PATH = `${EXECUTION_AUCTION_PATH}/issuer`;

export const putPrelimTerms = async ({
  auctionId,
  termSheet,
  isin,
  cusip,
}: ApiAdminAuctionPutPrelimTermsRequestModel): Promise<ApiAdminAuctionPutPrelimTermsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ISSUER_PATH);

  const formData = new FormData();

  formData.append('term_sheet', termSheet);
  formData.append('_csrf_token', clientConfiguration.csrfToken as string);

  if (isin) formData.append('isin', isin);
  if (cusip) formData.append('cusip', cusip);

  const path = `${clientConfiguration.basePath}/${auctionId}/terms`;

  const response = await request<ApiAdminAuctionPutPrelimTermsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: formData,
  });

  return response;
};

export const putFinalPrelimTerms = async ({
  auctionId,
  termSheet,
  isin,
  cusip,
}: ApiAdminAuctionPutPrelimTermsRequestModel): Promise<ApiAdminAuctionPutFinalPrelimTermsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_ISSUER_PATH);

  const formData = new FormData();

  formData.append('term_sheet', termSheet);
  formData.append('_csrf_token', clientConfiguration.csrfToken as string);

  if (isin) formData.append('isin', isin);
  if (cusip) formData.append('cusip', cusip);

  const path = `${clientConfiguration.basePath}/${auctionId}/terms/final`;

  const response = await request<ApiAdminAuctionPutFinalPrelimTermsResponseModel>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: formData,
  });

  return response;
};

export const getAuctionDefaults = async (payload: ApiUserAuctionDefaultsRequest): Promise<ApiUserAuctionDefaults> => {
  const clientConfiguration = await getClientConfiguration(EXECUTION_AUCTION_PATH);

  const path = `${clientConfiguration.basePath}/date`;

  const response = await request<ApiUserAuctionDefaults>(path, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

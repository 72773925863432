export const REGISTER = {
  createYourAccount: 'Create Your Account',
  resetPassword: 'Reset Password',
  companyName: 'Company Name',
  role: 'Your Role (Optional)',
  phoneNumber: 'Phone Number (Optional)',
  alreadyHaveAccount: `Already have an account?`,
  login: 'Login',
  emailAddressError: 'Please enter a valid email address.',
  firstNameError: 'Please enter a first name.',
  lastNameError: 'Please enter a last name.',
  phoneNumberError: 'Phone number is not valid.',
  countryError: 'Please enter your country.',
  regionError: 'Please enter your {regionLabel}',
  repSettingArrayError: 'This field is required.',
  emailExists: 'Email Already Registered.',
  companyNameError: 'Please enter your company name.',
  surveyQuestion: 'Where did you hear about us?',
  surveyGoogle: 'Google/Search Engine',
  surveyWebinar: 'Webinar',
  surveyInPerson: 'In-Person Conference',
  surveyOnline: 'Online Advertisement',
  surveyDemo: 'Product Demo',
  surveyReferral: 'Referral from a friend/colleague',
  surveyOther: 'Other',
  surveySocialMedia: 'Social Media',
};

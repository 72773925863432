import { DEFAULT_LOCALE } from '@halo-common/constants';
import { capitalize } from '@mui/material';

export const formatLocale = (code: string, translationLocale?: string): string => {
  const languageCode = translationLocale?.substring(0, 2) ?? DEFAULT_LOCALE;

  const langObj = new Intl.DisplayNames(['US'], { type: 'language' });
  const regionObj = new Intl.DisplayNames([languageCode], { type: 'region' });

  const formattedRegionCode = code.length > 2 ? code.substring(3, code.length) : 'US';
  const formattedLanguageCode = code.substring(0, 2);

  const language = langObj.of(formattedLanguageCode) || 'English';
  const region = regionObj.of(formattedRegionCode);

  if (code.length === 2) return `${capitalize(language)}`;
  return `${capitalize(language)} (${region})`;
};

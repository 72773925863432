import { ReactElement } from 'react';

import { createdUsersAtom } from '@halo-atoms/userCreation';
import { translations } from '@halo-common/translations';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Alert, Chip, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

const chipSx = {
  color: 'primary.main',
  backgroundColor: 'primary.background',
  '& .MuiChip-deleteIcon': {
    color: 'primary.main',
  },
};

const nameSx = {
  width: '100%',
};

const rolesSx = {
  flexWrap: 'wrap',
};

export const CreateUserSuccess = (): ReactElement => {
  const newUsers = useAtomValue(createdUsersAtom);

  const newUser = newUsers[0];

  const orgName = newUser?.organization?.name ?? '';
  const firstName = newUser?.firstName ?? '';
  const lastName = newUser?.lastName ?? '';
  const email = newUser?.email ?? '';
  const relationshipManager = newUser?.relationshipManager?.name;
  const roles = newUser?.roles?.map((role) => {
    const label = <LocalizedTypography variant="body2">{role}</LocalizedTypography>;

    return <Chip size="small" sx={chipSx} key={role} label={label} />;
  });

  return (
    <Stack direction="column" spacing={3}>
      <Alert severity="success" variant="filled">
        An activation email was sent to this user to set a password.
      </Alert>
      <Stack direction="column" spacing={1}>
        <LocalizedTypography variant="caption" color="text.secondary">
          {translations.common.organization}
        </LocalizedTypography>
        <Typography variant="subtitle1" color="text.primary">
          {orgName}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Stack sx={nameSx} direction="column" spacing={1}>
          <LocalizedTypography variant="caption" color="text.secondary">
            {translations.common.firstName}
          </LocalizedTypography>
          <Typography variant="subtitle1" color="text.primary">
            {firstName}
          </Typography>
        </Stack>
        <Stack sx={nameSx} direction="column" spacing={1}>
          <LocalizedTypography variant="caption" color="text.secondary">
            {translations.common.lastName}
          </LocalizedTypography>
          <Typography variant="subtitle1" color="text.primary">
            {lastName}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="column" spacing={1}>
        <LocalizedTypography variant="caption" color="text.secondary">
          {translations.common.emailAddress}
        </LocalizedTypography>
        <Typography variant="subtitle1" color="text.primary">
          {email}
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1}>
        <LocalizedTypography variant="caption" color="text.secondary">
          {translations.common.relationshipManager}
        </LocalizedTypography>
        <Typography variant="subtitle1" color="text.primary">
          {relationshipManager}
        </Typography>
      </Stack>
      <Stack direction="column" spacing={1}>
        <LocalizedTypography variant="caption" color="text.secondary">
          {translations.common.roles}
        </LocalizedTypography>
        <Stack sx={rolesSx} useFlexGap direction="row" spacing={1}>
          {roles}
        </Stack>
      </Stack>
    </Stack>
  );
};

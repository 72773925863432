import { NoteTypeEnum } from '@halo-common/enums';
import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type NoteQueryKeys = {
  all: QueryKeyFactoryFn;
  note: QueryKeyFactoryFn;
  probabilityBreach: QueryKeyFactoryFn;
  incomeOutcomes: QueryKeyFactoryFn;
  incomeStats: QueryKeyFactoryFn;
  quoteHistory: QueryKeyFactoryFn;
  recentlyCreated: QueryKeyFactoryFn;
  rollingReturns: QueryKeyFactoryFn;
  quote: QueryKeyFactoryFn;
  payoff: QueryKeyFactoryFn;
  lookback: QueryKeyFactoryFn;
  underlyingPerformance: QueryKeyFactoryFn;
  noteByTermsheet: QueryKeyFactoryFn;
};

export const NoteQueryKeyFactory: NoteQueryKeys = {
  all: () => ['notes'],
  note: (id?: number) => [...NoteQueryKeyFactory.all(), id],
  probabilityBreach: (id?: number) => [...NoteQueryKeyFactory.note(id), 'probability', 'breach'],
  incomeOutcomes: (id?: number) => [...NoteQueryKeyFactory.note(id), 'income', 'outcomes'],
  incomeStats: (id?: number) => [...NoteQueryKeyFactory.note(id), 'income', 'stats'],
  quoteHistory: (id?: number) => [...NoteQueryKeyFactory.note(id), 'quote', 'history'],
  recentlyCreated: () => [...NoteQueryKeyFactory.all(), 'recent'],
  rollingReturns: (noteId?: number, quoteId?: number) => [
    ...NoteQueryKeyFactory.note(noteId),
    'quote',
    quoteId,
    'rolling_returns',
  ],
  quote: (id?: number) => [...NoteQueryKeyFactory.all(), 'quote', id],
  payoff: (productType: NoteTypeEnum, id?: number, noteId?: number) => [
    ...NoteQueryKeyFactory.quote(id),
    'payoff',
    noteId,
    productType,
  ],
  lookback: (id?: number) => [...NoteQueryKeyFactory.quote(id), 'lookback'],
  underlyingPerformance: (id?: number) => [...NoteQueryKeyFactory.all(), 'underlying', 'performance', id],
  noteByTermsheet: (id?: number) => [...NoteQueryKeyFactory.all(), 'termsheet', id],
};

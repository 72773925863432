import { OrganizationModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import {
  object as yupObject,
  string as yupString,
  number as yupNumber,
  array as yupArray,
  mixed as yupMixed,
} from 'yup';

export const createUserSchema = yupObject().shape({
  firstName: yupString().required(translations.userAndOrgCreation.firstNameError),
  lastName: yupString().required(translations.userAndOrgCreation.lastNameError),
  email: yupString()
    .email(translations.userAndOrgCreation.emailError)
    .required(translations.userAndOrgCreation.emailError),
  roles: yupArray()
    .of(yupNumber().defined())
    .min(1, translations.userAndOrgCreation.roleError)
    .required(translations.userAndOrgCreation.roleError),
});

export type CreateUsersFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  roles: Array<number>;
};

export const selectOrgSchema = yupObject().shape({
  organization: yupMixed<OrganizationModel>().required(translations.userAndOrgCreation.orgSelectError),
});

export type SelectOrgFormFields = {
  organization?: OrganizationModel | null;
};

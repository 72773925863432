import { CalendarPDFViewerPayload, CalendarPdfViewerDispositionTypeState } from '@halo-atoms/calendars';
import {
  DynamicFiltersHeaderFiltersModel,
  DynamicFiltersMinMaxModel,
  DynamicFiltersSideBarFiltersModel,
  DynamicFiltersToggleModel,
  dynamicFiltersClearAtom,
  dynamicFiltersHeaderManagerAtom,
  dynamicFiltersInitialLoadAtom,
  dynamicSideBarManagerAtom,
  initialHeaderFilters,
} from '@halo-atoms/common';
import { FIXED_INCOME_PREFERENCE_TAG } from '@halo-common/constants';
import { SortModelDirectionEnum } from '@halo-common/enums';
import { CalendarModel, SortModel } from '@halo-common/models';
import { atom } from 'jotai';

// TODO: Add back product types when BE supports it
export type FixedIncomeCheckboxFilterModel = {
  starred: boolean;
  submittedIndication: boolean;
  // fixed: boolean;
  // fixedToFloating: boolean;
  // floating: boolean;
  // fixedStepUp: boolean;x
  // mlcd: boolean;
  callable: boolean;
  notCallable: boolean;
  capped: boolean;
  uncapped: boolean;
};

export type FixedIncomeQueryModel = Omit<DynamicFiltersHeaderFiltersModel, 'sortBy'> &
  DynamicFiltersSideBarFiltersModel & {
    sort: Array<SortModel>;
    calendarNoteId: number | null;
  };

export type FixedIncomeManagerModel = {
  query: FixedIncomeQueryModel;
  isFiltered: boolean;
  isInitialLoad: boolean;
};

export type FixedIncomeQueryPayload = {
  sortBy?: string;
  filters?: Partial<DynamicFiltersHeaderFiltersModel>;
  toggles?: Partial<DynamicFiltersToggleModel>;
  checkboxes?: Partial<FixedIncomeCheckboxFilterModel>;
  checkbox?: string;
  toggle?: string;
  protectionAmount?: DynamicFiltersMinMaxModel | null;
  term?: DynamicFiltersMinMaxModel | null;
  currencies?: Array<string>;
  issuers?: Array<string>;
  productTypes?: Array<string>;
  initialPageLoad?: boolean;
};

export const initialFixedIncomeSortBy = 'term:asc';

// TODO: Add back product types when BE supports it
export const initialFixedIncomeCheckbox: FixedIncomeCheckboxFilterModel = {
  starred: false,
  submittedIndication: false,
  // fixed: false,
  // fixedToFloating: false,
  // floating: false,
  // fixedStepUp: false,
  // mlcd: false,
  callable: false,
  notCallable: false,
  capped: false,
  uncapped: false,
};

export const fixedIncomeSelectedAtom = atom<CalendarModel | null>(null);

const _fixedIncomeDispositionTypeAtom = atom<CalendarPdfViewerDispositionTypeState>('');
export const fixedIncomePDFViewerAtom = atom(
  (get): CalendarPDFViewerPayload => ({
    calendar: get(fixedIncomeSelectedAtom),
    disposition: get(_fixedIncomeDispositionTypeAtom),
  }),
  (_, set, { calendar, disposition }: Partial<CalendarPDFViewerPayload>) => {
    if (typeof disposition === 'string') set(_fixedIncomeDispositionTypeAtom, disposition);
    if (calendar || calendar === null) set(fixedIncomeSelectedAtom, calendar);
  },
);

export const fixedIncomeHeaderFiltersAtom = atom(
  (get) => get(dynamicFiltersHeaderManagerAtom),
  (_, set, payload: FixedIncomeQueryPayload['filters']) => {
    if (payload) set(dynamicFiltersHeaderManagerAtom, { tag: FIXED_INCOME_PREFERENCE_TAG, ...initialHeaderFilters });
  },
);

const DEFAULT_FIXED_INCOME_SORT: Array<SortModel> = [
  { field: 'isActive', direction: SortModelDirectionEnum.desc },
  { field: 'expirationDate', direction: SortModelDirectionEnum.asc },
  { field: 'term', direction: SortModelDirectionEnum.asc },
];

export const fixedIncomeManagerAtom = atom((get): FixedIncomeManagerModel => {
  const getFixedIncomeSideBarFilters = get(dynamicSideBarManagerAtom);
  const getFixedIncomeHeaderFilters = get(fixedIncomeHeaderFiltersAtom);

  const fixedIncomeSideBarFilters = getFixedIncomeSideBarFilters(FIXED_INCOME_PREFERENCE_TAG);
  const fixedIncomeHeaderFilters = getFixedIncomeHeaderFilters(FIXED_INCOME_PREFERENCE_TAG);
  const fixedIncomeInitialPageLoad = get(dynamicFiltersInitialLoadAtom);

  const sortByParameters = fixedIncomeHeaderFilters?.sortByParameters;
  const fixedIncomeSortBy = sortByParameters?.length ? sortByParameters : DEFAULT_FIXED_INCOME_SORT;

  const query = {
    ...fixedIncomeHeaderFilters,
    ...fixedIncomeSideBarFilters,
    sort: fixedIncomeSortBy,
  };

  const isFiltered = fixedIncomeSideBarFilters.isFiltered || fixedIncomeHeaderFilters.isFiltered;

  return { query, isFiltered, isInitialLoad: fixedIncomeInitialPageLoad };
});

export const clearFixedIncomeFiltersAtom = atom(null, (_, set) => {
  set(dynamicFiltersClearAtom, { tag: FIXED_INCOME_PREFERENCE_TAG, action: 'all' });
});

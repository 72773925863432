import { PortfolioOrderEvaluationDataPoint } from '@halo-common/models';
import { fetchPortfolioOrderEvaluation } from '@halo-data-sources/clients';
import { PortfolioQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

const getPortfolioOrderEvaluationQueryFn = async (id?: number) => {
  if (!id) return [];

  const response = await fetchPortfolioOrderEvaluation(id.toString());

  const evaluation =
    response.dates?.map((date, index) => ({
      x: DateTime.fromISO(date).toSeconds(),
      'Note Value': parseFloat(response.values?.[index]?.toFixed(2)) ?? null,
    })) ?? [];

  return evaluation;
};

export const usePortfolioOrderEvaluationQuery = (
  id?: number,
): UseQueryResult<Array<PortfolioOrderEvaluationDataPoint>, Error> =>
  useQuery<Array<PortfolioOrderEvaluationDataPoint>, Error>({
    queryKey: PortfolioQueryKeyFactory.evaluation(id),
    queryFn: () => getPortfolioOrderEvaluationQueryFn(id),
  });

import { HTMLAttributes, Key, MouseEvent, ReactElement, SyntheticEvent, useEffect, useMemo } from 'react';

import { anchorElAtom } from '@halo-atoms/common';
import { ActionButton, Chipography } from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { alphabeticallySortWatchlists } from '@halo-common/utils';
import { useCreateWatchlistMutation, useUpdateNoteWatchlistMutation } from '@halo-data-sources/mutations';
import { useWatchlistNoteQuery, useWatchlistsQuery } from '@halo-data-sources/queries';
import { DEFAULT_WATCHLIST_ICON } from '@halo-modules/app';
import { LocalizedButton, LocalizedTextField } from '@halodomination/halo-fe-common';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  Stack,
  createFilterOptions,
} from '@mui/material';
import { atom, useAtom, useSetAtom } from 'jotai';
import { isEqual } from 'lodash';

import { WatchlistTypeaheadOption } from './WatchlistTypeaheadOption';

const generateFilterOptions = createFilterOptions({
  stringify: (option: WatchlistOption) => option.name,
});

export type WatchlistOption = {
  id: number;
  name: string;
  icon: string;
  pendingName?: string | null;
  count?: number;
};

export type WatchlistTypeaheadProps = {
  productId: number;
  productType: ProductTypeEnum;
  onClose: (ev: MouseEvent<HTMLButtonElement>) => void;
};

export const watchlistTypeaheadSelectedAtom = atom<Array<WatchlistOption>>([]);

export const WatchlistTypeahead = ({ productId, productType, onClose }: WatchlistTypeaheadProps): ReactElement => {
  const setAnchorElMap = useSetAtom(anchorElAtom);
  const [selectedWatchlists, setSelectedWatchlists] = useAtom(watchlistTypeaheadSelectedAtom);

  const { mutate: createWatchlist, ...createWatchlistMutation } = useCreateWatchlistMutation({ addToTypeahead: true });
  const { mutate: updateNoteWatchlist, ...updateNoteMutation } = useUpdateNoteWatchlistMutation();

  const { data: watchlists = [], ...watchlistQuery } = useWatchlistsQuery();
  const { data: noteWatchlists } = useWatchlistNoteQuery(productId, productType);

  const options = useMemo(
    () =>
      watchlists.map((watchlist) => ({
        id: watchlist.id,
        name: watchlist.name,
        icon: watchlist.icon,
        newValue: false,
      })),
    [watchlists],
  );
  const currentWatchlists = noteWatchlists ?? [];

  const mutationLoading = createWatchlistMutation.isPending || updateNoteMutation.isPending;
  const initializeSelectedOptions = currentWatchlists.length && watchlists.length && !watchlistQuery.isPending;

  const selectedWatchlistsAreNotModified = useMemo(() => {
    const currentIds = new Set(currentWatchlists.map((watchlist) => watchlist.id));
    const selectedIds = new Set(selectedWatchlists.map((watchlist) => watchlist.id));
    return isEqual(currentIds, selectedIds);
  }, [currentWatchlists, selectedWatchlists]);

  const getOptionLabel = (option: string | WatchlistOption) => {
    return typeof option === 'string' ? option : option.name;
  };

  const getOptionEquality = (option: WatchlistOption, val: WatchlistOption) => {
    return option.id === val.id;
  };

  const handleWatchlistSubmission = () => {
    const addIds = alphabeticallySortWatchlists(selectedWatchlists).map((watchlist) => watchlist.id);

    const removeIds = currentWatchlists
      .filter((watchlist) => !selectedWatchlists.some((selected) => selected.id === watchlist.id))
      .map((watchlist) => watchlist.id);

    const uniqueRemoveIds = [...new Set(removeIds)];

    updateNoteWatchlist({ productId, productType, addIds, removeIds: uniqueRemoveIds });
  };

  const handleRemoveWatchlist = (watchlist: WatchlistOption) => {
    const updatedWatchlists = selectedWatchlists.filter((item) => item.id !== watchlist.id) ?? [];
    setSelectedWatchlists(updatedWatchlists);
  };

  const handleAutocompleteChange = (
    _: SyntheticEvent,
    _newValue: unknown,
    _reason: unknown,
    details?: AutocompleteChangeDetails<WatchlistOption>,
  ) => {
    if (details?.option.pendingName) {
      const product = { id: productId, type: productType };
      const pendingWatchlist = { name: details.option.pendingName, icon: DEFAULT_WATCHLIST_ICON, product };
      createWatchlist(pendingWatchlist);
    } else if (selectedWatchlists.some((item) => item.id === details?.option.id)) {
      const updatedWatchlists = selectedWatchlists.filter((item) => item.id !== details?.option.id);
      setSelectedWatchlists(updatedWatchlists);
    } else if (details) {
      const updatedWatchlists = [...selectedWatchlists, details.option];
      setSelectedWatchlists(updatedWatchlists);
    }
  };

  const handleOptionFilter = (options: Array<WatchlistOption>, params: FilterOptionsState<WatchlistOption>) => {
    const filtered = generateFilterOptions(options, params);

    const inputValue = params.inputValue.trim();
    const noMatch = inputValue && !options.some((option) => option.name === inputValue);

    if (noMatch) filtered.unshift({ icon: 'info-circle', count: 0, id: -1, name: inputValue, pendingName: inputValue });

    return filtered;
  };

  const handleRenderTag = (value: Array<WatchlistOption>, getTagProps: AutocompleteRenderGetTagProps) =>
    value.map((tag, index) => {
      const { key, ...tagProps } = getTagProps({ index });

      return (
        <Chipography
          {...tagProps}
          key={key}
          label={tag.name}
          onDelete={(ev) => {
            ev?.stopPropagation();
            handleRemoveWatchlist(tag);
          }}
          size="small"
        />
      );
    });

  const handleRenderOption = (
    { key, ...props }: HTMLAttributes<HTMLLIElement> & { key: Key },
    option: WatchlistOption,
    { inputValue }: AutocompleteRenderOptionState,
  ) => (
    <li key={key} {...props}>
      <WatchlistTypeaheadOption
        name={option.name}
        highlight={inputValue}
        pending={Boolean(option.pendingName)}
        count={option.count}
      />
    </li>
  );

  useEffect(() => {
    if (updateNoteMutation.isSuccess) setAnchorElMap({ [productId]: null });
  }, [updateNoteMutation.isSuccess]);

  useEffect(() => {
    if (initializeSelectedOptions) {
      const updatedSelected = options?.filter(({ id }) => currentWatchlists.some((watchlist) => watchlist.id === id));
      setSelectedWatchlists(alphabeticallySortWatchlists(updatedSelected));
    }
  }, [initializeSelectedOptions]);

  return (
    <Stack direction="column" spacing={2}>
      <Autocomplete
        autoComplete
        autoHighlight
        multiple
        openOnFocus
        disableClearable
        noOptionsText={translations.components.watchlistTypeAheadEmptyMessage}
        value={selectedWatchlists}
        options={options}
        getOptionLabel={getOptionLabel}
        filterOptions={handleOptionFilter}
        isOptionEqualToValue={getOptionEquality}
        onChange={handleAutocompleteChange}
        renderTags={handleRenderTag}
        renderOption={handleRenderOption}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <LocalizedTextField
            {...params}
            helperText={translations.components.watchListTypeAheadHelperText}
            label={translations.components.watchlistTypeAheadLabel}
            size="large"
            name="watchlists"
            multiline
          />
        )}
      />
      <Stack direction="row" justifyContent="end" spacing={2}>
        <LocalizedButton onClick={onClose} type="button" variant="outlined" color="primary">
          {translations.common.cancel}
        </LocalizedButton>
        <ActionButton
          onClick={handleWatchlistSubmission}
          type="button"
          variant="contained"
          color="primary"
          loading={mutationLoading}
          disabled={selectedWatchlistsAreNotModified}
        >
          {translations.common.save}
        </ActionButton>
      </Stack>
    </Stack>
  );
};

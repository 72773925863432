import { getClientConfiguration, request } from '@halo-data-sources/clients';
import { ApiQueryRequestModel, GetRolesResponseModel } from '@halo-data-sources/models';

const ROLES_PATH = '/common/role';

export const getRoles = async (payload: ApiQueryRequestModel): Promise<GetRolesResponseModel> => {
  const clientConfiguration = await getClientConfiguration(ROLES_PATH);

  const response = await request<GetRolesResponseModel>(`${clientConfiguration.basePath}/query`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

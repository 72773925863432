import { ReactElement } from 'react';

import { FavoriteButton } from '@halo-common/components';
import { useFixedIncomeFavoriteMutation } from '@halo-data-sources/mutations';

export type FixedIncomeProductCardFavoriteBtnPropTypes = {
  isFavorite: boolean;
  calendarId: number;
  assetIdentifier: string;
  assetIdentifierType: string;
};

export const FixedIncomeProductCardFavoriteBtn = ({
  isFavorite,
  calendarId,
  assetIdentifier,
  assetIdentifierType,
}: FixedIncomeProductCardFavoriteBtnPropTypes): ReactElement => {
  const { mutate } = useFixedIncomeFavoriteMutation();

  const handleFavoriteClick = (favorite: boolean) => {
    const payload = { favorite, id: calendarId, assetIdentifier, assetIdentifierType };
    mutate(payload);
  };

  return <FavoriteButton checked={isFavorite} onClick={handleFavoriteClick} className="wm-productshelf-favorite" />;
};

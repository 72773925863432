import { ReactElement } from 'react';

import {
  DynamicFiltersHeaderPayload,
  dynamicAssetSearchOptionAtom,
  dynamicFiltersClearAtom,
  dynamicFiltersHeaderManagerAtom,
} from '@halo-atoms/common';
import { AssetSearchAlert, DateRangePicker, UnderlyingAutocomplete } from '@halo-common/components';
import { DynamicFiltersUnderlyingMatchEnum } from '@halo-common/enums';
import { DynamicFiltersLayoutProps, DynamicSearchAssetTypeAhead } from '@halo-common/layouts';
import { UnderlyingModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { LocalizedMenuItem } from '@halodomination/halo-fe-common';
import { OutlinedInput, Select, SelectChangeEvent, Stack } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useT } from '@transifex/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';

import { DynamicSearchFiltersSortBy } from './DynamicSearchFiltersSortBy';

const searchLimitationTextAlertSx = {
  mb: 2,
};

const underlyingAutocompleteSx = {
  ['& fieldset']: {
    borderLeft: 0,
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
  },
  width: '100%',
};

const matchSelectSx = {
  ['& fieldset']: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  width: 150,
  height: 41,
};

const exportAndSortByContainerSx = {
  py: 2,
};

const underlyingAutocompleteChipSx = {
  color: 'primary.main',
  backgroundColor: 'primary.background',
  '& .MuiChip-deleteIcon': {
    color: 'primary.main',
  },
};

const underlyingWrapSx = {
  flexBasis: '50%',
  minWidth: 415,
  width: '100%',
};

export type DynamicSearchFiltersProps = {
  tag: string;
  slots?: DynamicFiltersLayoutProps['slots'];
  rules?: DynamicFiltersLayoutProps['rules'];
};

const dropdownText = {
  [DynamicFiltersUnderlyingMatchEnum.ALL]: translations.dynamicFilters.common.containsAll,
  [DynamicFiltersUnderlyingMatchEnum.ANY]: translations.dynamicFilters.common.containsAny,
  [DynamicFiltersUnderlyingMatchEnum.EXACTLY]: translations.dynamicFilters.common.isExactly,
};

export const DynamicSearchFilters = ({ tag, rules, slots }: DynamicSearchFiltersProps): ReactElement => {
  const translator = useT();

  const [getHeaderFilters, setHeaderFilters] = useAtom(dynamicFiltersHeaderManagerAtom);

  const onClear = useSetAtom(dynamicFiltersClearAtom);

  const getSearchOption = useAtomValue(dynamicAssetSearchOptionAtom);
  const searchOption = getSearchOption(tag);

  const headerFilters = getHeaderFilters(tag);
  const { endDate, startDate, underlyings, underlyingMatches } = headerFilters;

  const handleExactChange = (e: SelectChangeEvent) => {
    const value = e.target.value as DynamicFiltersUnderlyingMatchEnum;

    const filters: Partial<DynamicFiltersHeaderPayload> = { underlyingMatches: value };

    setHeaderFilters({ tag, ...filters });
  };

  const handleUnderlyingAutocompleteChange = (selections: Array<UnderlyingModel>) => {
    setHeaderFilters({ tag, underlyings: selections });
  };

  const handleDateChange = (value: DateRange<DateTime>) => {
    const startDate = value[0]?.toISODate();
    const endDate = value[1]?.toISODate();

    const filters: Partial<DynamicFiltersHeaderPayload> = {};

    filters.startDate = startDate ?? undefined;
    filters.endDate = endDate ?? undefined;

    setHeaderFilters({ tag, ...filters });
  };

  const handleAssetSearchClear = () => {
    onClear({ tag, action: 'asset' });
  };

  const searchLimitationTextAlert = searchOption ? (
    <AssetSearchAlert onClear={handleAssetSearchClear} sx={searchLimitationTextAlertSx} />
  ) : null;

  return (
    <>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Stack direction="row" sx={underlyingWrapSx}>
          <Select
            onChange={handleExactChange}
            value={underlyingMatches}
            input={<OutlinedInput sx={matchSelectSx} size="medium" />}
            className="wm-productshelf-searchasset-dropdown"
            renderValue={(value) => {
              const text = dropdownText[value];
              return translator(text);
            }}
          >
            <LocalizedMenuItem
              value={DynamicFiltersUnderlyingMatchEnum.EXACTLY}
              className="wm-productshelf-searchasset-dropdown-isexactly"
            >
              {translations.dynamicFilters.common.isExactly}
            </LocalizedMenuItem>
            <LocalizedMenuItem
              value={DynamicFiltersUnderlyingMatchEnum.ALL}
              className="wm-productshelf-searchasset-dropdown-containsall"
            >
              {translations.dynamicFilters.common.containsAll}
            </LocalizedMenuItem>
            <LocalizedMenuItem
              value={DynamicFiltersUnderlyingMatchEnum.ANY}
              className="wm-productshelf-searchasset-dropdown-containsany"
            >
              {translations.dynamicFilters.common.containsAny}
            </LocalizedMenuItem>
          </Select>
          <UnderlyingAutocomplete
            sx={underlyingAutocompleteSx}
            label={translations.dynamicFilters.common.underlyingAutocompleteLabel}
            ChipProps={{ sx: underlyingAutocompleteChipSx, size: 'small' }}
            variant="outlined"
            size="medium"
            value={underlyings}
            onChange={handleUnderlyingAutocompleteChange}
            tag={tag}
            className="wm-productshelf-searchasset-input"
          />
        </Stack>
        <DynamicSearchAssetTypeAhead tag={tag} {...rules} />
        <DateRangePicker label="Close Date" onChange={handleDateChange} startDate={startDate} endDate={endDate} />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={exportAndSortByContainerSx}
        spacing={2}
      >
        {slots?.ExportButton}
        <DynamicSearchFiltersSortBy tag={tag} />
      </Stack>
      {searchLimitationTextAlert}
    </>
  );
};

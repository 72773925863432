import { DEFAULT_LOCALE, HALO_ORGANIZATION_DETAILS } from '@halo-common/constants';
import { DocGenPerformanceReportTypeEnum, NoteTypeEnum } from '@halo-common/enums';
import type {
  DashboardSetWidgetModel,
  DashboardWidgetSetModel,
  OrganizationModel,
  UserCalendarFilterSettingModel,
  UserCountryModel,
  UserCreatedModel,
  UserGeographicSettingModel,
  UserModel,
  UserOnboardingSettingModel,
  UserRegionModel,
  UserRepRelationshipModel,
  UserSettingsModel,
} from '@halo-common/models';
import { CreateUsersFormFields } from '@halo-common/schemas';
import { ApiUserRoleEnum } from '@halo-data-sources/enums';
import {
  ApiCalendarsMapper,
  ApiOrganizationMapper,
  ApiRelationshipManagerMapper,
  ApiUserPreferencesMapper,
  ApiUserRoleMapper,
  ApiWhiteLabelImageMapper,
  ApiWhiteLabelMapper,
  ApiWhiteLabelThemeMapper,
} from '@halo-data-sources/mappers';
import type {
  ApiCalendarFilterSettingSetModel,
  ApiCommonUserSettings,
  ApiDashboardSetWidget,
  ApiDashboardWidgetSet,
  ApiNewUserInfoModel,
  ApiUserCountry,
  ApiUserCreationUserModel,
  ApiUserGeographicSetting,
  ApiUserInfoResponseModel,
  ApiUserModel,
  ApiUserRegion,
  ApiUserRepIdsModel,
} from '@halo-data-sources/models';
import type { CommonUserSettings, UserInfoModel } from '@halo-data-sources/queries';
import { capitalize } from '@mui/material';

interface UserMapperModel {
  toApiUserCreationUserModel: (model: CreateUsersFormFields, org: OrganizationModel) => ApiUserCreationUserModel;
}

export const UserMapper: UserMapperModel = {
  toApiUserCreationUserModel: (model, org) => {
    return {
      country_id: null,
      email: model.email,
      first_name: model.firstName,
      last_name: model.lastName,
      locale: DEFAULT_LOCALE,
      organization_id: org.id,
      region_id: null,
      relationship_manager_id: org.relationshipManager?.id,
      rep_ids: [],
      role_ids: model.roles,
      send_activation_email: true,
    };
  },
};

interface ApiUserMapperModel {
  toDashboardWidget: (dashboard_widget_set: ApiDashboardSetWidget) => DashboardSetWidgetModel;
  toCalendarFilterSettingSet: (response: ApiCalendarFilterSettingSetModel) => UserCalendarFilterSettingModel;
  toDashboardWidgetSet: (dashboard_widget_set: ApiDashboardWidgetSet) => DashboardWidgetSetModel;
  toOnboardingSettings: (details: ApiUserInfoResponseModel) => UserOnboardingSettingModel;
  toUser: (user: ApiUserModel) => UserModel;
  toUserSettings: (details: ApiUserInfoResponseModel) => Partial<UserSettingsModel>;
  toUserRepIds: (repIds: ApiUserRepIdsModel) => UserRepRelationshipModel;
  toUserInfo: (response: ApiUserInfoResponseModel) => UserInfoModel;
  toUserRegion: (response: ApiUserRegion) => UserRegionModel;
  toUserCountry: (response: ApiUserCountry) => UserCountryModel;
  toUserGeographicSetting: (response: ApiUserGeographicSetting) => UserGeographicSettingModel;
  toCommonUserSettings: (response: ApiCommonUserSettings) => CommonUserSettings;
  toNewUserModel: (response: ApiNewUserInfoModel) => UserCreatedModel;
}

export const ApiUserMapper: ApiUserMapperModel = {
  toDashboardWidget: (dashboard_widget) => {
    const { visible_by_default, widget, ...rest } = dashboard_widget ?? {};
    const { engine, ...widgetProps } = widget ?? {};
    return {
      ...rest,
      engine,
      visibleByDefault: visible_by_default,
      widget: widgetProps,
    };
  },
  toDashboardWidgetSet: (dashboard_widget_set) => ({
    id: dashboard_widget_set.id,
    name: dashboard_widget_set.name,
    widgets: dashboard_widget_set.widgets?.map(ApiUserMapper.toDashboardWidget),
    widgetsAreHidable: dashboard_widget_set.widgets_are_hidable,
    widgetsAreReorderable: dashboard_widget_set.widgets_are_reorderable,
  }),
  toOnboardingSettings: (details) => {
    const settings = details.onboarding_setting;
    const brand = details.white_label.brand;

    const backgroundImage = brand.onboarding_background
      ? ApiWhiteLabelImageMapper.toWhiteLabelImage(brand.onboarding_background)
      : null;

    return {
      id: settings?.id,
      name: settings?.name,
      onboardingDisclaimerContent: settings?.onboarding_disclaimer_content,
      useInternationalLocations: settings?.use_international_locations,
      requireCrd: settings?.require_crd,
      requireCompanyName: settings?.require_company_name,
      documentLinks: settings?.document_links,
      overline: settings?.overline,
      title: settings?.title,
      subtitle: settings?.subtitle,
      body: settings?.body,
      video: settings?.video_link,
      image: settings?.image ? ApiWhiteLabelImageMapper.toWhiteLabelImage(settings?.image) : null,
      buttonText: settings?.cta_button_text,
      buttonLink: settings?.cta_button_link,
      drawerBackground: backgroundImage,
      isLightBackground: brand.onboarding_background_light,
      logoDark: brand.logo_dark ? ApiWhiteLabelImageMapper.toWhiteLabelImage(brand.logo_dark) : null,
      logoLight: brand.logo_light ? ApiWhiteLabelImageMapper.toWhiteLabelImage(brand.logo_light) : null,
      subtitles: brand.subtitles,
      showPoweredByHalo: brand.powered_by_halo_disclaimer,
      displaySurveyQuestion: settings?.display_survey_question,
    };
  },
  toUserSettings: (details) => {
    const {
      user,
      brand,
      auction_notes_enabled,
      organization,
      white_label,
      coupon_summary_settings,
      orderbook_setting,
    } = details;

    const useHaloFooter = Boolean(white_label?.use_default_halo_footer_content);

    const isHaloBrand = white_label?.brand.name.toLowerCase() === HALO_ORGANIZATION_DETAILS.name.toLowerCase();

    const hasEducationLockRole = Boolean(user?.roles?.includes(ApiUserRoleEnum.EducationLock));
    const hasEducationWriteRole = Boolean(user?.roles?.includes(ApiUserRoleEnum.WriteEducationHub));
    const hasEducationRIARole = Boolean(user?.roles?.includes(ApiUserRoleEnum.RIAAdmin));

    const showEducationHubToggles = hasEducationWriteRole || hasEducationRIARole;

    const startAuctionTypeWhitelist =
      auction_notes_enabled?.map((type) => {
        const mappedType = capitalize(type) as keyof typeof NoteTypeEnum;
        return NoteTypeEnum[mappedType];
      }) ?? [];

    const hasReportTemplate = white_label?.portfolio_performance_reports?.some(
      (report) => report.enabled && report.report_type === DocGenPerformanceReportTypeEnum.Account,
    );

    const show_status = coupon_summary_settings?.show_status ?? false;
    const show_amount = coupon_summary_settings?.show_amount ?? false;
    const show_date = coupon_summary_settings?.show_date ?? false;
    const showAllCouponSummaryInfo = show_amount && show_date && show_status;

    const showBothRRTab = organization?.rolling_returns_tab === 'BOTH';
    const showExecRRTab = showBothRRTab || organization?.rolling_returns_tab === 'CALENDAR';
    const showPreTradeRRTab = showBothRRTab || organization?.rolling_returns_tab === 'PRE_TRADE';

    return {
      useHaloFooter,
      hasReportTemplate,
      showEducationHubToggles,
      showEducationBanner: hasEducationLockRole,
      showCustodyForm: isHaloBrand,
      showPoweredByHalo: brand.powered_by_halo_disclaimer,
      startAuctionTypeWhitelist,
      onboarding: details.onboarding_setting ? ApiUserMapper.toOnboardingSettings(details) : undefined,
      hasMultiCurrencyRole: user?.use_multicurrency_setting,
      enableCalendarAllocations: Boolean(organization?.calendar_allocation_placement_enabled),
      showCalendarEmailSubscription: Boolean(user?.show_calendar_email_subscriber_signup_setting),
      isEnabledToAuction: Boolean(organization?.is_enabled_to_auction),
      orderBookTabs: orderbook_setting?.tabs ?? [],
      couponSummary: {
        showAll: showAllCouponSummaryInfo,
        showAmount: show_amount,
        showDate: show_date,
        showStatus: show_status,
      },
      auctionDatePermissions: {
        canSetStrikeDate: user?.auction_permissions?.can_set_strike_date,
        canSetTradeDate: user?.auction_permissions?.can_set_trade_date,
        canSetSettlementDate: user?.auction_permissions?.can_set_settlement_date,
      },
      showFixedIncomeTab: user?.show_fixed_income_tab_setting,
      showCalendarCategoryFilterSetting: user?.show_calendar_category_filter_setting,
      showExecutionRRTab: showExecRRTab,
      showPreTradeRRTab: showPreTradeRRTab,
    };
  },
  toUserRegion: (region) => ({
    id: region.id,
    name: region.name,
  }),
  toUserCountry: (country) => ({
    id: country.id,
    isoCode: country.iso_code,
    name: country.name,
    regionName: country.region_name,
    regions: country.regions.map(ApiUserMapper.toUserRegion),
  }),
  toUserRepIds: (repIds) => ({
    id: repIds.id,
    userId: repIds.user_id,
    value: repIds.value,
    level: repIds.level,
    type: repIds.type,
  }),
  toUserGeographicSetting: (settings) => ({
    countries: settings.countries.map(ApiUserMapper.toUserCountry),
    id: settings.id,
  }),
  toUser: (user) => {
    const hasPreferredLanguage = user?.current_locale && !user?.current_locale?.includes('en_');
    const preferredLanguage = hasPreferredLanguage ? (user.current_locale as string) : DEFAULT_LOCALE;

    const relationshipManager = ApiRelationshipManagerMapper.toRelationshipManager(user?.relationship_manager);

    return {
      id: user?.id,
      email: user?.email,
      userName: user?.username,
      portfolioAccess: user?.portfolio_access,
      name: user?.name,
      firstName: user?.first_name,
      lastName: user?.last_name,
      isActive: user?.active,
      lastLogin: user?.last_login,
      createdAt: user?.created_on,
      phone: user?.phone,
      preferredLanguage,
      languageProvider: user?.language_provider,
      repIds: user?.rep_ids?.map(ApiUserMapper.toUserRepIds) ?? [],
      organization: user?.organization ? ApiOrganizationMapper.toOrganization(user.organization) : null,
      relationshipManager,
      roles: user?.roles?.map(ApiUserRoleMapper.toUserRole),
      manageAuctionModalSetting: user?.manage_auction_modal_setting,
    };
  },
  toCalendarFilterSettingSet: (response) => {
    const { id, name, settings } = response;

    return {
      id,
      name,
      settings: settings.sort((a, b) => a.position - b.position),
    };
  },
  toUserInfo: (response) => {
    const {
      authenticated,
      brand,
      calendar_filter_settings,
      calendar_page,
      dashboard_widget_set,
      user,
      geographic_setting_set,
      organization: api_organization,
      white_label,
      user_ui_preferences,
      lexicon,
    } = response;

    const details = ApiUserMapper.toUser(user);
    const whiteLabel = ApiWhiteLabelMapper.toWhiteLabel(white_label);
    const themeOptions = ApiWhiteLabelThemeMapper.toHaloThemeModel(brand?.materialtheme);
    const settings = ApiUserMapper.toUserSettings(response);

    const calendarPage = calendar_page ? ApiCalendarsMapper.toCalendarPageModel(calendar_page) : null;
    const dashboardWidgetSet = dashboard_widget_set ? ApiUserMapper.toDashboardWidgetSet(dashboard_widget_set) : null;
    const organization = api_organization ? ApiOrganizationMapper.toOrganization(api_organization) : null;
    const preferences = user_ui_preferences ? ApiUserPreferencesMapper.toUserPreferences(user_ui_preferences) : {};

    const geographicSettingSet = geographic_setting_set
      ? ApiUserMapper.toUserGeographicSetting(geographic_setting_set)
      : null;

    const userLexicon = lexicon
      ? Object.entries(lexicon).reduce((map, [key, value]) => ({ ...map, [key]: value.value }), {})
      : null;

    const calendarPageFilterSettingSet = calendar_filter_settings ?? null;
    const theme = { options: themeOptions };

    return {
      authenticated,
      details,
      calendarPage,
      organization,
      settings,
      dashboardWidgetSet,
      calendarPageFilterSettingSet,
      geographicSettingSet,
      preferences,
      theme,
      whiteLabel,
      lexicon: userLexicon,
    };
  },
  toCommonUserSettings: (commonSettings) => {
    return {
      settingGroups: commonSettings.setting_groups.map((settingGroup) => ({
        name: settingGroup.name,
        settings: settingGroup.settings,
      })),
    };
  },
  toNewUserModel: (userInfo) => {
    return {
      id: userInfo.id,
      firstName: userInfo.first_name,
      lastName: userInfo.last_name,
      email: userInfo.email,
      organization: ApiOrganizationMapper.toOrganization(userInfo.organization),
      relationshipManager: ApiRelationshipManagerMapper.toRelationshipManager(userInfo.relationship_manager),
      roles: userInfo.roles,
    };
  },
};

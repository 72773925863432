import { SettingSetRepIdModel, UserCountryModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { AnyObjectSchema, array as yupArray, number as yupNumber, object as yupObject, string as yupString } from 'yup';

export const registerFormSchema = (
  countries: Array<UserCountryModel>,
  repIdSettingSet?: Array<SettingSetRepIdModel>,
  requireCompanyName?: boolean,
): AnyObjectSchema => {
  const yupShape = yupObject().shape({
    emailAddress: yupString()
      .email(translations.onboarding.register.emailAddressError)
      .required(translations.onboarding.register.emailAddressError),
    firstName: yupString().required(translations.onboarding.register.firstNameError),
    lastName: yupString().required(translations.onboarding.register.lastNameError),
    phoneNumber: yupString().matches(
      /(^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$)|^(?![\s\S])/,
      translations.onboarding.register.phoneNumberError,
    ),
    role: yupString(),
    country: yupNumber().required(translations.onboarding.register.countryError),
    region: yupNumber().when('country', {
      is: (country: number) => {
        const selectedCountry = countries.find((userCountry) => userCountry.id === country);
        const regions = selectedCountry?.regions ?? [];
        return regions.length > 0;
      },
      then: (schema) => schema.required(translations.onboarding.register.regionError),
    }),
    companyName: requireCompanyName
      ? yupString().required(translations.onboarding.register.companyNameError)
      : yupString().notRequired(),
    repSettingArrayField: yupArray().of(
      yupArray().of(
        yupObject()
          .shape({
            repSettingId: yupNumber(),
            value: yupString(),
          })
          .test((currentRepId, ctx) => {
            const { createError, path } = ctx;
            const currentRepIdSetting = repIdSettingSet?.find((repId) => repId.id === currentRepId.repSettingId);

            if (!currentRepId.value && currentRepIdSetting?.required) {
              return createError({ message: translations.messages.requiredField, path: `${path}.value` });
            }

            const maxChar = currentRepIdSetting?.maxCharacters;
            const minChar = currentRepIdSetting?.minCharacters;

            if (!currentRepId.value || !maxChar || !minChar) return true;

            const regex = /^[a-zA-Z0-9]+$/g;
            const isAlphaNumeric = regex.test(currentRepId.value);

            if (!isAlphaNumeric) {
              return createError({
                message: translations.profile.details.alphaNumericOnly,
                path: `${path}.value`,
              });
            }

            const currentRepIdLength = currentRepId.value.length;
            const maxAndMinCharEqual = maxChar === minChar;
            const repIdNotEqualToMaxChar = maxAndMinCharEqual && currentRepIdLength !== maxChar;
            const repIdNotEqualToMinChar = maxAndMinCharEqual && currentRepIdLength !== minChar;

            if (repIdNotEqualToMaxChar && repIdNotEqualToMinChar) {
              return createError({
                message: translations.profile.details.exactCharacters,
                path: `${path}.value`,
              });
            } else if (maxAndMinCharEqual) {
              return true;
            } else if (maxChar < currentRepIdLength) {
              return createError({
                message: translations.profile.details.maxCharacters,
                path: `${path}.value`,
              });
            } else if (minChar > currentRepIdLength) {
              return createError({
                message: translations.profile.details.minCharacters,
                path: `${path}.value`,
              });
            }

            return true;
          }),
      ),
    ),
    surveyAnswer: yupString().notRequired(),
  });

  return yupShape;
};

export const forgotPasswordSchema = yupObject().shape({
  email: yupString().email(translations.messages.validEmail).required(translations.messages.validEmail),
});

export const linkExpiredSchema = yupObject().shape({
  email: yupString().email(translations.messages.validEmail).required(translations.messages.validEmail),
});

export const loginFormSchema = yupObject().shape({
  email: yupString().email(translations.messages.validEmail).required(translations.messages.validEmail),
  password: yupString().required(translations.messages.requiredField),
});

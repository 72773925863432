import { useMemo } from 'react';

import { PershingStatusIndicator } from '@halo-common/components';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { useDateFormatter } from '@halo-common/hooks';
import { OrderBookCalendarDetails } from '@halo-common/models';
import {
  CancelPershingOrderButton,
  OrderBookCalendarsViewOrderDetailsButton,
  ViewApproveOrderModalButton,
} from '@halo-modules/app';
import { HaloDataGridProps, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Box, capitalize } from '@mui/material';

const actionCellSx = {
  alignItems: 'center',
  display: 'flex',
  gap: 1,
  height: '100%',
  width: 'fit-content',
  '& button': { maxWidth: 90, width: 'fit-content' },
};

const commonCellProps = {
  editable: false,
  disableColumnMenu: true,
  disableReorder: true,
  flex: 1,
};

const formatCurrency = (value: number) => useCurrencyConverter(value);

export const useOrderBookPershingTableColumns = (): HaloDataGridProps['columns'] => {
  const formatDate = useDateFormatter();

  const columns: HaloDataGridProps['columns'] = useMemo(
    () => [
      {
        ...commonCellProps,
        field: 'ibd',
        headerName: 'IBD #',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.allocation?.ibd,
      },
      {
        ...commonCellProps,
        field: 'accountId',
        headerName: 'Account #',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.allocation?.account?.accountId,
      },
      {
        ...commonCellProps,
        field: 'user',
        headerName: 'User Name',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.allocation?.user?.name,
      },
      {
        ...commonCellProps,
        field: 'cusip',
        headerName: 'CUSIP',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.calendar?.cusip ?? 'N/A',
        renderCell: ({ row, value }) => {
          return <OrderBookCalendarsViewOrderDetailsButton cusip={value} calendarId={row.calendar?.id} />;
        },
      },
      {
        ...commonCellProps,
        field: 'issuer',
        headerName: 'Issuer',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.calendar?.issuer?.name,
      },
      {
        ...commonCellProps,
        field: 'designation',
        headerName: 'Capacity',
        valueGetter: (_, row: OrderBookCalendarDetails) => row.calendar?.category,
        valueFormatter: (value: string) => (value ? capitalize(value) : ''),
      },
      {
        ...commonCellProps,
        field: 'principal',
        headerName: 'Principal',
        valueGetter: (_, row: OrderBookCalendarDetails) => formatCurrency(row.allocation?.amount),
        align: 'right',
      },
      {
        ...commonCellProps,
        field: 'updatedOn',
        headerName: 'Last Update',
        valueGetter: (_, row: OrderBookCalendarDetails) => {
          return formatDate(row.allocation?.updatedOn, COMBINED_DATE_TIME_ZONE_FORMAT);
        },
        flex: 1.5,
      },
      {
        ...commonCellProps,
        field: 'pershingOrderbookStatus',
        headerName: 'Status',
        renderCell: ({ row }) => <PershingStatusIndicator value={row.allocation?.pershingOrderBookStatus} />,
      },
      {
        ...commonCellProps,
        field: 'actions',
        headerName: '',
        sortable: false,
        resizable: false,
        renderCell: ({ row }) => (
          <Box sx={actionCellSx}>
            <ViewApproveOrderModalButton allocation={row.allocation} />
            <CancelPershingOrderButton allocation={row.allocation} />
          </Box>
        ),
      },
    ],
    [formatDate],
  );

  return columns;
};

import { QueryKeyFactoryFn } from '@halo-data-sources/queries';

type RolesClientQueryKeys = {
  all: QueryKeyFactoryFn;
  infinite: QueryKeyFactoryFn;
};

export const RolesClientQueryKeyFactory: RolesClientQueryKeys = {
  all: () => ['roles'],
  infinite: (query) => [...RolesClientQueryKeyFactory.all(), 'infinite', query],
};

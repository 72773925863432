import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { ProductTypeChip } from '@halo-common/components';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { OrderBookAuctionStatusEnum, ProductTypeEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { GridPaginationModel, NoteModel, OrderBookAuctionModel } from '@halo-common/models';
import { useOrderBookAuctionsQuery } from '@halo-data-sources/queries';
import {
  OrderBookAuctionCreateSimilarButton,
  OrderBookAuctionStatus,
  OrderBookAuctionViewButton,
} from '@halo-modules/app';
import { HaloDataGrid, HaloDataGridProps, useCurrencyConverter } from '@halodomination/halo-fe-common';
import { Box } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';

const actionCellSx = {
  display: 'flex',
  alignItems: 'center',
  flex: '25% 75%',
  '& :first-child': { mr: 1, '& button': { maxWidth: '24%', flex: 1 } },
  '& :not(:first-child) ': { '& button': { maxWidth: '75%', flex: 3 } },
};

export const OrderBookAuctionTable = (): ReactElement => {
  const configureDateTime = useWhiteLabelDateConfiguration();

  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const { isPending, isFetching, data } = useOrderBookAuctionsQuery(searchFields, 10000);

  const rows = data?.auctions ?? [];
  const page = data?.pagination?.page ?? searchFields.page;
  const pageSize = data?.pagination?.resultsPerPage ?? searchFields.pageSize;
  const totalResults = data?.pagination?.totalResults ?? 0;
  const paginationModel = { page: page - 1, pageSize };

  const handlePaginationChange = (model: GridPaginationModel) => {
    if (!isFetching) {
      setSearchFields({
        page: model.page + 1,
        pageSize: model.pageSize,
      });
    }
  };

  const handleSortChange = (model: GridSortModel) => {
    setSearchFields({
      sortBy: model,
    });
  };

  const formatCurrency = (value: number) => useCurrencyConverter(value);

  const commonCellProps = {
    editable: false,
    disableColumnMenu: true,
    disableReorder: true,
    flex: 1,
  };

  const columns: HaloDataGridProps['columns'] = [
    {
      ...commonCellProps,
      field: 'id',
      headerName: 'Auction ID',
      flex: 0.8,
      sortable: false,
    },
    {
      ...commonCellProps,
      field: 'noteType',
      headerName: 'Note Type',
      renderCell: ({ value }) => {
        const note = { type: value, productType: value } as NoteModel;
        return <ProductTypeChip product={note} type={ProductTypeEnum.note} />;
      },
    },
    {
      ...commonCellProps,
      field: 'issuer',
      headerName: 'Issuer',
      flex: 1.4,
      valueGetter: (_, row: OrderBookAuctionModel) => {
        const status = row.status as OrderBookAuctionStatusEnum;
        const isNotWin = status === OrderBookAuctionStatusEnum['not-win'];
        const isCanceled = status === OrderBookAuctionStatusEnum['canceled'];
        const blankIssuer = isNotWin || isCanceled;

        if (blankIssuer) return '-';

        const issuer = row.issuer;

        if (issuer) return issuer;

        const numberOfIssuers = row.numberOfIssuers;
        const numberIssuersResponded = row.numberIssuersResponded;

        if (numberOfIssuers !== 0) return `${numberIssuersResponded}/${numberOfIssuers} Responded`;
        else return '-';
      },
    },
    {
      ...commonCellProps,
      field: 'underlying',
      headerName: 'Underlying',
      sortable: false,
      valueFormatter: (value: Array<string>) => {
        return value?.join(', ');
      },
    },
    {
      ...commonCellProps,
      field: 'payoff',
      headerName: 'Target',
      flex: 1.8,
      sortable: false,
      valueGetter: (value: OrderBookAuctionModel['payoff']) => {
        return value?.payoffString;
      },
    },
    {
      ...commonCellProps,
      field: 'notional',
      headerName: 'Notional',
      valueFormatter: (value: number) => {
        return formatCurrency(value);
      },
    },
    {
      ...commonCellProps,
      field: 'endDate',
      headerName: 'End Date',
      valueFormatter: (value: string) => {
        const endDateTime = configureDateTime(value);
        return endDateTime?.toFormat(COMBINED_DATE_TIME_ZONE_FORMAT);
      },
      flex: 1.8,
    },
    {
      ...commonCellProps,
      field: 'tradeDate',
      headerName: 'Trade Date',
      sortable: false,
      valueFormatter: (value: string) => {
        const tradeDateTime = configureDateTime(value);
        return tradeDateTime?.toLocaleString();
      },
      flex: 0.8,
    },
    {
      ...commonCellProps,
      field: 'status',
      headerName: 'Status',
      flex: 1.6,
      renderCell: ({ value }) => {
        return <OrderBookAuctionStatus value={value} />;
      },
    },
    {
      ...commonCellProps,
      field: 'action',
      headerName: '',
      sortable: false,
      resizable: false,
      flex: 1.4,
      renderCell: ({ row }) => {
        return (
          <Box sx={actionCellSx}>
            <OrderBookAuctionCreateSimilarButton noteId={row.noteId} />
            <OrderBookAuctionViewButton auctionId={row.id} />
          </Box>
        );
      },
    },
  ];

  return (
    <HaloDataGrid
      columns={columns}
      rows={rows}
      loading={isPending}
      pagination
      height="fill"
      paginationMode="server"
      sortingMode="server"
      density="compact"
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationChange}
      onSortModelChange={handleSortChange}
      rowCount={totalResults}
      disableRowSelectionOnClick
    />
  );
};

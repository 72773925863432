import { ReactElement } from 'react';

import { Chipography } from '@halo-common/components';
import { UnderlyingBasketEnum } from '@halo-common/enums';
import { ApiUnderlyingModel } from '@halo-data-sources/models';

interface UnderlyingSelectedAssetsProps {
  basketType: UnderlyingBasketEnum;
  weightedAssets: Record<number, number>;
  selectedAssets: Array<ApiUnderlyingModel>;
  onDeleteSelectedAsset: (asset: ApiUnderlyingModel) => void;
}

export const UnderlyingSelectedAssets = ({
  basketType,
  weightedAssets,
  selectedAssets,
  onDeleteSelectedAsset,
}: UnderlyingSelectedAssetsProps): ReactElement => {
  const isWeighted = basketType === UnderlyingBasketEnum.WEIGHTED;

  return (
    <>
      {selectedAssets.map((asset: ApiUnderlyingModel) => {
        const label = isWeighted ? `${asset.name}  (${weightedAssets[asset.id]}%)` : asset.name;
        const handleDelete = () => onDeleteSelectedAsset(asset);

        return (
          <Chipography
            key={asset.id}
            size="small"
            label={label}
            variant="filled"
            color="primary.background"
            contrastColor="primary.main"
            onDelete={handleDelete}
          />
        );
      })}
    </>
  );
};

import { getClientConfiguration, request } from '@halo-data-sources/clients';
import {
  ApiOrganizationModel,
  ApiQueryRequestModel,
  CreateOrganizationRequestModel,
  GetOrganizationsResponseModel,
} from '@halo-data-sources/models';

const PRETRADE_ADMIN_PATH = '/pretrade/admin';

export const getOrganizations = async (payload: ApiQueryRequestModel): Promise<GetOrganizationsResponseModel> => {
  const clientConfiguration = await getClientConfiguration(PRETRADE_ADMIN_PATH);

  const response = await request<GetOrganizationsResponseModel>(`${clientConfiguration.basePath}/organization/query`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

export const createOrganization = async (payload: CreateOrganizationRequestModel): Promise<ApiOrganizationModel> => {
  const clientConfiguration = await getClientConfiguration(PRETRADE_ADMIN_PATH);

  const response = await request<ApiOrganizationModel>(`${clientConfiguration.basePath}/create_organization`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      ...payload,
    }),
  });

  return response;
};

import { getNotePayoffDataModel } from '@halo-data-sources/clients';
import { NotePayoffSwitchResult } from '@halo-data-sources/switches';
import { LineChartReferenceLine } from '@halodomination/halo-fe-common';

const NOTE_PAYOFF_LINE = 'Note Payoff';
const UNDERLYING_PERFORMANCE_LINE = 'Underlying Performance';

export const payoffSwitchFn = async (
  quoteId?: number,
  protectionPercent?: number,
): Promise<NotePayoffSwitchResult | null> => {
  if (quoteId === undefined) return null;

  const response = await getNotePayoffDataModel(quoteId);

  const data = [];

  for (let i = -100; i <= 100; i++) {
    data.push({
      x: i,
      [NOTE_PAYOFF_LINE]: parseFloat(response[i].toFixed(2)),
      [UNDERLYING_PERFORMANCE_LINE]: i,
    });
  }

  const lines = [{ label: UNDERLYING_PERFORMANCE_LINE }, { label: NOTE_PAYOFF_LINE }];

  const cap = protectionPercent ? -protectionPercent : null;
  const referenceLines: Array<LineChartReferenceLine> | undefined = cap
    ? [{ point: cap, label: 'Protection level', orientation: 'y' }]
    : undefined;

  return { lines, data, references: referenceLines };
};

import { ReactElement } from 'react';

import { UserRelationshipManagerModel } from '@halo-common/models';
import { CreateOrgFormFields } from '@halo-common/schemas';
import { RelationshipManagerTypeAhead } from '@halo-common/smartComponents';
import { translations } from '@halo-common/translations';
import { LocalizedAlert, LocalizedTextField } from '@halodomination/halo-fe-common';
import { InputAdornment, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export const CreateOrgForm = (): ReactElement => {
  const formMethods = useFormContext<CreateOrgFormFields>();

  const { register, setValue, formState } = formMethods;
  const { errors } = formState;

  const orgNameError = errors?.name?.message;
  const hasOrgNameError = Boolean(orgNameError);

  const relationshipManagerError = errors?.relationshipManager?.message;
  const hasRelationshipManagerError = Boolean(relationshipManagerError);

  const emailSuffixError = errors?.emailSuffix?.message;
  const hasEmailSuffixError = Boolean(emailSuffixError);
  const emailSuffixHelperText = hasEmailSuffixError
    ? emailSuffixError
    : translations.userAndOrgCreation.emailSuffixHelperText;

  const { ref: orgNameRef, ...orgNameProps } = register('name');
  const { ref: emailSuffixRef, ...emailSuffixProps } = register('emailSuffix');

  const handleRelationshipManagerChange = (
    option: UserRelationshipManagerModel | Array<UserRelationshipManagerModel> | null,
  ) => {
    const relationshipManager = option as UserRelationshipManagerModel;
    const relationshipManagerId = relationshipManager.id;
    setValue('relationshipManager', relationshipManagerId, { shouldDirty: true, shouldValidate: true });
  };
  return (
    <Stack direction="column" spacing={2}>
      <LocalizedTextField
        {...orgNameProps}
        ref={orgNameRef}
        label={translations.common.name}
        error={hasOrgNameError}
        helperText={orgNameError}
      />
      <RelationshipManagerTypeAhead
        onChange={handleRelationshipManagerChange}
        TextFieldProps={{ error: hasRelationshipManagerError, helperText: relationshipManagerError }}
      />
      <LocalizedTextField
        {...emailSuffixProps}
        ref={emailSuffixRef}
        label={translations.userAndOrgCreation.emailSuffix}
        error={hasEmailSuffixError}
        helperText={emailSuffixHelperText}
        InputProps={{
          startAdornment: <InputAdornment position="start">@</InputAdornment>,
        }}
      />
      <LocalizedAlert severity="info" variant="filled">
        {translations.userAndOrgCreation.newOrgAlert}
      </LocalizedAlert>
    </Stack>
  );
};

import { ReactElement } from 'react';

import { ApiUnderlyingModel, ApiUnderlyingSearchResponseModel } from '@halo-data-sources/index';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, Skeleton, Stack } from '@mui/material';

type UnderlyingTradablesProps = {
  loadingTradables: boolean;
  data: ApiUnderlyingSearchResponseModel | undefined;
  selectedAssets: Array<ApiUnderlyingModel>;
  handleSelectAsset: (asset: ApiUnderlyingModel) => void;
};

export const UnderlyingTradables = ({
  loadingTradables,
  data,
  selectedAssets,
  handleSelectAsset,
}: UnderlyingTradablesProps): ReactElement => {
  if (loadingTradables) {
    return <Skeleton />;
  }

  return (
    <Box sx={{ maxHeight: '1000px', overflow: 'auto' }}>
      {data?.tradables
        .filter((tradable) => tradable.id)
        .filter((tradable) => !selectedAssets.some((asset) => asset.id === tradable.id))
        .map((option: ApiUnderlyingModel) => {
          return (
            <Stack
              direction="row"
              alignItems="center"
              key={option.id}
              onClick={() => handleSelectAsset(option)}
              sx={{
                p: 1,
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: 'primary.background',
                },
              }}
            >
              <Box component="img" src={option?.svg ?? ''} sx={{ height: '40px', width: '40px', borderRadius: 1 }} />
              <Box sx={{ pl: 2 }}>
                <LocalizedTypography variant="overline" color="text.secondary">
                  {option.name}
                </LocalizedTypography>
                <LocalizedTypography variant="subtitle2">{option.description}</LocalizedTypography>
              </Box>
            </Stack>
          );
        })}
    </Box>
  );
};

import { Input } from '@halo-common/components/FormBuilder';
import { translations } from '@halo-common/translations';
import { OnboardingSuitabilityForm, SuitabilityStepModel } from '@halo-modules/app/v2/onboarding/subPages/suitability';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Stack, Typography } from '@mui/material';
import { UT as TranslateWithHTML } from '@transifex/react';

const suitability = translations.onboarding.suitability;

const DesiredBusinessUnitForm = () => (
  <OnboardingSuitabilityForm>
    <Stack spacing={2}>
      <Input
        type="checkbox"
        label={
          <>
            <LocalizedTypography variant="body1" fontWeight="bold">
              {suitability.structuredNotes}
            </LocalizedTypography>
            <Typography display="block" variant="caption" color="textSecondary">
              <TranslateWithHTML _str={suitability.offeredThroughHaloSecurities} _inline />
            </Typography>
          </>
        }
        name="firm_details.entity_onboarding_checkbox_securities"
        disableLocalization
      />
      <Input
        type="checkbox"
        label={
          <>
            <LocalizedTypography variant="body1" fontWeight="bold">
              {suitability.separatelyManagedAccounts}
            </LocalizedTypography>
            <Typography display="block" variant="caption" color="textSecondary">
              <TranslateWithHTML _str={suitability.offeredThroughHaloInvestmentServices} _inline />
            </Typography>
          </>
        }
        name="firm_details.entity_onboarding_checkbox_investment_services"
        disableLocalization
      />
    </Stack>
  </OnboardingSuitabilityForm>
);

export const desiredBusinessUnitStep: SuitabilityStepModel = {
  id: '1-8',
  title: suitability.selectDesiredBusinessUnit,
  subtitle: suitability.mostFirmsChooseToOnboardWithBoth,
  component: <DesiredBusinessUnitForm />,
};

import { CSSProperties, ReactElement } from 'react';

import { keyframes } from '@emotion/react';
import { useCombinedStyling } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, SxProps, useTheme } from '@mui/material';

const getAnimation = keyframes`
  from { transform: rotate(0deg); },
  to { transform: rotate(360deg); }
`;

export type LinedCircleProps = {
  disableAnimation?: boolean;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  sx?: SxProps<HaloTheme>;
};

export const LinedCircle = ({
  disableAnimation = false,
  width = 573,
  height = 573,
  sx,
}: LinedCircleProps): ReactElement => {
  const theme = useTheme<HaloTheme>();

  const linedCircleStyling = useCombinedStyling(sx, {
    animation: !disableAnimation ? `${getAnimation} 15s linear infinite` : undefined,
  });

  return (
    <Box sx={linedCircleStyling}>
      <svg width={width} height={height} viewBox="0 0 573 573" fill="none">
        <path
          fill={theme.palette.common.black}
          fillOpacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.000557004 286.195C0.0315018 220.151 22.8927 156.148 64.7111 105.03C106.53 53.9115 164.734 18.8214 229.462 5.70441C294.191 -7.41255 361.464 2.25025 419.884 
        33.0557C478.304 63.8612 524.278 113.915 550.018 174.737C579.574 244.691 580.131 323.522 551.566 393.886C523.001 464.251 467.653 520.386 397.699 549.943C327.745 
        579.5 248.914 580.057 178.55 551.491C108.185 522.926 52.0494 467.578 22.4928 397.624C7.57496 362.373 -0.0751876 324.473 0.000557004 286.195ZM570.473 285C570.285 
        229.179 553.658 174.64 522.655 128.199C491.431 81.4268 447.029 44.9722 395.072 23.452C343.116 1.9317 285.941 -3.6862 230.79 7.30969C175.638 18.3056 124.99 45.4206 
        85.2588 85.2211C52.174 118.272 27.7532 158.974 14.161 203.72C6.1258 230.173 2.02149 257.555 1.89112 285H20.6313C20.9443 224.144 42.1407 165.225 80.6957 
        118.108C119.503 70.682 173.511 38.1286 233.571 25.9621C293.631 13.7955 356.05 22.7637 410.255 51.3475C464.46 79.9313 507.118 126.373 531.003 182.807C544.801 
        215.469 551.786 250.217 551.943 285H570.473ZM481.014 493.383C430.032 541.055 361.543 570.237 286.237 570.237C202.359 570.237 126.939 534.032 74.7485 476.4C103.079 
        507.918 138.137 532.746 177.391 549.004C220.596 566.9 267.549 573.861 314.088 569.27C360.627 564.679 405.315 548.679 444.192 522.687C457.299 513.924 469.609 
        504.116 481.014 493.383ZM549.881 285C549.647 233.275 534.201 182.752 505.454 139.73C476.486 96.3756 435.312 62.5855 387.139 42.6323C338.967 22.6791 285.959 17.459 
        234.82 27.6321C183.68 37.8052 136.706 62.9146 99.8375 99.785C75.3573 124.265 55.9385 153.327 42.6899 185.312C29.5942 216.928 22.7806 250.787 22.6251 
        285H41.228C41.4586 236.956 55.8109 190.029 82.5121 150.066C109.435 109.772 147.702 78.3665 192.475 59.8227C237.248 41.2789 286.514 36.4295 334.043 45.8879C381.572 
        55.3462 425.229 78.6875 459.491 112.959C482.287 135.699 500.377 162.709 512.726 192.445C524.914 221.794 531.275 253.23 531.457 285H549.881ZM529.253 285C529.004 
        237.359 514.757 190.831 488.272 151.208C461.565 111.254 423.613 80.1143 379.212 61.7248C334.812 43.3354 285.956 38.5218 238.82 47.8924C191.684 57.2631 148.385 
        80.3974 114.394 114.371C69.9836 160.04 45.2826 221.313 45.5936 285H61.9001C62.1318 241.042 75.2729 198.111 99.7038 161.545C124.356 124.649 159.396 95.891 200.393 
        78.9097C241.39 61.9285 286.502 57.4862 330.024 66.1447C373.545 74.8031 413.522 96.1735 444.898 127.553C486.69 169.346 510.292 225.929 510.606 285H529.253ZM508.708 
        285C508.474 241.418 495.443 198.855 471.22 162.603C446.776 126.02 412.033 97.507 371.383 80.67C330.734 63.8329 286.005 59.4282 242.852 68.0127C199.699 76.5972 
        160.061 97.7855 128.95 128.898C87.5142 170.334 64.1126 226.434 63.7973 285H82.5351C82.8106 238.293 99.1293 193.085 128.777 156.962C158.677 120.53 200.284 95.5931 
        246.51 86.3995C292.735 77.206 340.718 84.3247 382.282 106.543C423.847 128.761 456.423 164.703 474.458 208.246C484.614 232.767 489.806 258.842 489.96 
        285H508.708ZM488.118 285C487.845 238.717 471.676 193.918 442.299 158.121C412.669 122.016 371.437 97.3012 325.628 88.1886C279.819 79.0759 232.267 86.1291 191.075 
        108.146C149.884 130.163 117.6 165.782 99.7266 208.934C89.6611 233.234 84.516 259.077 84.363 285H103.17C103.443 243.065 118.106 202.48 144.725 170.045C171.596 
        137.301 208.99 114.887 250.535 106.623C292.08 98.359 335.204 104.756 372.561 124.723C409.919 144.69 439.197 176.993 455.407 216.127C464.521 238.13 469.187 261.527 
        469.34 285H488.118ZM467.535 285C467.264 243.477 452.747 203.29 426.391 171.171C399.782 138.744 362.753 116.547 321.612 108.362C280.472 100.176 237.766 106.509 
        200.772 126.282C163.777 146.054 134.783 178.042 118.73 216.796C109.704 238.585 105.083 261.755 104.931 285H123.798C124.077 247.835 137.091 211.874 160.689 
        183.13C184.54 154.077 217.727 134.193 254.595 126.866C291.463 119.54 329.731 125.223 362.878 142.949C396.025 160.675 422 189.346 436.378 224.076C444.436 243.542 
        448.571 264.236 448.727 285H467.535ZM446.944 285C446.671 248.246 433.804 212.683 410.472 184.253C386.887 155.515 354.066 135.844 317.604 128.591C281.141 121.339 
        243.291 126.953 210.504 144.479C177.717 162.004 152.021 190.356 137.794 224.704C129.814 243.968 125.72 264.45 125.567 285H144.433C145.077 207.349 208.447 144.376 
        286.255 144.376C364.063 144.376 427.425 207.349 428.07 285H446.944ZM426.353 285C425.709 208.271 363.112 146.092 286.255 146.092C209.398 146.092 146.794 208.293 
        146.15 285H165.069C165.713 218.725 219.831 165.003 286.255 165.003C352.68 165.003 406.798 218.725 407.442 285H426.353ZM405.807 285C405.163 219.647 351.795 166.675 
        286.293 166.675C220.79 166.675 167.422 219.647 166.778 285H185.705C186.347 230.102 231.207 185.638 286.255 185.638C341.303 185.638 386.163 230.102 386.806 
        285H405.807ZM385.179 285C384.536 231.001 340.404 187.265 286.255 187.265C232.107 187.265 187.975 231.001 187.332 285H206.341C206.571 269.61 211.241 254.604 
        219.802 241.792C228.584 228.649 241.066 218.405 255.67 212.356C270.274 206.307 286.344 204.724 301.847 207.808C317.351 210.892 331.592 218.504 342.769 
        229.681C353.947 240.859 361.559 255.1 364.642 270.603C365.59 275.368 366.097 280.187 366.169 285H385.179ZM364.594 285C364.364 269.922 359.786 255.22 351.399 
        242.668C342.79 229.783 330.554 219.741 316.238 213.811C301.921 207.881 286.168 206.33 270.97 209.353C255.772 212.376 241.812 219.838 230.855 230.795C219.898 
        241.752 212.436 255.712 209.413 270.91C208.485 275.574 207.988 280.29 207.916 285H227.001C227.229 273.69 230.689 262.672 236.983 253.252C243.498 243.501 252.759 
        235.901 263.594 231.413C274.429 226.925 286.351 225.751 297.853 228.039C309.355 230.327 319.921 235.974 328.214 244.267C336.506 252.559 342.154 263.125 344.442 
        274.627C345.125 278.061 345.499 281.532 345.569 285H364.594ZM344.03 285C343.798 273.997 340.426 263.279 334.3 254.116C327.952 244.621 318.932 237.222 308.38 
        232.853C297.827 228.484 286.216 227.342 275.014 229.57C263.813 231.799 253.523 237.299 245.447 245.374C237.37 253.45 231.869 263.738 229.639 274.94C228.976 278.27 
        228.611 281.636 228.54 285H247.613C247.836 277.776 250.082 270.751 254.106 264.729C258.352 258.375 264.386 253.422 271.447 250.498C278.507 247.573 286.276 246.808 
        293.771 248.299C301.266 249.79 308.151 253.47 313.555 258.874C318.959 264.277 322.639 271.162 324.13 278.657C324.547 280.757 324.788 282.879 324.854 
        285H344.03ZM323.367 285C323.144 278.07 320.985 271.332 317.125 265.554C313.042 259.445 307.24 254.683 300.451 251.871C293.662 249.059 286.192 248.323 278.985 
        249.756C271.778 251.19 265.158 254.728 259.962 259.924C254.766 265.12 251.228 271.74 249.794 278.947C249.396 280.951 249.165 282.976 249.1 285H268.084C268.056 
        286.375 268.185 287.744 268.465 289.083C268.31 288.135 268.23 287.169 268.229 286.195C268.229 282.63 269.286 279.145 271.267 276.18C273.247 273.216 276.063 
        270.906 279.357 269.541C282.651 268.177 286.275 267.82 289.772 268.515C293.269 269.211 296.481 270.928 299.002 273.449C301.523 275.97 303.24 279.182 303.935 
        282.679C304.29 284.46 304.371 286.275 304.185 288.062C304.337 287.051 304.403 286.027 304.382 285H323.367ZM302.84 286.195C302.84 282.915 301.867 279.709 300.045 
        276.981C298.223 274.254 295.633 272.128 292.602 270.873C289.571 269.618 286.237 269.289 283.02 269.929C279.802 270.569 276.847 272.149 274.528 274.468C272.208 
        276.788 270.629 279.743 269.989 282.96C269.349 286.177 269.677 289.512 270.933 292.542C272.188 295.573 274.314 298.163 277.041 299.985C279.768 301.808 282.975 
        302.78 286.255 302.78C290.652 302.775 294.867 301.025 297.976 297.916C301.085 294.807 302.834 290.592 302.84 286.195Z"
        />
      </svg>
    </Box>
  );
};

import { useStepWizardContext } from '@halo-common/components/StepWizard';
import type { SuitabilityStepModel } from '@halo-modules/app/v2/onboarding/subPages/suitability';
import { CircularProgress, Stack } from '@mui/material';

const Fallback = () => {
  const { step, setStep } = useStepWizardContext();

  if (step) {
    setStep(null);
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Stack>
  );
};

export const fallbackStep: SuitabilityStepModel = {
  id: 'fallback',
  component: <Fallback />,
};

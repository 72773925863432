import { ChangeEvent, ReactElement, useCallback, useRef, useState } from 'react';

import { UnderlyingAssetsPopper, UnderlyingSelectedAssets } from '@halo-common/components';
import { UnderlyingBasketEnum } from '@halo-common/enums';
import { UnderlyingGlyphWeights } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { ApiUnderlyingModel } from '@halo-data-sources/models';
import { useUnderlyingTradableQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, InputLabel, Stack, SxProps, TextField, Tooltip } from '@mui/material';
import { debounce } from 'lodash';

export const roundWeightedAssets = (newWeightedAssets: Record<number, number>): Record<number, number> => {
  const weightedKeys = Object.keys(newWeightedAssets);
  const numberOfAssets = weightedKeys.length;
  weightedKeys.forEach((key) => {
    newWeightedAssets[Number(key)] = Number((100 / numberOfAssets).toFixed(1));
  });
  if (numberOfAssets === 3) {
    const fractionToRound = newWeightedAssets[Number(weightedKeys[0])];
    newWeightedAssets[Number(weightedKeys[0])] = fractionToRound + 0.1;
  }

  if (numberOfAssets === 6) {
    const fractionToRound = newWeightedAssets[Number(weightedKeys[0])];
    newWeightedAssets[Number(weightedKeys[0])] = Number((fractionToRound - 0.2).toFixed(1));
  }
  if (numberOfAssets === 7) {
    const fractionToRound = newWeightedAssets[Number(weightedKeys[0])];
    newWeightedAssets[Number(weightedKeys[0])] = Number((fractionToRound - 0.1).toFixed(1));
  }
  return newWeightedAssets;
};

interface UnderlyingAssetsDropdownProps {
  sx?: SxProps;
}

export const UnderlyingAssetsDropdown = ({ sx }: UnderlyingAssetsDropdownProps): ReactElement => {
  const [searchString, setSearchString] = useState('');
  const [selectedAssets, setSelectedAssets] = useState<Array<ApiUnderlyingModel>>([]);
  const [open, setOpen] = useState(false);
  const [basketType, setBasketType] = useState<UnderlyingBasketEnum>(UnderlyingBasketEnum.WORST_OF);
  const [weightedAssets, setWeightedAssets] = useState<UnderlyingGlyphWeights>({});

  const dropdownAnchorRef = useRef(null);

  const { data, fetchStatus, refetch } = useUnderlyingTradableQuery(
    { search_string: searchString, page: 1, page_length: 15 },
    false,
  );

  const loadingTradables = fetchStatus === 'fetching';

  const fetchUnderlyings = useCallback(debounce(refetch, 500), []);

  const handleClick = () => (open ? setOpen(false) : setOpen(true));

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = (search: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchString(search.target.value);
    setOpen(true);
    if (search) void fetchUnderlyings();
  };

  const handleDeleteSelectedAsset = (option: ApiUnderlyingModel) => {
    setSelectedAssets(selectedAssets.filter((asset) => asset.id !== option.id));
    const newWeightedAssets = { ...weightedAssets };
    delete newWeightedAssets[option.id];

    roundWeightedAssets(newWeightedAssets);

    setWeightedAssets(newWeightedAssets);
    if (Object.keys(newWeightedAssets).length < 2) setBasketType(UnderlyingBasketEnum.WORST_OF);
  };

  const handleClear = () => {
    setSelectedAssets([]);
    setSearchString('');
    setOpen(false);
    setBasketType(UnderlyingBasketEnum.WORST_OF);
    setWeightedAssets({});
  };

  const handleAssetChange = (option: ApiUnderlyingModel) => {
    if (!selectedAssets.some((asset) => asset.id === option.id)) {
      setSelectedAssets([...selectedAssets, option]);

      const newWeightedAssets = { ...weightedAssets, [option.id]: 0 };
      roundWeightedAssets(newWeightedAssets);

      setWeightedAssets(newWeightedAssets);
      setSearchString('');
    }
    // 1 because we are adding a second asset when this is called
    if (selectedAssets.length === 1) setBasketType(UnderlyingBasketEnum.WORST_OF);
  };

  const handleWeightChange = (assetId: number, weight: number) => {
    setWeightedAssets({ ...weightedAssets, [assetId]: weight });
  };

  const showClear = selectedAssets.length >= 1 || searchString;

  const clearButton = showClear ? (
    <Tooltip arrow placement="top" title="Clear All">
      <Iconography
        iconName="close"
        onClick={handleClear}
        sx={{
          cursor: 'pointer',
        }}
        data-testid="clear-button"
      />
    </Tooltip>
  ) : null;

  const handleBasketToggle = (value: UnderlyingBasketEnum) => {
    if (value) setBasketType(value);
  };

  return (
    <Box sx={sx} ref={dropdownAnchorRef}>
      <Box onClick={handleClick}>
        <InputLabel shrink>
          <LocalizedTypography>{translations.noteCreate.underlyingAssets}</LocalizedTypography>
        </InputLabel>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            border: '1px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            sx={{
              maxHeight: '200px',
              overflow: 'auto',
            }}
          >
            <UnderlyingSelectedAssets
              basketType={basketType}
              weightedAssets={weightedAssets}
              selectedAssets={selectedAssets}
              onDeleteSelectedAsset={handleDeleteSelectedAsset}
            />
            <TextField
              multiline
              size="small"
              placeholder={selectedAssets.length === 0 ? 'Type to search...' : undefined}
              value={searchString}
              onChange={(input) => handleSearch(input)}
              sx={{
                '& fieldset': { border: 'none' },
              }}
            />
          </Stack>
          {clearButton}
        </Stack>
      </Box>
      <UnderlyingAssetsPopper
        handleClose={handleClose}
        anchorRef={dropdownAnchorRef}
        open={open}
        selectedAssets={selectedAssets}
        basketType={basketType}
        onBasketToggle={handleBasketToggle}
        weightedAssets={weightedAssets}
        onWeightChange={handleWeightChange}
        onAssetChange={handleAssetChange}
        loadingTradables={loadingTradables}
        data={data}
      />
    </Box>
  );
};

import { ReactElement } from 'react';

import { userCreationStepAtom } from '@halo-atoms/userCreation';
import { ActionButton } from '@halo-common/components';
import { CreateOrgFormFields, SelectOrgFormFields } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { useCreateOrgMutation } from '@halo-data-sources/mutations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { UseFormHandleSubmit } from 'react-hook-form';

export type CreateBulkUsersModalFooterContinueButtonProps = {
  onReset: () => void;
  onClose: () => void;
  onSubmitOrg: UseFormHandleSubmit<CreateOrgFormFields, undefined>;
  onSelectOrg: UseFormHandleSubmit<SelectOrgFormFields, undefined>;
};

export const CreateBulkUsersModalFooterContinueButton = ({
  onReset,
  onClose,
  onSubmitOrg,
  onSelectOrg,
}: CreateBulkUsersModalFooterContinueButtonProps): ReactElement => {
  const step = useAtomValue(userCreationStepAtom.currentPageAtom);
  const handleNext = useSetAtom(userCreationStepAtom.handleNextAtom);
  const setStep = useSetAtom(userCreationStepAtom.setCurrentPageAtom);

  const { isPending: isOrgCreatePending, mutate: mutateOrgCreation } = useCreateOrgMutation(handleNext);

  const handleOrgSubmit = onSubmitOrg((data: CreateOrgFormFields) => {
    mutateOrgCreation(data);
  });

  const handleOrgSelectNext = onSelectOrg(() => {
    setStep(2);
  });

  const createAnotherIcon = <Iconography iconName="user-plus" color="primary.main" />;

  const orgCreateOrSelectContinueButton = (
    <LocalizedButton variant="contained" color="primary" onClick={handleOrgSelectNext} size="large">
      {translations.userAndOrgCreation.nextCreateUsers}
    </LocalizedButton>
  );

  const orgCreateButton = (
    <ActionButton
      variant="contained"
      color="primary"
      onClick={handleOrgSubmit}
      size="large"
      loading={isOrgCreatePending}
    >
      {translations.userAndOrgCreation.nextCreateUsers}
    </ActionButton>
  );

  const createBulkUsersFormContinueButton = (
    <ActionButton id="user-create-button" variant="contained" color="primary" onClick={handleNext} size="large">
      {translations.userAndOrgCreation.createUsers}
    </ActionButton>
  );

  const createBulkUsersSuccessContinueButton = (
    <Stack direction="row" spacing={2}>
      <LocalizedButton variant="outlined" color="primary" onClick={onReset} startIcon={createAnotherIcon} size="large">
        {translations.common.createAnother}
      </LocalizedButton>
      <LocalizedButton variant="contained" color="primary" onClick={onClose} size="large">
        {translations.common.done}
      </LocalizedButton>
    </Stack>
  );

  const continueButtons = [
    orgCreateOrSelectContinueButton,
    orgCreateButton,
    createBulkUsersFormContinueButton,
    createBulkUsersSuccessContinueButton,
  ];

  return continueButtons[step];
};

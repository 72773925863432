import { ReactElement } from 'react';

import { orderBookQueryAtom } from '@halo-atoms/orderbook';
import { GridPaginationModel } from '@halo-common/models';
import { useOrderBookJanneyQuery } from '@halo-data-sources/queries';
import { useOrderBookJanneyTableColumns } from '@halo-modules/app';
import { HaloDataGrid } from '@halodomination/halo-fe-common';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { useAtom } from 'jotai';

export const OrderBookJanneyTable = (): ReactElement => {
  const [searchFields, setSearchFields] = useAtom(orderBookQueryAtom);

  const { data, isFetching } = useOrderBookJanneyQuery(searchFields);

  const rows = data?.details ?? [];
  const page = data?.pagination?.page ?? searchFields.page;
  const pageSize = data?.pagination?.resultsPerPage ?? searchFields.pageSize;
  const totalResults = data?.pagination?.totalResults ?? 0;
  const paginationModel = { page: page - 1, pageSize };

  // TODO: Uncomment when https://halodomination.atlassian.net/browse/HADT-19446 is done
  // const footerRow: GridPinnedRowsProp = {
  //   bottom: [
  //     {
  //       id: 'footer',
  //       calendar: undefined,
  //       allocation: { amount: totalPrincipal, account: undefined, user: undefined },
  //       transactionSummary: { salesCredit: totalCommission },
  //     },
  //   ],
  // };

  const handlePaginationChange = (model: GridPaginationModel) => {
    if (!isFetching) {
      setSearchFields({
        page: model.page + 1,
        pageSize: model.pageSize,
      });
    }
  };

  const handleSortChange = (model: GridSortModel) => {
    setSearchFields({ sortBy: model });
  };

  const columns = useOrderBookJanneyTableColumns();

  return (
    <HaloDataGrid
      columns={columns}
      loading={isFetching}
      rows={rows}
      pagination
      // TODO: Uncomment when https://halodomination.atlassian.net/browse/HADT-19446 is done
      // pinnedRows={footerRow}
      height="fill"
      paginationMode="server"
      sortingMode="server"
      density="compact"
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationChange}
      onSortModelChange={handleSortChange}
      rowCount={totalResults}
      disableRowSelectionOnClick
    />
  );
};

import { ReactElement, useEffect } from 'react';

import { AddAccountModalFormFields, AddAccountSimpleFooter, AddAccountSimpleForm } from '@halo-common/modals';
import { AccountModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import {
  useAddAccountToExistingHouseholdMutation,
  useAddAccountToNewHouseholdMutation,
} from '@halo-data-sources/mutations';
import { useHouseholdQuery } from '@halo-data-sources/queries';
import { Modal, ModalProps } from '@halodomination/halo-fe-common';
import { useFormContext } from 'react-hook-form';

export type AddAccountSimpleVariantProps = {
  ModalProps?: Pick<ModalProps, 'title' | 'subtitle'>;
  onSuccess?: (account: AccountModel) => void;
  onClose: () => void;
  open: boolean;
};

export const AddAccountSimpleVariant = ({
  ModalProps,
  onSuccess,
  onClose,
  open,
}: AddAccountSimpleVariantProps): ReactElement => {
  const { handleSubmit, setError } = useFormContext<AddAccountModalFormFields>();

  const { data: households = [] } = useHouseholdQuery();

  const addAccountToExistingHousehold = useAddAccountToExistingHouseholdMutation();
  const addAccountToNewHousehold = useAddAccountToNewHouseholdMutation();

  const loading = addAccountToExistingHousehold.isPending || addAccountToNewHousehold.isPending;
  const error = addAccountToExistingHousehold.error ?? addAccountToNewHousehold.error;
  const data = addAccountToExistingHousehold.data ?? addAccountToNewHousehold.data?.account;

  const handleAccountModalClose = () => onClose?.();

  const handleCreate = handleSubmit((data: AddAccountModalFormFields) => {
    const { accountHousehold, accountNumber, accountName, custodian } = data;
    const { id: custodianId } = custodian;

    const isExistingHousehold = !accountHousehold || households.some(({ name }) => accountHousehold === name);

    if (isExistingHousehold) {
      const household = households.find((household) => data.accountHousehold === household.name);
      const adviseeId = data.accountHousehold && household ? household.id : undefined;
      const action = { ownerId: adviseeId, accountName, accountNumber, custodianId };
      addAccountToExistingHousehold.mutate(action);
    } else {
      const action = { accountName, accountNumber, custodianId, householdName: accountHousehold };
      addAccountToNewHousehold.mutate(action);
    }
  });

  useEffect(() => {
    if (error?.status === 409) {
      const message = translations.portfolio.addAccountModal.formDuplicateError;
      setError('accountNumber', { message });
      setError('custodian', { message });
    }
  }, [error]);

  useEffect(() => {
    const success = addAccountToExistingHousehold.isSuccess || addAccountToNewHousehold.isSuccess;
    const successfullyCreatedAccount = onSuccess && data && success;
    if (successfullyCreatedAccount) onSuccess(data);
  }, [addAccountToExistingHousehold.isSuccess, addAccountToNewHousehold.isSuccess]);

  return (
    <Modal
      {...ModalProps}
      title={translations.portfolio.addAccountModal.simpleFormTitle}
      footer={<AddAccountSimpleFooter onSubmit={handleCreate} onClose={onClose} loading={loading} />}
      open={open}
      onClose={handleAccountModalClose}
      size="small"
    >
      <AddAccountSimpleForm />
    </Modal>
  );
};

export enum SMAAllocationStatusEnum {
  submitted = 'SUBMITTED',
  pending = 'PENDING_APPROVAL',
  rejected = 'REJECTED',
  approved = 'APPROVED',
  canceled = 'CANCELED',
}

export enum SMADisclaimerFields {
  DateDay = 'date-day',
  DateMonth = 'date-month',
  DateYear = 'date-year',
  AdvisorEntity = 'advisor-entity',
  CorporationTypeRadio = 'corporation-type-radio',
  AdvisorName = 'advisor-name',
  AdvisorSignature = 'advisor-signature',
  SignatureDate = 'signature-date',
  AdvisorTitle = 'advisor-title',
}

export enum SMAStatusEnum {
  Idle = 'idle',
  Requesting = 'requesting',
  Pending = 'pending',
  RequestingFetchStrategies = 'requestingFetchStrategies',
  PendingFetchStrategies = 'pendingFetchStrategies',
  FailureFetchStrategies = 'failureFetchStrategies',
  SuccessFetchStrategies = 'successFetchStrategies',
  RequestingGetDisclaimers = 'requestingGetDisclaimers',
  PendingGetDisclaimers = 'pendingGetDisclaimers',
  FailureGetDisclaimers = 'failureGetDisclaimers',
  SuccessGetDisclaimers = 'successGetDisclaimers',
  RequestingPutDisclaimer = 'requestingPutDisclaimer',
  PendingPutDisclaimer = 'pendingPutDisclaimer',
  FailurePutDisclaimer = 'failurePutDisclaimer',
  SuccessPutDisclaimer = 'successPutDisclaimer',
  RequestingCreateRequest = 'requestingCreateRequest',
  PendingCreateRequest = 'pendingCreateRequest',
  FailureCreateRequest = 'failureCreateRequest',
  SuccessCreateRequest = 'succesCreateRequest',
}

import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';

export type ProductFeaturesFiltersProps = {
  tag: string;
};

export const ProductFeaturesFilters = ({ tag }: ProductFeaturesFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleCheckboxChange = (value: string) => {
    setSideBarFilters({ tag, checkbox: value });
  };

  const callabilityCheckboxes = [
    {
      isChecked: sideBarFilters.checkboxes.autocallable,
      value: 'autocallable',
      name: translations.common.autoCallable,
    },
    {
      isChecked: sideBarFilters.checkboxes.issuerCallable,
      value: 'issuerCallable',
      name: translations.common.issuerCallable,
    },
    {
      isChecked: sideBarFilters.checkboxes.notCallable,
      value: 'notCallable',
      name: translations.common.notCallable,
    },
  ];

  const couponTypeCheckboxes = [
    {
      isChecked: sideBarFilters.checkboxes.fixed,
      value: 'fixed',
      name: translations.common.fixed,
    },
    {
      isChecked: sideBarFilters.checkboxes.contingent,
      value: 'contingent',
      name: translations.common.contingent,
    },
    {
      isChecked: sideBarFilters.checkboxes.memory,
      value: 'memory',
      name: translations.common.memory,
    },
  ];

  const upsideLeverageCheckboxes = [
    {
      isChecked: sideBarFilters.checkboxes.capped,
      value: 'capped',
      name: translations.common.capped,
    },
    {
      isChecked: sideBarFilters.checkboxes.uncapped,
      value: 'uncapped',
      name: translations.common.uncapped,
    },
  ];

  return (
    <CollapsibleFilters
      title={DynamicTypeFiltersSideBarTitlesDict.productFeatures}
      walkMeClassName="wm-productshelf-filters-productfeatures"
    >
      <Stack direction="column" spacing={2}>
        <GroupedCheckbox
          onChange={handleCheckboxChange}
          title={translations.dynamicFilters.common.callabilityFilter}
          values={callabilityCheckboxes}
          groupedCheckboxType="productFeature"
        />
        <GroupedCheckbox
          onChange={handleCheckboxChange}
          title={translations.dynamicFilters.common.couponTypeFilter}
          values={couponTypeCheckboxes}
          groupedCheckboxType="productFeature"
        />
        <GroupedCheckbox
          onChange={handleCheckboxChange}
          title={translations.dynamicFilters.common.upsideLeverageFilter}
          values={upsideLeverageCheckboxes}
          groupedCheckboxType="productFeature"
        />
      </Stack>
    </CollapsibleFilters>
  );
};

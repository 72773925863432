import { DEFAULT_INFINITE_QUERY_NEXT_PAGINATION } from '@halo-common/constants';
import { SortModelDirectionEnum, SortModelNullSortEnum } from '@halo-common/enums';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { InfinitePaginationModel, InfiniteQueryOptions, OrganizationModel, UserRoleModel } from '@halo-common/models';
import { getRoles } from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { PaginationMapper } from '@halo-data-sources/mappers';
import { RolesClientQueryKeyFactory } from '@halo-data-sources/queries';

export type GetRolesInfiniteQueryPayload = {
  query?: string;
  organization?: OrganizationModel;
};

export type GetRolesNextPageParamModel = {
  pagination: InfinitePaginationModel;
};

export type GetRolesInfiniteResult = {
  roles: Array<UserRoleModel>;
  pagination: InfinitePaginationModel;
};

const rolesInfiniteQueryFn = async (
  payload: GetRolesInfiniteQueryPayload,
  pageParam = DEFAULT_INFINITE_QUERY_NEXT_PAGINATION,
) => {
  const request = {
    comparisons: [
      {
        field: 'roles.name',
        op: ApiComparisonOptionEnum.ILIKE,
        value: payload.query ?? '',
      },
      {
        field: 'roles.is_prohibited',
        op: ApiComparisonOptionEnum.EQ,
        value: false,
      },
    ],
    page: pageParam.next,
    page_length: 20,
    sort: [
      {
        field: 'roles.name',
        direction: SortModelDirectionEnum.asc,
        nulls: SortModelNullSortEnum.last,
      },
    ],
  };

  const response = await getRoles(request);

  const roles = response.roles;
  const mappedPagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { roles, pagination: mappedPagination };
};

export const useRolesInfiniteQuery = (
  payload: GetRolesInfiniteQueryPayload,
  options?: Partial<InfiniteQueryOptions<GetRolesInfiniteResult>>,
) =>
  useHaloInfiniteQuery<GetRolesInfiniteResult>({
    queryKey: RolesClientQueryKeyFactory.infinite(payload),
    queryFn: ({ pageParam }) => rolesInfiniteQueryFn(payload, pageParam),
    ...options,
  });

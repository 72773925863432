import { useMoneyness } from '@halo-common/hooks';
import { V2NoteModel } from '@halo-common/models';
import { capitalize } from '@mui/material';

export type V2ProtectionDetails = {
  text?: string;
  label?: string;
  value?: string;
};

export const useV2ProtectionDetails = (product?: V2NoteModel | null): V2ProtectionDetails | undefined => {
  const {
    formatters: { getProtectionText },
    convertNMEProtectionToNoteProtectionTypeEnum: nmeProtectionlabel,
  } = useMoneyness();

  const protectionLabel = nmeProtectionlabel(product?.protection?.label);

  if (!product) return undefined;

  const protection = product.protection;
  const percentage = protection?.value ? parseFloat(protection.value) : '';
  const type = protection?.label ? capitalize(protection.label) : '';

  const hasProtection = typeof percentage === 'number';
  if (!hasProtection) return undefined;

  const text = getProtectionText(percentage, protectionLabel);

  return { text, value: `${percentage}%`, label: type };
};

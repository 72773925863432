import { ReactElement } from 'react';

import { userCreationOrganizationAtom } from '@halo-atoms/userCreation';
import { translations } from '@halo-common/translations';
import {
  GridRenderCellParams,
  HaloDataGrid,
  HaloDataGridProps,
  LocalizedAlert,
  LocalizedTypography,
} from '@halodomination/halo-fe-common';
import { Chip, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

const chipSx = {
  backgroundColor: 'primary.background',
  borderRadius: 2,
  fontSize: 12,
  color: 'primary.main',
};

const chipStackSx = {
  height: '100%',
};

type UserModel = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  roles: Array<string>;
};

export const CreateBulkUsersSuccess = (): ReactElement => {
  const selectedOrg = useAtomValue(userCreationOrganizationAtom);

  const relationshipManager = selectedOrg?.relationshipManager;
  const relationshipManagerName = relationshipManager?.name;

  const orgName = selectedOrg?.name;

  const alert = (
    <LocalizedAlert severity="success" variant="filled">
      {translations.userAndOrgCreation.bulkActivationEmailSent}
    </LocalizedAlert>
  );

  const columns: HaloDataGridProps<UserModel>['columns'] = [
    {
      field: 'email',
      headerName: translations.common.email,
      flex: 1,
    },
    {
      field: 'firstName',
      headerName: translations.common.firstName,
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: translations.common.lastName,
      flex: 1,
    },
    {
      field: 'roles',
      headerName: translations.common.roles,
      flex: 1,
      renderCell: ({ value }: GridRenderCellParams<UserModel>) => {
        const chips = value.map((label: string) => {
          return <Chip key={`role-chip-${label}`} sx={chipSx} label={label} size="small" />;
        });

        return (
          <Stack sx={chipStackSx} direction="row" alignItems="center" spacing={1}>
            {chips}
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack direction="column" spacing={2}>
      {alert}
      <Stack direction="row" spacing={2}>
        <Stack direction="column" spacing={1}>
          <LocalizedTypography variant="caption" color="text.secondary">
            {translations.common.organization}
          </LocalizedTypography>
          <Typography variant="subtitle1" color="text.primary">
            {orgName}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={1}>
          <LocalizedTypography variant="caption" color="text.secondary">
            {translations.common.relationshipManager}
          </LocalizedTypography>
          <Typography variant="subtitle1" color="text.primary">
            {relationshipManagerName}
          </Typography>
        </Stack>
      </Stack>
      <HaloDataGrid<UserModel> density="compact" columns={columns} disableColumnSorting />
    </Stack>
  );
};

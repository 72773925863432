import { ReactElement, useEffect, useState } from 'react';

import { LexicalTypography } from '@halo-common/components';
import { useMoneyness, useNoteSpecificDetails } from '@halo-common/hooks';
import { OrdersSelectors } from '@halo-stores/Orders';
import { HaloDataGrid, mapNumberToLocalCurrency } from '@halodomination/halo-fe-common';
import {
  GridAlignment,
  GridCellEditStopParams,
  GridRenderCellParams,
  GridRowId,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';

import { OpsApprovalModalTableFooter } from './OpsApprovalModalTableFooter';
import type { OpsApprovalFormTableRow } from './types';

const COMMON_COL_DEFINITIONS = {
  minWidth: 200,
  flex: 1,
  editable: true,
  align: 'left' as GridAlignment,
};

export type OpsApprovalModalTableProps = {
  onUpdate: (rows: Array<OpsApprovalFormTableRow>) => void;
};

export const OpsApprovalModalTable = ({ onUpdate }: OpsApprovalModalTableProps): ReactElement => {
  const [tableRows, setTableRows] = useState<Array<OpsApprovalFormTableRow>>([]);
  const [rowsToDelete, setRowsToDelete] = useState<Array<GridRowId>>([]);
  const { calculateProtection } = useMoneyness();

  const selectedAuction = useSelector(OrdersSelectors.selectSelectedAuction);

  const details = useNoteSpecificDetails(selectedAuction?.note);

  const protectionPercent = calculateProtection(
    selectedAuction?.note?.protectionPercent,
    selectedAuction?.note?.protectionType,
  );

  const handleEditCell = (params: GridCellEditStopParams) => {
    const { field, value, id } = params;

    const copy = [...tableRows];
    const index = copy.findIndex((row) => row.id === id);

    copy.splice(index, 1, { ...copy[index], [field]: value });

    setTableRows(copy);
    onUpdate(copy);
  };

  const handleAddRow = () => {
    const updatedRows = [{ id: Math.random().toString(), name: '', value: '' }, ...tableRows];
    setTableRows(updatedRows);
    onUpdate(updatedRows);
  };

  const handleDeleteRow = () => {
    const updatedRows = tableRows.filter((row) => !rowsToDelete.includes(row.id));
    setTableRows(updatedRows);
    onUpdate(updatedRows);
  };

  const handleSelect = (selection: GridRowSelectionModel) => {
    const values = selection.values();
    const rows = Array.from(values);

    setRowsToDelete(rows);
  };

  useEffect(() => {
    if (selectedAuction) {
      const {
        issuers,
        note,
        strikeStyle,
        targetStrikeDate,
        targetTradeDate,
        targetSettleDate,
        price,
        notional,
        details: additionalDetails,
      } = selectedAuction;
      const {
        callInformation,
        type,
        termInMonths,
        tradables,
        tradableWeights,
        protectionType,
        features,
        currencyCode,
        currencySymbol,
      } = note;

      const parsedStrikeStyle = capitalize(strikeStyle);
      const parsedProtectionType = capitalize(protectionType);
      const protectionText = `${protectionPercent}% ${parsedProtectionType} Protection`;

      const specifics = details.highlights.map(({ label, text }) => {
        if (label === 'Max Return')
          return { id: label, name: 'Minimum Max Return', value: text, translationKey: 'Max Return' };
        else if (label === 'Annualized Yield')
          return { id: label, name: 'Minimum Annual Coupon', value: text, translationKey: 'Annual Coupon' };
        else return { id: label, name: label, value: text };
      });

      const currencyOptions = { currency: currencyCode };

      const rows = [
        { id: 'issuer count', name: 'Issuer Count', value: issuers.length ?? 0 },
        { id: 'structure', name: 'Structure', value: type, translationKey: type },
        {
          id: 'notional',
          name: 'Notional',
          value: mapNumberToLocalCurrency(notional, currencyOptions, currencySymbol),
        },
        { id: 'term', name: 'Term', value: termInMonths ? `${termInMonths} Months` : '' },
        {
          id: 'underlyings',
          name: 'Underlyings',
          value: tradables.map(({ name }) => name).join(' / '),
        },
        { id: 'price', name: 'Price', value: mapNumberToLocalCurrency(price, currencyOptions, currencySymbol) },
        { id: 'protection', name: 'Protection', value: protectionText, translationKey: parsedProtectionType },
        { id: 'strike style', name: 'Strike Style', value: parsedStrikeStyle, translationKey: parsedStrikeStyle },
        ...specifics,
        { id: 'strike date', name: 'Strike Date', value: targetStrikeDate ?? '' },
        { id: 'trade date', name: 'Trade Date', value: targetTradeDate ?? '' },
        { id: 'settlement date', name: 'Settlement Date', value: targetSettleDate ?? '' },
      ];

      if (callInformation) {
        rows.push({
          id: 'callinfo',
          name: 'Autocall Level',
          value: `${callInformation.level}% ${capitalize(callInformation.frequency)}`,
        });
      }
      if (tradableWeights) {
        rows.push({
          id: 'weights',
          name: 'Weights',
          value: tradables
            .map(({ name }) => `${name}${tradableWeights ? `: ${tradableWeights[name]}%` : ''}`)
            .join(', '),
        });
      }
      if (features) {
        const featureText = features
          .filter(({ description }) => description)
          .map(({ description }) => description)
          .join(' • ');

        if (featureText)
          rows.push({
            id: 'features',
            name: 'Features',
            value: featureText,
          });
      }

      const updatedRows = [
        ...rows,
        { id: 'additional details', name: 'Additional Details', value: additionalDetails ?? '' },
      ];

      setTableRows(updatedRows);
      onUpdate(updatedRows);
    }
  }, [selectedAuction?.id]);

  const slots = {
    footer: () => (
      <OpsApprovalModalTableFooter
        onAdd={handleAddRow}
        onDelete={handleDeleteRow}
        rows={tableRows}
        rowsToDelete={rowsToDelete}
      />
    ),
  };

  const columns = [
    {
      ...COMMON_COL_DEFINITIONS,
      headerName: 'Field Name',
      field: 'name',
      renderCell: ({ value }: GridRenderCellParams) => (
        <LexicalTypography variant="body2">{value as string}</LexicalTypography>
      ),
    },
    {
      ...COMMON_COL_DEFINITIONS,
      headerName: 'Field Value',
      field: 'value',
      renderCell: ({ value, row }: GridRenderCellParams) => {
        const translationKey = row.translationKey as string;

        return (
          <LexicalTypography translationKey={translationKey} variant="body2">
            {value as string}
          </LexicalTypography>
        );
      },
    },
  ];

  return (
    <div style={{ backgroundColor: 'white', height: 450 }}>
      <HaloDataGrid
        rows={tableRows}
        columns={columns}
        slots={slots}
        disableRowSelectionOnClick
        onRowSelectionModelChange={handleSelect}
        checkboxSelection
        onCellEditStop={handleEditCell}
      />
    </div>
  );
};

import { ReactElement, useEffect } from 'react';

import { DEFAULT_LOCALE } from '@halo-common/constants';
import { RepSettingArrayField, RepSettingInputFieldSchema } from '@halo-common/formComponents';
import { registerFormSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import { OnboardingSurveyEnum } from '@halo-data-sources/enums';
import { useRegisterUserMutation } from '@halo-data-sources/mutations';
import { useUserInfoQuery } from '@halo-data-sources/queries';
import { CountrySelect, RegionSelect } from '@halo-modules/app/v2/onboarding/components/RegisterForm';
import { ForgotPasswordPageMeta } from '@halo-pages/app/v2/onboarding/forgot-password';
import { LoginPageMeta } from '@halo-pages/app/v2/onboarding/login';
import {
  LocalizedAlert,
  LocalizedButton,
  LocalizedInputLabel,
  LocalizedMenuItem,
  LocalizedTextField,
  LocalizedTypography,
} from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, FormControl, Paper, Select, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useT } from '@transifex/react';
import { useRouter } from 'next/router';
import { FormProvider, useController, useForm } from 'react-hook-form';

const loginButtonSx = { mb: 0.5 };
const formContainerSx = { maxWidth: 450, width: '100%', padding: 3 };
const buttonSx = { color: 'info.dark', pl: 0, textDecoration: 'underline' };
const alertSx = { alignItems: 'flex-start', mb: 2 };
const surveyLabelSx = { pt: 0.5 };

export const onboardingSurveyOptions = [
  {
    text: translations.onboarding.register.surveyGoogle,
    value: OnboardingSurveyEnum.Google,
  },
  {
    text: translations.onboarding.register.surveyOnline,
    value: OnboardingSurveyEnum.Online,
  },
  {
    text: translations.onboarding.register.surveyWebinar,
    value: OnboardingSurveyEnum.Webinar,
  },
  {
    text: translations.onboarding.register.surveyInPerson,
    value: OnboardingSurveyEnum.InPerson,
  },
  {
    text: translations.common.email,
    value: OnboardingSurveyEnum.Email,
  },
  {
    text: translations.onboarding.register.surveySocialMedia,
    value: OnboardingSurveyEnum.SocialMedia,
  },
  {
    text: translations.onboarding.register.surveyReferral,
    value: OnboardingSurveyEnum.Referral,
  },
  {
    text: translations.onboarding.register.surveyDemo,
    value: OnboardingSurveyEnum.Demo,
  },
  {
    text: translations.onboarding.register.surveyOther,
    value: OnboardingSurveyEnum.Other,
  },
];

export type RegisterFormSchema = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  companyName: string;
  repSettingArrayField?: RepSettingInputFieldSchema['repSettingArrayField'];
  country?: number;
  role: string;
  region?: number;
  surveyAnswer?: OnboardingSurveyEnum;
};

export const RegisterForm = (): ReactElement => {
  const theme = useTheme();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const translator = useT();

  const { data: user } = useUserInfoQuery();

  const urlLocale = (router.query.locale ?? DEFAULT_LOCALE) as string;

  const requireCompanyName = user?.settings?.onboarding?.requireCompanyName;
  const countries = user?.geographicSettingSet?.countries ?? [];

  const repSettingInfo = user?.whiteLabel?.repIdSettingSet;
  const repSettingSet = repSettingInfo?.repIdSettingSet?.filter((setting) => setting.rules.register.display);

  const displaySurveyQuestion = user?.settings?.onboarding?.displaySurveyQuestion;
  const hasCountries = countries.length > 1;
  const defaultRegions = countries?.[0]?.regions;
  const defaultRegion = defaultRegions?.[0]?.id;

  const formMethods = useForm<RegisterFormSchema>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(registerFormSchema(countries, repSettingSet, requireCompanyName)),
    resetOptions: { keepDirtyValues: true },
    defaultValues: {
      emailAddress: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      role: '',
      country: undefined,
      companyName: '',
      region: defaultRegion,
      surveyAnswer: undefined,
    },
  });

  const {
    control,
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    watch,
    formState: { isSubmitting, errors },
  } = formMethods;

  const country = watch('country');

  const {
    field: { ref: surveyAnswerRef, ...surveyAnswerFieldProps },
  } = useController({ control, name: 'surveyAnswer' });

  const { ref: firstNameRef, ...firstNameProps } = register('firstName');
  const { ref: lastNameRef, ...lastNameProps } = register('lastName');
  const { ref: emailAddressRef, ...emailAddressProps } = register('emailAddress');
  const { ref: phoneNumberRef, ...phoneNumberProps } = register('phoneNumber');
  const { ref: roleRef, ...roleProps } = register('role');
  const { ref: companyNameRef, ...companyNameProps } = register('companyName');

  const {
    mutate: registerUser,
    isError,
    error: registrationError,
  } = useRegisterUserMutation({
    onError: (error: Error) => {
      if (error.message.includes('Email')) {
        setError('emailAddress', { message: translations.onboarding.register.emailExists });
      }
    },
  });

  const submitHandler = (data: RegisterFormSchema) => {
    registerUser({ ...data, locale: urlLocale });
  };

  const loginHandler = () => window.location.assign(`/react${LoginPageMeta.route}${window.location.search}`);
  const resetPassword = () => window.location.assign(`/react${ForgotPasswordPageMeta.route}${window.location.search}`);

  const errorMessage = isError ? (
    <LocalizedAlert sx={alertSx} severity="error" variant="filled">
      <Stack direction="column">
        <LocalizedTypography variant="body2">{translator(registrationError?.message)}</LocalizedTypography>
        <Stack direction="row">
          <LocalizedButton onClick={loginHandler} size="small" sx={buttonSx}>
            {translations.onboarding.login.login}
          </LocalizedButton>
          <LocalizedButton onClick={resetPassword} size="small" sx={buttonSx}>
            {translations.onboarding.register.resetPassword}
          </LocalizedButton>
        </Stack>
      </Stack>
    </LocalizedAlert>
  ) : null;

  const companyNameTextField = requireCompanyName ? (
    <LocalizedTextField
      {...companyNameProps}
      inputRef={companyNameRef}
      size="large"
      fullWidth
      label={translations.onboarding.register.companyName}
      error={Boolean(errors?.companyName)}
      helperText={errors?.companyName?.message}
      slotProps={{ input: { autoComplete: 'off' } }}
    />
  ) : null;

  const repSettings = repSettingSet?.length ? (
    <Stack spacing={2} direction="column">
      {repSettingSet.map((setting, index) => (
        <RepSettingArrayField
          key={setting.id}
          fieldIndex={index}
          allowMultiple={setting.allowMultiple}
          id={setting.id}
          required={setting.required}
          inputLabel={setting.displayName}
          minLength={setting.minCharacters}
          maxLength={setting.maxCharacters}
        />
      ))}
    </Stack>
  ) : null;

  const countrySelect = hasCountries ? <CountrySelect /> : null;
  const regionSelect = hasCountries && country ? <RegionSelect country={country} /> : null;

  const surveyMenuItems = onboardingSurveyOptions.map((option) => (
    <LocalizedMenuItem key={option.value} value={option.value}>
      {option.text}
    </LocalizedMenuItem>
  ));

  const surveyField = displaySurveyQuestion ? (
    <FormControl variant="outlined" fullWidth>
      <LocalizedInputLabel sx={surveyLabelSx} id="onboarding-survey-label">
        {translations.onboarding.register.surveyQuestion}
      </LocalizedInputLabel>
      <Select
        {...surveyAnswerFieldProps}
        inputRef={surveyAnswerRef}
        size="large"
        fullWidth
        labelId="onboarding-survey-label"
        id="onboarding-survey-select"
        label={translations.onboarding.register.surveyQuestion}
      >
        {surveyMenuItems}
      </Select>
    </FormControl>
  ) : null;

  useEffect(() => {
    const countryValue = countries.length === 1 ? countries[0].id : undefined;

    if (countryValue) setValue('country', countryValue);

    if (repSettingInfo && repSettingSet?.length) {
      repSettingSet.forEach((settingSet, fieldIndex) => {
        const fieldId: `repSettingArrayField.${number}` = `repSettingArrayField.${fieldIndex}`;
        const currentValue = getValues(fieldId);
        if (!currentValue?.length) setValue(fieldId, [{ repSettingId: settingSet.id, value: '' }]);
      });
    }
  }, [countries, repSettingSet]);

  return (
    <Paper variant="outlined" sx={formContainerSx}>
      {errorMessage}
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Stack direction="column" spacing={3}>
            <Stack direction="row" spacing={1}>
              <LocalizedTextField
                {...firstNameProps}
                inputRef={firstNameRef}
                size="large"
                fullWidth
                label={translations.common.firstName}
                error={Boolean(errors?.firstName)}
                helperText={errors?.firstName?.message}
                slotProps={{ input: { autoComplete: 'off' } }}
              />

              <LocalizedTextField
                {...lastNameProps}
                inputRef={lastNameRef}
                size="large"
                fullWidth
                label={translations.common.lastName}
                error={Boolean(errors?.lastName)}
                helperText={errors?.lastName?.message}
                slotProps={{ input: { autoComplete: 'off' } }}
              />
            </Stack>
            <LocalizedTextField
              {...emailAddressProps}
              inputRef={emailAddressRef}
              label={translations.common.emailAddress}
              size="large"
              fullWidth
              error={Boolean(errors?.emailAddress)}
              helperText={errors?.emailAddress?.message}
              slotProps={{ input: { autoComplete: 'off' } }}
            />
            <LocalizedTextField
              {...phoneNumberProps}
              inputRef={phoneNumberRef}
              size="large"
              fullWidth
              label={translations.onboarding.register.phoneNumber}
              type="tel"
              error={Boolean(errors?.phoneNumber)}
              helperText={errors?.phoneNumber?.message}
              slotProps={{ input: { autoComplete: 'off' } }}
            />
            {companyNameTextField}
            {countrySelect}
            {regionSelect}
            {repSettings}
            <LocalizedTextField
              {...roleProps}
              inputRef={roleRef}
              size="large"
              fullWidth
              label={translations.onboarding.register.role}
              slotProps={{ input: { autoComplete: 'off' } }}
            />
            {surveyField}
            <LocalizedButton
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : undefined}
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
            >
              {translations.common.continue}
            </LocalizedButton>
            <Stack direction="row" spacing={2} alignItems="center">
              <LocalizedTypography variant="body2">
                {translations.onboarding.register.alreadyHaveAccount}
              </LocalizedTypography>
              <LocalizedButton
                sx={loginButtonSx}
                disabled={isSubmitting}
                type="button"
                color="primary"
                variant="outlined"
                fullWidth={isMobile}
                onClick={loginHandler}
              >
                {translations.onboarding.register.login}
              </LocalizedButton>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Paper>
  );
};

import { translations } from '@halo-common/translations';
import { number as yupNumber, object as yupObject, string as yupString } from 'yup';

export const addAuraNoteSchema = yupObject().shape({
  category: yupString().required(translations.aura.common.categoryRequired),
  allocation: yupNumber()
    .min(0, translations.aura.common.increaseAllocation)
    .max(100, translations.aura.common.decreaseAllocation)
    .required(translations.aura.common.allocationIsRequired),
  quote: yupNumber()
    .defined()
    .nullable()
    .transform((value) => (isNaN(value) ? undefined : value)),
});

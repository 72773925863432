import { DateTime } from 'luxon';

export type CsvCreatorColumnBase<T> = { name: string; mapper?: (data: T) => unknown; field?: keyof T };

export type useCsvCreatorOptions<T> = {
  fileName: string;
  data?: Array<T>;
  appendDate?: boolean;
  columns: Array<CsvCreatorColumnBase<T>>;
};

export type useCsvCreatorResult = {
  exportCsv: () => void;
};

export function useCsvCreator<T extends unknown>(options: useCsvCreatorOptions<T>): useCsvCreatorResult {
  const exportCsv = () => {
    const { fileName, data = [], columns, appendDate = false } = options;

    const rows = data.map((row) =>
      columns.map((column) => {
        if (column?.mapper) return column.mapper(row);
        else if (column?.field) return row[column.field];
        else return '';
      }),
    );

    const columnNames = columns.map((column) => column.name).join(',');
    const csvData = [columnNames, ...rows].join('\n');

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvData}`);
    const link = document.createElement('a');
    const downloadName = appendDate ? `${fileName}_${DateTime.now().toFormat('dd_mm_yyyy')}.csv` : fileName;

    link.href = encodedUri;
    link.download = downloadName;
    link.click();
  };

  return {
    exportCsv,
  };
}

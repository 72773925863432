import { executionHubQueryAtom } from '@halo-atoms/executionHub';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { AccountDesignationEnum, ExecutionHubJanneyStatusEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import {
  GridColDef,
  HaloDataGridProps,
  mapLocalCurrencyToNumber,
  mapNumberToLocalCurrency,
} from '@halodomination/halo-fe-common';
import { useAtomValue } from 'jotai';

export const useEHOrderColumns = (columns: HaloDataGridProps['columns'] = []): HaloDataGridProps['columns'] => {
  const executionHubQuery = useAtomValue(executionHubQueryAtom);

  const configureDateTime = useWhiteLabelDateConfiguration();

  const currentStatus = executionHubQuery.status;

  const conditionalColumn: GridColDef = {
    flex: 0.5,
    minWidth: 225,
    field: 'canceledOn',
    headerName: 'Canceled On',
    valueGetter: (_, row) => row.allocation?.updatedOn,
    valueFormatter: (value) => configureDateTime(value)?.toFormat(COMBINED_DATE_TIME_ZONE_FORMAT),
  };

  if (currentStatus === ExecutionHubJanneyStatusEnum.active) {
    conditionalColumn.field = 'submittedOn';
    conditionalColumn.headerName = 'Submitted On';
    conditionalColumn.valueGetter = (_, row) => row.allocation?.createdOn;
  } else if (currentStatus === ExecutionHubJanneyStatusEnum.filled) {
    conditionalColumn.field = 'filledOn';
    conditionalColumn.headerName = 'Filled On';
  }

  return [
    ...columns,
    {
      width: 112,
      field: 'orderId',
      headerName: 'order #',
      valueGetter: (_, row) => row.executionOrder?.id,
    },
    {
      minWidth: 100,
      field: 'repCode',
      headerName: 'rep code',
      valueGetter: (_, row) => row.allocation?.account.household,
    },
    {
      flex: 1,
      minWidth: 275,
      field: 'accountName',
      headerName: 'account name',
      valueGetter: (_, row) => row.allocation?.account.name,
    },
    {
      minWidth: 100,
      field: 'accountId',
      headerName: 'account #',
      valueGetter: (_, row) => row.allocation?.account.accountId,
    },
    {
      flex: 0.5,
      minWidth: 175,
      field: 'submitted',
      headerName: 'submitted by',
      valueGetter: (_, row) => row.executionOrder?.orderReceiveFrom,
    },
    conditionalColumn,
    {
      minWidth: 125,
      field: 'category',
      headerName: 'account type',
      translateCell: true,
      valueGetter: (_, row) => row.allocation?.account.designation,
    },
    {
      minWidth: 125,
      field: 'soldPrice',
      align: 'right',
      headerName: 'issuer reoffer',
      valueGetter: (_, row) => {
        if (!row.allocation) return row.reoffer;
        const isBrokerage = row.allocation.account.designation === AccountDesignationEnum.Brokerage;
        return isBrokerage ? row.allocation.soldPrice : row.allocation.boughtPrice;
      },
    },
    {
      align: 'right',
      field: 'quantity',
      headerName: 'quantity',
      valueGetter: (_, row) => row.executionOrder?.quantity ?? row.quantity,
    },
    {
      align: 'right',
      field: 'commission',
      headerName: 'commission',
      sortComparator: (v1, v2) => {
        const v1Numerical = mapLocalCurrencyToNumber(v1);
        const v2Numerical = mapLocalCurrencyToNumber(v2);

        if (v1Numerical === null) return -1;
        if (v2Numerical === null) return 1;

        return v1Numerical > v2Numerical ? 1 : v2Numerical > v1Numerical ? -1 : 0;
      },
      valueGetter: (_, row) => mapNumberToLocalCurrency(row.transactionSummary?.salesCredit),
    },
  ];
};

import { ReactElement, useState, useEffect } from 'react';

import { selectedAuraNoteAtom } from '@halo-atoms/aura';
import { modalAtom } from '@halo-atoms/common';
import { ProductCard } from '@halo-common/components';
import { ProductActionTypeEnum, ProductTypeEnum } from '@halo-common/enums';
import { NoteModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useNoteQuery } from '@halo-data-sources/queries';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { Stack } from '@mui/material';
import { useSetAtom } from 'jotai';

export type AuraNoteProps = {
  id: number;
};

const commonProps = {
  action: ProductActionTypeEnum.watch,
  isLoading: false,
};

export const AuraNote = ({ id }: AuraNoteProps): ReactElement | null => {
  const setModalMap = useSetAtom(modalAtom);
  const setAuraNoteAtom = useSetAtom(selectedAuraNoteAtom);

  const { data: note } = useNoteQuery(id);

  const [isAdded, setIsAdded] = useState(false);

  const handleReceivedMessage = (event: MessageEvent): void => {
    if (event.data) {
      const { note_ids } = JSON.parse(event.data);
      const ids = note_ids.map((noteId: string) => parseInt(noteId));
      setIsAdded(ids.includes(id));
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleReceivedMessage);

    return () => window.removeEventListener('message', handleReceivedMessage);
  }, []);

  const handleAdd = () => {
    setModalMap({ addNote: true });
    setAuraNoteAtom(note as NoteModel);
  };

  const buttonLabel = isAdded ? translations.aura.common.addedLabel : translations.aura.common.addLabel;
  const endIcon = isAdded ? (
    <Iconography iconName="check" color="inherit" />
  ) : (
    <Iconography iconName="arrow-up" color="secondary.main" />
  );

  return (
    <Stack alignItems="center" justifyContent="center">
      <LocalizedButton
        sx={{ mb: 2 }}
        type="button"
        color="secondary"
        {...(!isAdded && { onClick: handleAdd })}
        endIcon={endIcon}
        variant={isAdded ? 'contained' : 'outlined'}
      >
        {buttonLabel}
      </LocalizedButton>
      <ProductCard
        {...commonProps}
        type={ProductTypeEnum.note}
        product={note as NoteModel}
        onClick={(_id, _type, product) => {
          const parsedProduct = product as NoteModel;
          setModalMap({ pdm: true });
          setAuraNoteAtom(parsedProduct);
        }}
      />
    </Stack>
  );
};

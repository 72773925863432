import { Children, ReactElement, ReactNode } from 'react';

import { NoteTypeEnum } from '@halo-common/enums';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Box, Stack } from '@mui/material';

const borderSx = {
  borderLeft: (theme: HaloTheme) => `1px solid ${theme.palette.grey[300]}`,
  paddingLeft: 1.5,
  width: 'fit-content',
};

export type ProductCardDetailsProps = {
  payoff: ReactNode;
  children?: ReactNode;
  type: NoteTypeEnum;
};

export const ProductCardDetails = ({ children, payoff, type }: ProductCardDetailsProps): ReactElement => {
  const payoffContainerSx = {
    paddingRight: 1,
    width: type === NoteTypeEnum.General ? '50%' : 126,
  };

  const fields = Children.toArray(children);

  return (
    <Stack direction="row" alignItems="center">
      <Box sx={payoffContainerSx}>{payoff}</Box>
      <Stack direction="column" sx={borderSx} spacing={1}>
        {fields}
      </Stack>
    </Stack>
  );
};

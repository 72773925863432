import { ReactElement } from 'react';

import { modalAtom, rolesInputSelectionAtom } from '@halo-atoms/common';
import {
  createdUsersAtom,
  isNewOrgAtom,
  userCreationOrganizationAtom,
  userCreationStepAtom,
} from '@halo-atoms/userCreation';
import {
  CreateOrgFormFields,
  createOrgSchema,
  createUserSchema,
  CreateUsersFormFields,
  SelectOrgFormFields,
  selectOrgSchema,
} from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import {
  CreateOrgForm,
  CreateUserForm,
  CreateUserModalFooter,
  CreateUserSuccess,
  SelectOrCreateOrg,
} from '@halo-modules/admin';
import { ElementSelector, Modal } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

export const CreateUserModal = (): ReactElement => {
  const [modalMap, setModalMap] = useAtom(modalAtom);
  const setSelectedOrg = useSetAtom(userCreationOrganizationAtom);
  const setCreatedUsers = useSetAtom(createdUsersAtom);
  const setIsNewOrg = useSetAtom(isNewOrgAtom);
  const step = useAtomValue(userCreationStepAtom.currentPageAtom);
  const handleReset = useSetAtom(userCreationStepAtom.handleResetAtom);
  const setSelectedRoles = useSetAtom(rolesInputSelectionAtom);

  const modalPropList = [
    {
      title: translations.common.organization,
      icon: 'user-plus',
    },
    {
      title: translations.userAndOrgCreation.newOrganization,
      icon: 'user-plus',
    },
    {
      title: translations.userAndOrgCreation.userInformation,
      icon: 'user-plus',
    },
    {
      title: translations.userAndOrgCreation.userCreated,
      icon: 'user-check',
    },
  ];

  const modalProps = modalPropList[step];

  const createUserFormMethods = useForm<CreateUsersFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: yupResolver<CreateUsersFormFields>(createUserSchema),
    defaultValues: { firstName: '', lastName: '', email: '', roles: [] },
  });

  const createOrgFormMethods = useForm<CreateOrgFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: yupResolver<CreateOrgFormFields>(createOrgSchema),
    defaultValues: { name: '', emailSuffix: '', relationshipManager: undefined },
  });

  const selectOrgFormMethods = useForm<SelectOrgFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: yupResolver<SelectOrgFormFields>(selectOrgSchema),
    defaultValues: { organization: undefined },
  });

  const { reset: resetCreateUser, handleSubmit: handleSubmitUser } = createUserFormMethods;
  const { reset: resetCreateOrg, handleSubmit: handleSubmitOrg } = createOrgFormMethods;
  const { reset: resetSelectedOrg, handleSubmit: handleSelectOrg } = selectOrgFormMethods;

  const open = Boolean(modalMap.createUsers);

  const handleModalReset = () => {
    resetSelectedOrg();
    resetCreateUser();
    resetCreateOrg();
    setSelectedOrg(undefined);
    setIsNewOrg(false);
    setCreatedUsers([]);
    setSelectedRoles([]);
    handleReset();
  };

  const handleClose = () => {
    setModalMap({ createUsers: false });
    handleModalReset();
  };

  const handleModalClose = (_: unknown, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  const footer = (
    <CreateUserModalFooter
      onReset={handleModalReset}
      onClose={handleClose}
      onSubmitOrg={handleSubmitOrg}
      onSubmitUser={handleSubmitUser}
      onSelectOrg={handleSelectOrg}
    />
  );

  return (
    <Modal
      {...modalProps}
      open={open}
      onClose={handleModalClose}
      overline={translations.userAndOrgCreation.createNewUser}
      footer={footer}
    >
      <ElementSelector selected={step}>
        <FormProvider {...selectOrgFormMethods}>
          <SelectOrCreateOrg />
        </FormProvider>
        <FormProvider {...createOrgFormMethods}>
          <CreateOrgForm />
        </FormProvider>
        <FormProvider {...createUserFormMethods}>
          <CreateUserForm />
        </FormProvider>
        <CreateUserSuccess />
      </ElementSelector>
    </Modal>
  );
};

import { ReactElement } from 'react';

import { LexicalTypography, ProductTypeChip } from '@halo-common/components';
import { NoteTypeEnum, ProductTypeEnum } from '@halo-common/enums';
import { useMoneyness, useNoteSpecificDetails } from '@halo-common/hooks';
import { NoteModel } from '@halo-common/models';
import { Iconography, LocalizedTypography, Stack, useCombinedStyling } from '@halodomination/halo-fe-common';
import { HaloTheme } from '@halodomination/halo-fe-theme';
import { Chip, Tooltip, Typography } from '@mui/material';
import { capitalize } from 'lodash';

const featureTitleStyling = { marginLeft: 4 };
const noteTypeRowStyling = { pt: 1, pb: 2, px: 0 };
const noteDataRowStyling = { py: 2, px: 0 };
const growthFeatureStyling = { backgroundColor: 'primary.background' };
const incomeFeatureStyling = (theme: HaloTheme) => ({
  backgroundColor: `${theme.palette.common.accent3}30`,
});
const fullWidthStyling = (theme: HaloTheme) => ({
  width: `calc(100% - ${theme.spacing(3)}px)`,
});

export type TackOnHeaderProps = {
  note?: NoteModel;
};

export const TackOnHeader = ({ note }: TackOnHeaderProps): ReactElement => {
  const { highlights: noteSpecificFields } = useNoteSpecificDetails(note);
  const { calculateProtection } = useMoneyness();

  const principalProtectionPercentage = calculateProtection(note?.protectionPercent, note?.protectionType);
  const principalProtectionText = note ? `${principalProtectionPercentage}% ${capitalize(note.protectionType)}` : '';

  const weights = note?.tradableWeights;

  const isIncomeType = note?.type === NoteTypeEnum.Income;
  const isGrowthType = note?.type === NoteTypeEnum.Growth;
  const mergedStyling = [noteTypeRowStyling, fullWidthStyling];
  const mergedRowStyling = [noteDataRowStyling, fullWidthStyling];

  const mergedFeatureStyling = useCombinedStyling(
    isIncomeType ? incomeFeatureStyling : null,
    isGrowthType ? growthFeatureStyling : null,
  );

  const tradablesText = weights ? (
    <Typography align="left">
      {note?.tradables.map(({ id, name }, index) => {
        const weight = weights[name];
        const weightText = index !== note?.tradables.length - 1 ? `${weight}, ` : weight;
        const nameText = `${name} `;
        return (
          <Typography display="inline" variant="subtitle2" key={id}>
            {nameText}
            <Typography display="inline">{weightText}</Typography>
          </Typography>
        );
      })}
    </Typography>
  ) : (
    <Typography align="left">
      {note?.tradables.map(({ id, name }, index) => {
        const nameText = index !== note?.tradables.length - 1 ? `${name}, ` : name;
        return (
          <Typography display="inline" variant="subtitle2" key={id}>
            {nameText}
          </Typography>
        );
      })}
    </Typography>
  );

  const noteFeaturesText = note?.features?.map(({ name, description, dynamicContent }) => {
    const text = description ?? '';

    return (
      <Tooltip key={name} title={text} placement="top" arrow>
        <Chip
          size="small"
          icon={text ? <Iconography prefix="fal" iconName="info-circle" /> : undefined}
          sx={mergedFeatureStyling}
          label={
            <LexicalTypography variant="body2" dynamicContent={dynamicContent}>
              {name}
            </LexicalTypography>
          }
        />
      </Tooltip>
    );
  });

  const noteSpecificFieldsText = noteSpecificFields.map(({ label, text, dynamicContent }) => (
    <Stack key={`${label}-header`} direction="column">
      <LexicalTypography variant="body2">{label}</LexicalTypography>
      <LocalizedTypography variant="h6" dynamicContent={dynamicContent}>
        {text}
      </LocalizedTypography>
    </Stack>
  ));

  return (
    <Stack direction="column">
      <Stack sx={mergedStyling} direction="row">
        <Stack direction="row" alignItems="center" spacing={2}>
          <LexicalTypography translationKey="Type" variant="h6">
            Type:
          </LexicalTypography>
          <ProductTypeChip product={note} type={ProductTypeEnum.note} />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <LexicalTypography sx={featureTitleStyling} translationKey="Features" variant="h6">
            Features:
          </LexicalTypography>
          {noteFeaturesText}
        </Stack>
      </Stack>
      <Stack sx={mergedRowStyling} direction="row" justify="space-between">
        {noteSpecificFieldsText}
        <Stack direction="column">
          <LexicalTypography variant="body2">Principal Protection</LexicalTypography>
          <LexicalTypography translationKey={capitalize(note?.protectionType)} variant="h6">
            {principalProtectionText}
          </LexicalTypography>
        </Stack>
        <Stack direction="column" alignItems="center">
          <LexicalTypography translationKey="Underlyings" variant="body2">
            Underlyings:
          </LexicalTypography>
          {tradablesText}
        </Stack>
        <Stack direction="column">
          <LexicalTypography variant="body2">Term</LexicalTypography>
          <Typography variant="h6">{note?.termInMonths}&nbsp;Months</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

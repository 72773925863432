import { translations } from '@halo-common/translations';
import { object as yupObject, string as yupString, number as yupNumber } from 'yup';

export const createOrgSchema = yupObject().shape({
  name: yupString().required(translations.userAndOrgCreation.orgNameError),
  emailSuffix: yupString()
    .required(translations.userAndOrgCreation.emailSuffixError)
    .matches(/[0-9a-zA-Z]+\.[0-9a-zA-Z]+/g, translations.userAndOrgCreation.emailSuffixError),
  relationshipManager: yupNumber().required(translations.userAndOrgCreation.relationshipManagerError),
});

export type CreateOrgFormFields = {
  name: string;
  emailSuffix: string;
  relationshipManager: number;
};

import { CalendarOrderCategoryEnum } from '@halo-common/enums';
import { useLexiconTranslator, useMoneyness } from '@halo-common/hooks';
import { IssuerModel, NoteModel } from '@halo-common/models';
import { capitalize } from 'lodash';

export type UseNoteDetailHidableValues = 'protection' | 'term' | 'underlyings';
export type UseNoteDetailEmphasisTypes = 'issuer' | 'product' | 'organization';

export type useNoteDetailCaptionOptions = {
  note?: NoteModel;
  category?: CalendarOrderCategoryEnum;
  issuer?: IssuerModel;
  organization?: string;
  hiddenValues?: Array<UseNoteDetailHidableValues>;
  emphasis?: UseNoteDetailEmphasisTypes;
};

export const useNoteDetailCaption = ({
  note,
  category,
  issuer,
  organization,
  emphasis = 'issuer',
  hiddenValues = [],
}: useNoteDetailCaptionOptions): string => {
  const [productType, protectionType] = useLexiconTranslator([note?.productType, note?.protectionType]);
  const { calculateProtection } = useMoneyness();

  const protectionPercent = calculateProtection(note?.protectionPercent, note?.protectionType);

  if (!note || (!issuer && !organization)) return '';

  const subtitleTerm = !hiddenValues.includes('term') ? `${note.termInMonths} Month(s)` : undefined;

  const subtitleUnderlyings = !hiddenValues.includes('underlyings')
    ? note.tradables.map((tradeable) => tradeable.name).join(', ')
    : undefined;

  const subtitleProtection = !hiddenValues.includes('protection')
    ? `${protectionPercent}% ${capitalize(protectionType)}`
    : undefined;

  let details = [issuer?.name, productType, subtitleTerm, subtitleUnderlyings, subtitleProtection];

  if (emphasis === 'product') {
    details = [productType, subtitleTerm, subtitleProtection, issuer?.name];
  } else if (emphasis === 'organization') {
    details = [organization, subtitleTerm, subtitleUnderlyings, subtitleProtection, productType];
  }

  if (category) details.push(capitalize(category));

  return details.filter((value) => Boolean(value)).join(' — ');
};

import { ReactElement, ReactNode } from 'react';

import {
  ProductCardDetailRow,
  ProductCardDetails,
  ProductCardHeader,
  ProductFeatureList,
  UnderlyingGlyphs,
} from '@halo-common/components';
import { ProductTypeEnum } from '@halo-common/enums';
import { NoteSpecificDetailsField } from '@halo-common/hooks';
import { NoteModel } from '@halo-common/models';
import { Stack } from '@mui/material';

const containerLayoutSx = {
  height: '100%',
  minHeight: 280,
};

const featureListSx = {
  justifyContent: 'center',
  minHeight: 33.75,
  mt: 2,
};

export type ProductCardNoteBaseProps = {
  action: ReactNode;
  term: NoteSpecificDetailsField;
  principalProtection?: NoteSpecificDetailsField;
  note: NoteModel;
  highlights: Array<NoteSpecificDetailsField>;
  slots?: {
    payoff?: ReactNode;
  };
};

export const ProductCardNoteBase = ({
  action,
  term,
  note,
  principalProtection,
  highlights,
  slots,
}: ProductCardNoteBaseProps): ReactElement => {
  const { tradables, tradableWeights, features = [], productType, type } = note;

  const termText = term?.text;
  const principalProtectionText = principalProtection?.text ?? '-';
  const scrollable = tradables.length > 4;

  const highlightRows = highlights.map(({ label, text, icon, dynamicContent }) => (
    <ProductCardDetailRow key={label} icon={icon} dynamicContent={dynamicContent}>
      {`${text} ${label}`}
    </ProductCardDetailRow>
  ));

  return (
    <Stack sx={containerLayoutSx} direction="column" justifyContent="space-between">
      <ProductCardHeader action={action} note={note} type={ProductTypeEnum.note} />
      <UnderlyingGlyphs
        underlyings={tradables}
        weights={tradableWeights}
        size="large"
        scrollable={scrollable}
        centered={!scrollable}
      />
      <ProductCardDetails payoff={slots?.payoff} type={type}>
        <ProductCardDetailRow tooltip={term?.label} icon={term?.icon} dynamicContent={term?.dynamicContent}>
          {termText}
        </ProductCardDetailRow>
        <ProductCardDetailRow
          tooltip={principalProtection?.label}
          icon={principalProtection?.icon}
          dynamicContent={principalProtection?.dynamicContent}
        >
          {principalProtectionText}
        </ProductCardDetailRow>
        {highlightRows}
      </ProductCardDetails>
      <ProductFeatureList sx={featureListSx} features={features} type={type} subtype={productType} />
    </Stack>
  );
};

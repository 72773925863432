import { DEFAULT_INFINITE_QUERY_NEXT_PAGINATION } from '@halo-common/constants';
import { SortModelDirectionEnum, SortModelNullSortEnum } from '@halo-common/enums';
import { useHaloInfiniteQuery } from '@halo-common/hooks';
import { InfinitePaginationModel, InfiniteQueryOptions, OrganizationModel } from '@halo-common/models';
import { getOrganizations } from '@halo-data-sources/clients';
import { ApiComparisonOptionEnum } from '@halo-data-sources/enums';
import { ApiOrganizationMapper, PaginationMapper } from '@halo-data-sources/mappers';
import { OrganizationClientQueryKeyFactory } from '@halo-data-sources/queries';

export type GetOrganizationsNextPageParamModel = {
  pagination: InfinitePaginationModel;
};

export type GetOrganizationsInfiniteResult = {
  organizations: Array<OrganizationModel>;
  pagination: InfinitePaginationModel;
};

const organizationsInfiniteQueryFn = async (query?: string, pageParam = DEFAULT_INFINITE_QUERY_NEXT_PAGINATION) => {
  const request = {
    comparisons: [
      {
        field: 'organizations.name',
        op: ApiComparisonOptionEnum.ILIKE,
        value: query ?? '',
      },
    ],
    page: pageParam.next,
    page_length: 20,
    sort: [
      {
        field: 'organizations.name',
        direction: SortModelDirectionEnum.asc,
        nulls: SortModelNullSortEnum.last,
      },
    ],
  };

  const response = await getOrganizations(request);

  const organizations = response.results.map((result) => ApiOrganizationMapper.toOrganization(result.org));
  const pagination = PaginationMapper.toInfinitePaginationModel(response.pagination);

  return { organizations, pagination };
};

export const useOrganizationsInfiniteQuery = (
  payload?: string,
  options?: Partial<InfiniteQueryOptions<GetOrganizationsInfiniteResult>>,
) =>
  useHaloInfiniteQuery<GetOrganizationsInfiniteResult>({
    queryKey: OrganizationClientQueryKeyFactory.infinite(payload),
    queryFn: ({ pageParam }) => organizationsInfiniteQueryFn(payload, pageParam),
    ...options,
  });

import {
  EducationGlossaryTerm,
  EducationModuleGroup,
  EducationQuizAttemptMap,
  EducationTopicDefinition,
  EducationTopicDivider,
  EducationTopicHeader,
  EducationTopicImage,
  EducationTopicList,
  EducationTopicParagraph,
  EducationTopicVideo,
  ModuleModel,
  ModuleQuizAnswerModel,
  ModuleQuizModel,
  ModuleQuizQuestionModel,
  ModuleStepModel,
  ModuleTopicContentType,
  ModuleTopicModel,
  ModuleTopicRows,
  QuizAttemptAnswerModel,
} from '@halo-common/models';
import {
  ApiEducationAnswerModel,
  ApiEducationDefinitionModel,
  ApiEducationDividerModel,
  ApiEducationEducationGlossaryTermModel,
  ApiEducationHeaderModel,
  ApiEducationImageModel,
  ApiEducationJsonDataComponentModel,
  ApiEducationJsonDataContentModel,
  ApiEducationListModel,
  ApiEducationModuleModel,
  ApiEducationParagraphModel,
  ApiEducationQuestionModel,
  ApiEducationQuizModel,
  ApiEducationQuizSubmittedAnswerModel,
  ApiEducationTopicModel,
  ApiEducationVideoModel,
  ApiEductionGroupModel,
  ApiGetEducationQuizAttemptsResponseModel,
} from '@halo-data-sources/models';
import { DateTime } from 'luxon';

export interface ApiEducationMapper {
  toGlossaryTerm(term: ApiEducationEducationGlossaryTermModel): EducationGlossaryTerm;
  toModuleQuizAnswer(answer: ApiEducationAnswerModel): ModuleQuizAnswerModel;
  toModuleQuizQuestion(question: ApiEducationQuestionModel): ModuleQuizQuestionModel;
  toModuleQuiz(quiz: ApiEducationQuizModel, position: number): ModuleQuizModel;
  toEducationTopicDivider(divider: ApiEducationDividerModel): EducationTopicDivider;
  toEducationTopicHeader(header: ApiEducationHeaderModel): EducationTopicHeader;
  toEducationTopicParagraph(paragraph: ApiEducationParagraphModel): EducationTopicParagraph;
  toEducationTopicList(list: ApiEducationListModel): EducationTopicList;
  toEducationTopicDefinition(definition: ApiEducationDefinitionModel): EducationTopicDefinition;
  toEducationTopicImage(image: ApiEducationImageModel): EducationTopicImage;
  toEducationTopicVideo(video: ApiEducationVideoModel): EducationTopicVideo;
  toComponentContentTemplate(template: ApiEducationJsonDataComponentModel): ModuleTopicContentType;
  toModuleTopicRows(data: ApiEducationJsonDataContentModel): ModuleTopicRows;
  toModuleTopic(topic: ApiEducationTopicModel, position: number): ModuleTopicModel;
  toModuleSteps(topics: Array<ApiEducationTopicModel>): Array<ModuleStepModel>;
  toModuleModel(module: ApiEducationModuleModel, position: number): ModuleModel;
  toModules(modules: Array<ApiEducationModuleModel>): Array<ModuleModel>;
  toModuleGroup(group: ApiEductionGroupModel, index: number): EducationModuleGroup;
  toModuleGroups(groups: Array<ApiEductionGroupModel>): Array<EducationModuleGroup>;
  toSubmittedAnswerModel(
    quiz: ApiEducationQuizModel,
    submittedAnswers: Array<ApiEducationQuizSubmittedAnswerModel>,
  ): QuizAttemptAnswerModel;
  toQuizAttemptMap(responses: ApiGetEducationQuizAttemptsResponseModel): EducationQuizAttemptMap;
}

export const ApiEducationMapper: ApiEducationMapper = {
  toGlossaryTerm: (term: ApiEducationEducationGlossaryTermModel): EducationGlossaryTerm => ({
    ...term,
    video: term.video?.path,
  }),
  toModuleQuizAnswer: (answer: ApiEducationAnswerModel): ModuleQuizAnswerModel => ({
    content: answer.body,
    explanation: answer.explain_if_wrong,
    id: answer.id,
    isRight: answer.is_right,
    position: answer.position,
  }),
  toModuleQuizQuestion: (question: ApiEducationQuestionModel): ModuleQuizQuestionModel => ({
    answers: question.answers.map((answer) => ApiEducationMapper.toModuleQuizAnswer(answer)),
    content: question.body,
    id: question.id,
    infoGraphics: question.infographics,
    position: question.position,
    score: question.score,
  }),
  toModuleQuiz: (quiz: ApiEducationQuizModel, position: number): ModuleQuizModel => ({
    completed: quiz.completed,
    description: quiz.description,
    id: quiz.id,
    passThreshold: quiz.pass_threshold,
    position,
    questions: quiz.questions.map((question) => ApiEducationMapper.toModuleQuizQuestion(question)),
    title: quiz.title,
    type: 'quiz',
  }),
  toEducationTopicDivider: (divider: ApiEducationDividerModel): EducationTopicDivider => ({
    type: divider._template,
  }),
  toEducationTopicHeader: (header: ApiEducationHeaderModel): EducationTopicHeader => ({
    type: header._template,
    header: header.header,
  }),
  toEducationTopicParagraph: (paragraph: ApiEducationParagraphModel): EducationTopicParagraph => ({
    type: paragraph._template,
    title: paragraph.title,
    paragraphContent: paragraph.paragraph_content,
    bold: paragraph.bold,
    italicized: paragraph.italicized,
  }),
  toEducationTopicList: (list: ApiEducationListModel): EducationTopicList => ({
    type: list._template,
    title: list.title,
    list: list.list,
    listStyle: list.list_style,
  }),
  toEducationTopicDefinition: (definition: ApiEducationDefinitionModel): EducationTopicDefinition => ({
    type: definition._template,
    icon: definition.fontawesome_icon,
    termName: definition.term_name,
    definitionContent: definition.definition_content,
    includeVideo: definition.include_video === '' ? null : definition.include_video,
  }),
  toEducationTopicImage: (image: ApiEducationImageModel): EducationTopicImage => ({
    type: image._template,
    title: image.title,
    imageUpload: image.image_upload,
    imageMaxWidth: image['image_max-width'],
    imageMaxHeight: image['image_max-height'],
  }),
  toEducationTopicVideo: (video: ApiEducationVideoModel): EducationTopicVideo => ({
    type: video._template,
    title: video.title,
    videoUpload: video.video_upload,
    captionContent: video.caption_content,
  }),
  toComponentContentTemplate: (template: ApiEducationJsonDataComponentModel): ModuleTopicContentType => {
    const type = template._template;

    const mapper = {
      divider: () => ApiEducationMapper.toEducationTopicDivider(template as ApiEducationDividerModel),
      header: () => ApiEducationMapper.toEducationTopicHeader(template as ApiEducationHeaderModel),
      paragraph: () => ApiEducationMapper.toEducationTopicParagraph(template as ApiEducationParagraphModel),
      list: () => ApiEducationMapper.toEducationTopicList(template as ApiEducationListModel),
      definition: () => ApiEducationMapper.toEducationTopicDefinition(template as ApiEducationDefinitionModel),
      image: () => ApiEducationMapper.toEducationTopicImage(template as ApiEducationImageModel),
      video: () => ApiEducationMapper.toEducationTopicVideo(template as ApiEducationVideoModel),
    }[type];

    return mapper?.();
  },
  toModuleTopicRows: (data: ApiEducationJsonDataContentModel): ModuleTopicRows => {
    return data.map((value) => ApiEducationMapper.toComponentContentTemplate(value));
  },
  toModuleTopic: (topic: ApiEducationTopicModel, position: number): ModuleTopicModel => ({
    additionalContent: topic.additional_content,
    completed: topic.completed,
    content: topic?.jsondata?.rows?.map((row) => ApiEducationMapper.toModuleTopicRows(row)) ?? [],
    id: topic.id,
    position,
    title: topic.title,
    type: 'topic',
  }),
  toModuleSteps: (topics: Array<ApiEducationTopicModel>): Array<ModuleStepModel> => {
    return topics
      .sort((a, b) => {
        if (a.position > b.position) return 1;
        else if (a.position < b.position) return -1;
        else return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
      })
      .reduce((topics, topic) => {
        const currentIndex = topics.length;
        const quizzes =
          topic?.quizzes?.map((quiz, quizIndex) =>
            ApiEducationMapper.toModuleQuiz(quiz, currentIndex + quizIndex + 1),
          ) ?? [];

        return [...topics, ApiEducationMapper.toModuleTopic(topic, currentIndex), ...quizzes];
      }, [] as Array<ModuleStepModel>);
  },
  toModuleModel: (module: ApiEducationModuleModel, position: number): ModuleModel => ({
    description: module.description,
    id: module.id,
    position,
    isTracked: module.is_tracked,
    title: module.title,
    steps: ApiEducationMapper.toModuleSteps(module.topics),
  }),
  toModules: (modules: Array<ApiEducationModuleModel>): Array<ModuleModel> => {
    return modules
      .sort((a, b) => {
        if (a.position > b.position) return 1;
        else if (a.position < b.position) return -1;
        else return 0;
      })
      .map((module, index) => ApiEducationMapper.toModuleModel(module, index));
  },
  toModuleGroup: (group: ApiEductionGroupModel, index: number): EducationModuleGroup => ({
    modules: ApiEducationMapper.toModules(group.modules),
    id: group.id,
    position: index,
    description: group.description,
    percentageComplete: group.pct_complete,
    title: group.title,
  }),
  toModuleGroups: (groups: Array<ApiEductionGroupModel>): Array<EducationModuleGroup> => {
    return groups
      .sort((a, b) => {
        if (a.position > b.position) return 1;
        else if (a.position < b.position) return -1;
        else return 0;
      })
      .map((group, index) => ApiEducationMapper.toModuleGroup(group, index));
  },
  toSubmittedAnswerModel: (
    quiz: ApiEducationQuizModel,
    submittedAnswers: Array<ApiEducationQuizSubmittedAnswerModel>,
  ): QuizAttemptAnswerModel => {
    return submittedAnswers.reduce((map, submittedAnswer) => {
      const questionId = submittedAnswer.question_id;
      const question = quiz.questions.find((question) => question.id === questionId);

      const variant = submittedAnswer.selected_variants.shift();
      const isCorrect = Boolean(question?.answers.some(({ id, is_right }) => id === variant?.answer_id && is_right));
      const answer = { submittedAnswer: variant?.answer_id, isCorrect };

      return { ...map, [questionId.toString()]: answer };
    }, {});
  },
  toQuizAttemptMap: (model?: ApiGetEducationQuizAttemptsResponseModel): EducationQuizAttemptMap => {
    if (!model) return {};

    const quiz = model.quiz;
    const lastAttempt = model.attempts
      .sort((a1, b1) => {
        const lastAttemptDate1 = DateTime.fromSQL(a1.quiz_finished);
        const lastAttemptDate2 = DateTime.fromSQL(b1.quiz_finished);
        return lastAttemptDate2 < lastAttemptDate1 ? -1 : lastAttemptDate2 > lastAttemptDate1 ? 1 : 0;
      })
      .shift();

    const submittedAnswers = lastAttempt?.submitted_answers ?? [];
    const answers = lastAttempt ? ApiEducationMapper.toSubmittedAnswerModel(quiz, submittedAnswers) : {};

    return { [model.quiz.id]: answers };
  },
};

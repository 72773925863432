import { DEFAULT_LOCALE } from '@halo-common/constants';
import { OrganizationOnboardingStatusEnum } from '@halo-common/enums';
import { SetPasswordFormData } from '@halo-common/forms';
import { getClientConfiguration, institutionalApiUrl, request } from '@halo-data-sources/clients';
import {
  ApiLoginRequestModel,
  ApiLoginResponseModel,
  ApiLogoutResponseModel,
  ApiSendResetPasswordEmailRequestModel,
  ApiTokenResponseModel,
  AuthCSRFToken,
  AuthConfirmPasswordToken,
} from '@halo-data-sources/models';
import { USER_LOCAL_KEY, USER_TOKEN_KEY } from '@halo-data-sources/queries';
import { SuitabilityPageMeta } from '@halo-pages/app/onboarding/suitability';

const CSRF_TOKEN_KEY = 'csrfToken';
const CONFIRM_PASSWORD_TOKEN_KEY = 'token';
const COMMON_AUTH_URL = '/common/auth';
const COMMON_CSRF_URL = '/common/auth/csrf-token';

export const login = async ({ username, password, next }: ApiLoginRequestModel): Promise<ApiLoginResponseModel> => {
  const clientConfiguration = await getClientConfiguration();

  const requestUrl = next ? `${COMMON_AUTH_URL}?next=${encodeURIComponent(next)}` : COMMON_AUTH_URL;

  const response = await request<ApiLoginResponseModel>(`${clientConfiguration.basePath}${requestUrl}`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  const currentLocale = response.user.current_locale;
  const transifexToken = response.white_label.localization_provider?.transifex_native_token;
  const localizationPublicKey = transifexToken ?? window.localStorage.getItem(USER_TOKEN_KEY);
  const preferredLanguage = currentLocale ?? window.localStorage.getItem(USER_LOCAL_KEY) ?? DEFAULT_LOCALE;

  if (localizationPublicKey) window.localStorage.setItem(USER_TOKEN_KEY, localizationPublicKey);
  if (preferredLanguage) window.localStorage.setItem(USER_LOCAL_KEY, preferredLanguage);

  const onboardingStatus = response.user.organization?.onboarding_status;

  const shouldRedirectToOnboarding = onboardingStatus === OrganizationOnboardingStatusEnum.FORM_NOT_COMPLETED;

  if (shouldRedirectToOnboarding) {
    const userId = String(response.user.id);
    const key = userId + '_hasSuitabilityFormBeenOpenedBefore';
    const hasSuitabilityFormBeenOpenedBefore = window.localStorage.getItem(key);
    if (!hasSuitabilityFormBeenOpenedBefore) {
      response.destination = '/react' + SuitabilityPageMeta.route;
      window.localStorage.setItem(key, 'true');
    }
  }

  return response;
};

export const logout = async (): Promise<ApiLogoutResponseModel> => {
  const clientConfiguration = await getClientConfiguration(COMMON_AUTH_URL);

  const response = await request<ApiLogoutResponseModel>(`${clientConfiguration.basePath}/logout`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });

  removeCsrfToken();

  return response;
};

export const setPassword = async (data: SetPasswordFormData): Promise<void> => {
  const clientConfiguration = await getClientConfiguration();

  const passwordToken = getConfirmPasswordToken();

  await request<void>(`${clientConfiguration.basePath}/forgot-password/set`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      token: passwordToken,
      password: data.password,
      confirm_password: data.confirmPassword,
    }),
  });
};

export const setPasswordV2 = async (data: SetPasswordFormData): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(COMMON_AUTH_URL);

  const passwordToken = getConfirmPasswordToken();

  await request<void>(`${clientConfiguration.basePath}/password/forgot/set`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      _csrf_token: clientConfiguration.csrfToken,
      token: passwordToken,
      password: data.password,
    }),
  });
};

export const sendResetPasswordEmailV2 = async ({ email }: ApiSendResetPasswordEmailRequestModel): Promise<void> => {
  const clientConfiguration = await getClientConfiguration(COMMON_AUTH_URL);

  await request<void>(`${clientConfiguration.basePath}/password/forgot`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      email,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const sendResetPasswordEmail = async ({ email }: ApiSendResetPasswordEmailRequestModel): Promise<void> => {
  const clientConfiguration = await getClientConfiguration();

  await request<void>(`${clientConfiguration.basePath}/forgot-password`, {
    ...clientConfiguration.requestInit,
    method: 'POST',
    body: JSON.stringify({
      email,
      _csrf_token: clientConfiguration.csrfToken,
    }),
  });
};

export const fetchCsrfToken = async (): Promise<string> => {
  const { _csrf_token } = await request<ApiTokenResponseModel>(`${institutionalApiUrl}${COMMON_CSRF_URL}`, {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
  });

  return _csrf_token;
};
export const removeCsrfToken = (): void => window.sessionStorage.removeItem(CSRF_TOKEN_KEY);
export const setCsrfToken = (value: string): void => window.sessionStorage.setItem(CSRF_TOKEN_KEY, value);
export const getCsrfToken = async (): Promise<AuthCSRFToken> => {
  const token = window?.sessionStorage?.getItem(CSRF_TOKEN_KEY);

  if (token) return token;

  const newToken = await fetchCsrfToken();

  setCsrfToken(newToken);

  return newToken;
};

export const setConfirmPasswordToken = (value: string): void =>
  window.sessionStorage.setItem(CONFIRM_PASSWORD_TOKEN_KEY, value);

export const getConfirmPasswordToken = (): AuthConfirmPasswordToken =>
  window.sessionStorage.getItem(CONFIRM_PASSWORD_TOKEN_KEY);

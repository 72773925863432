import { HTMLAttributes, ReactElement, SyntheticEvent, useState } from 'react';

import { useDebounced } from '@halo-common/hooks';
import { UserRelationshipManagerModel } from '@halo-common/models';
import { translations } from '@halo-common/translations';
import { useRelationshipManagersInfiniteQuery } from '@halo-data-sources/queries';
import { LocalizedTextField, LocalizedTextFieldProps } from '@halodomination/halo-fe-common';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Box,
  CircularProgress,
  Typography,
  inputBaseClasses,
} from '@mui/material';

const endAdornmentSx = {
  display: 'flex',
};

const loadingIndicatorSx = {
  marginRight: 1,
};

const clientInputSx = {
  [`.${inputBaseClasses.root}`]: {
    paddingLeft: 2,
  },
};

const optionSx = {
  '&:hover': {
    backgroundColor: 'grey.300',
  },
};

export type RelationshipManagerTypeAheadProps = Omit<
  AutocompleteProps<UserRelationshipManagerModel, boolean, boolean, undefined>,
  'options' | 'renderInput' | 'onChange'
> & {
  onChange?: (option: UserRelationshipManagerModel | Array<UserRelationshipManagerModel> | null) => void;
  TextFieldProps?: LocalizedTextFieldProps;
};

export const RelationshipManagerTypeAhead = ({
  TextFieldProps,
  onChange,
  defaultValue,
  ...props
}: RelationshipManagerTypeAheadProps): ReactElement => {
  const [query, setQuery] = useState('');

  const queryResult = useRelationshipManagersInfiniteQuery(query);

  const { data, isFetched, hasNextPage, fetchNextPage, isPending, isFetchingNextPage } = queryResult;
  const relationshipManagers = data?.pages.flatMap((page) => page.relationshipManagers) ?? [];

  const debouncedSearchHandler = useDebounced(setQuery, 500);

  const handleSearch = (_: unknown, value: string) => {
    debouncedSearchHandler(value);
  };

  const getOptionLabel = (option: UserRelationshipManagerModel) => option.name;

  const handleChange = (
    _: unknown,
    option: UserRelationshipManagerModel | Array<UserRelationshipManagerModel> | null,
  ) => {
    onChange?.(option);
  };

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: UserRelationshipManagerModel) => (
    <Box {...props} sx={optionSx} component="li" key={option.id}>
      <Typography>{option.name}</Typography>
    </Box>
  );

  const noOptionsText = isFetched ? translations.common.noResultsFound : translations.common.searchingEllipsis;

  const renderInput = (params: AutocompleteRenderInputParams) => {
    const textFieldInputLoadingIndicator = isPending ? <CircularProgress sx={loadingIndicatorSx} size={20} /> : null;

    const textFieldInputProps = {
      ...params.InputProps,
      endAdornment: (
        <Box sx={endAdornmentSx}>
          {textFieldInputLoadingIndicator}
          {params.InputProps.endAdornment}
        </Box>
      ),
    };

    return (
      <LocalizedTextField
        {...params}
        label={translations.components.relationshipManagerTypeAhead}
        multiline={props.multiple}
        sx={clientInputSx}
        slotProps={{ input: textFieldInputProps }}
        {...TextFieldProps}
      />
    );
  };

  return (
    <Autocomplete<UserRelationshipManagerModel, boolean, boolean, undefined>
      {...props}
      defaultValue={defaultValue}
      noOptionsText={noOptionsText}
      options={relationshipManagers}
      onChange={handleChange}
      onInputChange={handleSearch}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      renderOption={handleRenderOption}
      ListboxProps={{
        role: 'list-box',
        onScroll: (event: SyntheticEvent) => {
          const listboxNode = event.currentTarget;
          const isBottom = listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight * 0.5;
          const loadMoreContent = !isPending && !isFetchingNextPage && hasNextPage && isBottom;
          if (loadMoreContent) void fetchNextPage();
        },
      }}
    />
  );
};

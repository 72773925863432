import { ReactElement } from 'react';

import { userCreationStepAtom } from '@halo-atoms/userCreation';
import { translations } from '@halo-common/translations';
import { Iconography, LocalizedButton } from '@halodomination/halo-fe-common';
import { useAtomValue, useSetAtom } from 'jotai';

export type CreateUserModalFooterBackButtonProps = {
  onClose: () => void;
};

export const CreateUserModalFooterBackButton = ({
  onClose,
}: CreateUserModalFooterBackButtonProps): ReactElement | null => {
  const step = useAtomValue(userCreationStepAtom.currentPageAtom);
  const handlePrev = useSetAtom(userCreationStepAtom.handlePrevAtom);
  const setStep = useSetAtom(userCreationStepAtom.setCurrentPageAtom);

  const handleBackToOrgSelect = () => {
    setStep(0);
  };

  const orgCreateOrSelectBackButton = (
    <LocalizedButton onClick={onClose} variant="text" color="primary" startIcon={<Iconography iconName="arrow-left" />}>
      {translations.common.back}
    </LocalizedButton>
  );

  const orgCreateButton = (
    <LocalizedButton
      onClick={handlePrev}
      variant="text"
      color="primary"
      startIcon={<Iconography iconName="arrow-left" />}
    >
      {translations.common.back}
    </LocalizedButton>
  );

  const createUserFormBackButton = (
    <LocalizedButton
      onClick={handleBackToOrgSelect}
      variant="text"
      color="primary"
      startIcon={<Iconography iconName="arrow-left" />}
    >
      {translations.common.back}
    </LocalizedButton>
  );

  const backButtons = [orgCreateOrSelectBackButton, orgCreateButton, createUserFormBackButton, null];

  return backButtons[step];
};

import { ReactElement, RefObject } from 'react';

import { UnderlyingCommonAssetChips, UnderlyingTradables, UnderlyingWeightedInputs } from '@halo-common/components';
import { UnderlyingBasketEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';
import { ApiUnderlyingModel, ApiUnderlyingSearchResponseModel } from '@halo-data-sources/index';
import { LocalizedTypography } from '@halodomination/halo-fe-common';
import { Box, ClickAwayListener, Divider, Popper, ToggleButton, ToggleButtonGroup } from '@mui/material';

export type UnderlyingAssetsPopperProps = {
  handleClose: () => void;
  anchorRef: RefObject<HTMLElement>;
  open: boolean;
  selectedAssets: Array<ApiUnderlyingModel>;
  basketType: UnderlyingBasketEnum;
  onBasketToggle: (value: UnderlyingBasketEnum) => void;
  weightedAssets: Record<number, number>;
  onWeightChange: (assetId: number, weight: number) => void;
  onAssetChange: (asset: ApiUnderlyingModel) => void;
  loadingTradables: boolean;
  data: ApiUnderlyingSearchResponseModel | undefined;
};

export const UnderlyingAssetsPopper = ({
  handleClose,
  anchorRef,
  open,
  selectedAssets,
  basketType,
  onBasketToggle,
  weightedAssets,
  onWeightChange,
  onAssetChange,
  loadingTradables,
  data,
}: UnderlyingAssetsPopperProps): ReactElement | null => {
  if (!open) return null;
  const hasSelectedAsset = selectedAssets.length > 1;
  const showWeightedInputs = selectedAssets.length > 1 && basketType === UnderlyingBasketEnum.WEIGHTED;

  const renderWeightedInputs = showWeightedInputs ? (
    <UnderlyingWeightedInputs
      weightedAssets={weightedAssets}
      selectedAssets={selectedAssets}
      onWeightChange={onWeightChange}
    />
  ) : null;

  const options = hasSelectedAsset ? (
    <Box>
      <LocalizedTypography variant="caption" color="text.secondary" sx={{ mb: 1 }}>
        {translations.noteCreate.basketType}
      </LocalizedTypography>
      <Box sx={{ pb: 2 }}>
        <ToggleButtonGroup value={basketType} exclusive onChange={(e, value) => onBasketToggle(value)} sx={{ pt: 1 }}>
          <ToggleButton
            selected={basketType === UnderlyingBasketEnum.WORST_OF}
            defaultChecked
            size="small"
            color="primary"
            type="button"
            value={UnderlyingBasketEnum.WORST_OF}
          >
            <LocalizedTypography
              sx={{
                fontSize: '13px',
                fontWeight: 600,
              }}
            >
              {translations.noteCreate.worstOf}
            </LocalizedTypography>
          </ToggleButton>
          <ToggleButton
            selected={basketType === UnderlyingBasketEnum.WEIGHTED}
            size="small"
            color="primary"
            type="button"
            value={UnderlyingBasketEnum.WEIGHTED}
          >
            <LocalizedTypography
              sx={{
                fontSize: '13px',
                fontWeight: 600,
              }}
            >
              {translations.noteCreate.weighted}
            </LocalizedTypography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {renderWeightedInputs}
      <Divider />
    </Box>
  ) : null;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper
        sx={{
          backgroundColor: 'common.white',
          p: 2,
          boxShadow: 3,
          borderRadius: 1,
          minWidth: '400px',
          maxHeight: '400px',
          overflow: 'auto',
        }}
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-10, 10],
              },
            },
          ],
        }}
      >
        <Box>
          {options}
          <LocalizedTypography variant="caption" color="text.secondary">
            {translations.noteCreate.commonAndTrending}
          </LocalizedTypography>
          <Box sx={{ pt: 1 }}>
            <UnderlyingCommonAssetChips selectedAssets={selectedAssets} handleSelectAsset={onAssetChange} />
          </Box>
          <Divider sx={{ p: 1 }} />
        </Box>
        <UnderlyingTradables
          loadingTradables={loadingTradables}
          data={data}
          selectedAssets={selectedAssets}
          handleSelectAsset={onAssetChange}
        />
      </Popper>
    </ClickAwayListener>
  );
};

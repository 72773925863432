import { ReactElement, useEffect } from 'react';

import { SetPasswordFormData, SetPasswordFormV2 } from '@halo-common/forms';
import { translations } from '@halo-common/translations';
import { useUpdateUserPasswordMutation } from '@halo-data-sources/mutations';
import { Modal } from '@halodomination/halo-fe-common';

export type PasswordChangeFormModalProps = {
  open: boolean;
  onClose: () => void;
};

export const PasswordChangeFormModal = ({ open, onClose }: PasswordChangeFormModalProps): ReactElement => {
  const { mutate, error, reset: resetPasswordMutation } = useUpdateUserPasswordMutation(onClose);

  const modalContentProps = {
    sx: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      py: 3,
      pl: 3,
      pr: 4,
      '& > *': {
        margin: 1,
      },
    },
  };

  const hasError = Boolean(error);
  const errorMessage = translations.profile.password.oldPasswordError;

  const handleSubmit = ({ oldPassword, password: newPassword }: SetPasswordFormData) => {
    mutate({ oldPassword, newPassword });
  };

  useEffect(() => {
    if (!open) resetPasswordMutation();
  }, [open]);

  return (
    <Modal size="small" onClose={onClose} open={open} ContentProps={modalContentProps}>
      <SetPasswordFormV2
        hasTitle
        onSubmit={handleSubmit}
        confirmCurrentPassword
        error={hasError}
        errorMessage={errorMessage}
      />
    </Modal>
  );
};

export enum DisclaimerUrgencyEnum {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  ONLOGIN = 'onlogin',
  ONBOARDING = 'onboarding',
  SMA = 'sma',
}

export enum OnboardingSurveyEnum {
  Google = 'GOOGLE',
  Online = 'ONLINE',
  Webinar = 'WEBINAR',
  InPerson = 'IN_PERSON',
  Email = 'EMAIL',
  SocialMedia = 'SOCIAL_MEDIA',
  Referral = 'REFERRAL',
  Demo = 'DEMO',
  Other = 'OTHER',
}

import { AuraAppModel } from '@halo-common/models/aura';
import { getAuraFrameData } from '@halo-data-sources/clients/aura';
import { ApiAuraAppMapper } from '@halo-data-sources/mappers';
import { AuraQueryKeyFactory } from '@halo-data-sources/queries';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

const fetchAuraFrameDataFn = async () => {
  const response = await getAuraFrameData();

  return ApiAuraAppMapper.toAuraAppModel(response);
};

export const useGetAuraLinkQuery = (): UseQueryResult<AuraAppModel, Error> =>
  useQuery<AuraAppModel, Error>({
    queryKey: AuraQueryKeyFactory.all(),
    queryFn: () => fetchAuraFrameDataFn(),
    refetchOnMount: false,
  });

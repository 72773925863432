import { ReactElement } from 'react';

import { useMoneyness, useNoteSpecificDetails } from '@halo-common/hooks';
import {
  ProductDetailPanelLayout,
  ProductDetailPanelPayoffSection,
  ProductDetailPanelPrincipalSection,
  ProductDetailPanelUnderlyingSection,
} from '@halo-common/layouts';
import { PostTradeNoteDetailIssuerSection, PostTradeNoteDetailLifecycleSection } from '@halo-common/modals';
import { PortfolioOrderModel } from '@halo-common/models';

export type PostTradeNoteDetailModalPanelProps = {
  product?: PortfolioOrderModel | null;
  loading?: boolean;
};

export const PostTradeNoteDetailModalPanel = ({
  product,
  loading = true,
}: PostTradeNoteDetailModalPanelProps): ReactElement => {
  const note = product?.note;

  const {
    calculateProtection,
    formatters: { getProtectionText },
  } = useMoneyness();

  const principalProtectionPercentage = calculateProtection(note?.protectionPercent, note?.protectionType);
  const principalProtectionText = getProtectionText(note?.protectionPercent, note?.protectionType);

  const { payoffDetails, term, protectionDetails } = useNoteSpecificDetails(note, { showParameter: true });

  const localization = {
    protection: { protection: principalProtectionPercentage },
    term: term.dynamicContent,
    gearing: protectionDetails?.[1]?.dynamicContent,
  };

  const principalProtection = { text: principalProtectionText };
  const gearingProtection = protectionDetails?.[1];

  const issuer = product?.termsheet?.issuer;
  const showIssuerSection = loading || issuer;
  const issuerSection = showIssuerSection ? (
    <PostTradeNoteDetailIssuerSection product={product} loading={loading} />
  ) : null;

  const hasPayoffDetails = payoffDetails.length !== 0;
  const showPayoffSection = loading || hasPayoffDetails;
  const payoffSection = showPayoffSection ? (
    <ProductDetailPanelPayoffSection fields={payoffDetails} loading={loading} />
  ) : null;

  const showLifecycleSection = loading || product?.termsheet;
  const lifecycleSection = showLifecycleSection ? (
    <PostTradeNoteDetailLifecycleSection product={product} loading={loading} />
  ) : null;

  const underlyings = product?.note?.tradables;
  const weights = product?.note?.tradableWeights;
  const slots = {
    highlight: issuerSection,
    underlyings: <ProductDetailPanelUnderlyingSection underlyings={underlyings} weights={weights} loading={loading} />,
  };

  return (
    <ProductDetailPanelLayout slots={slots} loading={loading}>
      <ProductDetailPanelPrincipalSection
        term={term?.text}
        gearing={gearingProtection?.text}
        protection={principalProtection?.text}
        protectionType={note?.protectionType}
        currency={note?.currencyCode}
        loading={loading}
        Localization={localization}
      />
      {payoffSection}
      {lifecycleSection}
    </ProductDetailPanelLayout>
  );
};

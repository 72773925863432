import { ReactElement } from 'react';

import { isNewOrgAtom, userCreationOrganizationAtom } from '@halo-atoms/userCreation';
import { translations } from '@halo-common/translations';
import {
  GridRenderCellParams,
  HaloDataGrid,
  HaloDataGridProps,
  Iconography,
  LocalizedAlert,
  LocalizedButton,
  LocalizedTypography,
} from '@halodomination/halo-fe-common';
import { Chip, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';

const alertSx = {
  px: 1.25,
  py: 0.25,
  height: 32,
};

const addRowAlertSx = {
  ...alertSx,
  flex: 11,
};

const addRowButtonSx = {
  width: 'fit-content',
  minWidth: 102,
  flex: 1,
};

const chipSx = {
  backgroundColor: 'primary.background',
  borderRadius: 2,
  fontSize: 12,
  color: 'primary.main',
};

const chipStackSx = {
  height: '100%',
};

type UserModel = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  roles: Array<string>;
};

export const CreateBulkUsersForm = (): ReactElement => {
  const selectedOrg = useAtomValue(userCreationOrganizationAtom);
  const isNewOrg = useAtomValue(isNewOrgAtom);

  const relationshipManager = selectedOrg?.relationshipManager;
  const relationshipManagerName = relationshipManager?.name;

  const orgName = selectedOrg?.name;

  const dynamicContent = { org: selectedOrg?.name };
  const alert = isNewOrg ? (
    <LocalizedAlert sx={alertSx} severity="success" variant="filled" dynamicContent={dynamicContent}>
      {translations.userAndOrgCreation.successOrgCreation}
    </LocalizedAlert>
  ) : null;

  // TODO: Add back in when CSV Upload/Template Download is implemented
  // const uploadIcon = <Iconography iconName="upload" color="common.white" />;
  // const downloadIcon = <Iconography iconName="download" color="primary.main" />;

  const addRowIcon = <Iconography iconName="user-plus" color="common.white" />;

  const columns: HaloDataGridProps<UserModel>['columns'] = [
    {
      field: 'actions',
      headerName: '',
      width: 55,
      disableColumnMenu: true,
      renderCell: () => (
        <IconButton>
          <Iconography iconName="trash" color="primary.main" />
        </IconButton>
      ),
    },
    {
      field: 'email',
      headerName: translations.common.email,
      flex: 1,
      disableColumnMenu: true,
      editable: true,
    },
    {
      field: 'firstName',
      headerName: translations.common.firstName,
      flex: 1,
      disableColumnMenu: true,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: translations.common.lastName,
      flex: 1,
      disableColumnMenu: true,
      editable: true,
    },
    {
      field: 'roles',
      headerName: translations.common.roles,
      flex: 1,
      disableColumnMenu: true,
      editable: true,
      renderCell: ({ value }: GridRenderCellParams<UserModel>) => {
        const chips = value.map((label: string) => {
          return <Chip key={`role-chip-${label}`} sx={chipSx} label={label} size="small" />;
        });

        return (
          <Stack sx={chipStackSx} direction="row" alignItems="center" spacing={1}>
            {chips}
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack direction="column" spacing={3}>
      {alert}
      <Stack direction="row" spacing={4}>
        <Stack direction="column" spacing={1}>
          <LocalizedTypography variant="caption" color="text.secondary">
            {translations.common.organization}
          </LocalizedTypography>
          <Typography variant="subtitle1" color="text.primary">
            {orgName}
          </Typography>
        </Stack>
        <Stack direction="column" spacing={1}>
          <LocalizedTypography variant="caption" color="text.secondary">
            {translations.common.relationshipManager}
          </LocalizedTypography>
          <Typography variant="subtitle1" color="text.primary">
            {relationshipManagerName}
          </Typography>
        </Stack>
      </Stack>
      {/* TODO: Add back in when CSV Upload/Template Download is implemented */}
      {/* <Stack direction="row" spacing={3}>
        <ActionButton color="primary" variant="contained" startIcon={uploadIcon}>
          {translations.components.uploadCsv}
        </ActionButton>
        <ActionButton color="primary" variant="text" startIcon={downloadIcon}>
          {translations.userAndOrgCreation.downloadTemplate}
        </ActionButton>
      </Stack> */}
      <Divider />
      <Stack direction="row" spacing={3} justifyContent="flex-end" alignItems="center">
        <LocalizedAlert sx={addRowAlertSx} variant="filled" severity="info">
          {translations.userAndOrgCreation.rowsEditable}
        </LocalizedAlert>
        <LocalizedButton size="small" sx={addRowButtonSx} variant="contained" startIcon={addRowIcon}>
          {translations.userAndOrgCreation.addRow}
        </LocalizedButton>
      </Stack>
      <HaloDataGrid<UserModel> density="compact" columns={columns} disableColumnSorting />
    </Stack>
  );
};

import { ReactElement } from 'react';

import { modalAtom, rolesInputSelectionAtom } from '@halo-atoms/common';
import {
  createdUsersAtom,
  isNewOrgAtom,
  userCreationOrganizationAtom,
  userCreationStepAtom,
} from '@halo-atoms/userCreation';
import { CreateOrgFormFields, createOrgSchema, SelectOrgFormFields, selectOrgSchema } from '@halo-common/schemas';
import { translations } from '@halo-common/translations';
import {
  CreateBulkUsersForm,
  CreateBulkUsersModalFooter,
  CreateBulkUsersSuccess,
  CreateOrgForm,
  SelectOrCreateOrg,
} from '@halo-modules/admin';
import { ElementSelector, Modal } from '@halodomination/halo-fe-common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';

export const CreateBulkUsersModal = (): ReactElement => {
  const [modalMap, setModalMap] = useAtom(modalAtom);
  const setSelectedOrg = useSetAtom(userCreationOrganizationAtom);
  const setCreatedUsers = useSetAtom(createdUsersAtom);
  const setIsNewOrg = useSetAtom(isNewOrgAtom);
  const step = useAtomValue(userCreationStepAtom.currentPageAtom);
  const handleReset = useSetAtom(userCreationStepAtom.handleResetAtom);
  const setSelectedRoles = useSetAtom(rolesInputSelectionAtom);

  const modalPropList = [
    {
      title: translations.common.organization,
      icon: 'user-plus',
    },
    {
      title: translations.userAndOrgCreation.newOrganization,
      icon: 'user-plus',
    },
    {
      title: translations.userAndOrgCreation.bulkCreateUsers,
    },
    {
      title: translations.userAndOrgCreation.userCreated,
    },
  ];

  const isModalFistTwoSteps = step === 0 || step === 1;
  const overline = isModalFistTwoSteps ? translations.userAndOrgCreation.createNewUser : undefined;
  const modalSize = isModalFistTwoSteps ? 'medium' : 'large';

  const modalProps = modalPropList[step];

  const createOrgFormMethods = useForm<CreateOrgFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(createOrgSchema),
    defaultValues: { name: '', emailSuffix: '', relationshipManager: undefined },
  });

  const selectOrgFormMethods = useForm<SelectOrgFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onSubmit',
    resolver: yupResolver<SelectOrgFormFields>(selectOrgSchema),
    defaultValues: { organization: undefined },
  });

  const { reset: resetCreateOrg, handleSubmit: handleSubmitOrg } = createOrgFormMethods;
  const { reset: resetSelectedOrg, handleSubmit: handleSelectOrg } = selectOrgFormMethods;

  const open = Boolean(modalMap.bulkCreateUsers);

  const handleModalReset = () => {
    resetSelectedOrg();
    resetCreateOrg();
    setSelectedOrg(undefined);
    setIsNewOrg(false);
    setCreatedUsers([]);
    setSelectedRoles([]);
    handleReset();
  };

  const handleClose = () => {
    setModalMap({ bulkCreateUsers: false });
    handleModalReset();
  };

  const handleModalClose = (_: unknown, reason: string) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  const footer = (
    <CreateBulkUsersModalFooter
      onReset={handleModalReset}
      onClose={handleClose}
      onSubmitOrg={handleSubmitOrg}
      onSelectOrg={handleSelectOrg}
    />
  );

  return (
    <Modal {...modalProps} open={open} onClose={handleModalClose} overline={overline} footer={footer} size={modalSize}>
      <ElementSelector selected={step}>
        <FormProvider {...selectOrgFormMethods}>
          <SelectOrCreateOrg />
        </FormProvider>
        <FormProvider {...createOrgFormMethods}>
          <CreateOrgForm />
        </FormProvider>
        <CreateBulkUsersForm />
        <CreateBulkUsersSuccess />
      </ElementSelector>
    </Modal>
  );
};

import { ReactElement } from 'react';

import { EmptyResultsCard, EmptyResultsCardProps } from '@halo-common/components';
import { BrandIcon, Iconography } from '@halodomination/halo-fe-common';

export type AuraNotesListEmptyMessageProps = Partial<EmptyResultsCardProps>;

export const AuraNotesListEmptyMessage = (props: AuraNotesListEmptyMessageProps): ReactElement => {
  const eyeIcon = (
    <span>
      <Iconography iconName="eye" color="text.secondary" prefix="far" size="1.25x" />
    </span>
  );

  const Localization = {
    description: {
      dynamicContent: { eyeIcon },
    },
  };

  return (
    <EmptyResultsCard
      brandIcon={<BrandIcon variant="trackEvents" color="primary" />}
      title={'Aura Notes Will Appear Here'}
      description={'Add notes to your Aura to keep track of your thoughts and ideas.'}
      Localization={Localization}
      {...props}
    />
  );
};

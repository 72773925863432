import { ReactElement } from 'react';

import { HOUR_MINUTE_SECONDS_APM_TIME_ZONE_FORMAT, MONTH_DAY_YEAR_DATE_FORMAT } from '@halo-common/constants';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { translations } from '@halo-common/translations';
import { SMAAllocationModalFormModel } from '@halo-modules/app';
import { Collapsible, LocalizedTextField, LocalizedTypography } from '@halodomination/halo-fe-common';
import { Checkbox, FormControl, FormHelperText, Stack } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';

export const SMAAllocationOrderDetails = (): ReactElement => {
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext<SMAAllocationModalFormModel>();
  const configureDateTime = useWhiteLabelDateConfiguration();

  const date = new Date(Date.now());

  const currentDate = configureDateTime(date.toISOString())?.toFormat(MONTH_DAY_YEAR_DATE_FORMAT);
  const currentTime = configureDateTime(date.toISOString())?.toFormat(HOUR_MINUTE_SECONDS_APM_TIME_ZONE_FORMAT);

  const { field: advisorNameField } = useController({ control, name: 'advisorName' });
  const { field: checkAdv } = useController({ control, name: 'checkAdv' });
  const { field: checkInvestmentObj } = useController({ control, name: 'checkInvestmentObj' });

  const checkAdvErrorExists = !!formErrors.checkAdv;
  const checkInvestmentObjErrorExists = !!formErrors.checkInvestmentObj;
  const eitherCheckboxErrorExists = checkAdvErrorExists || checkInvestmentObjErrorExists;

  const errorSx = { border: 'solid 1px', borderColor: 'error.main', borderRadius: '9px' };

  const investmentSx = checkInvestmentObjErrorExists ? { ...errorSx, mb: checkAdvErrorExists ? 1 : 0 } : {};
  const advSx = checkAdvErrorExists ? errorSx : {};

  const renderCheckboxError = eitherCheckboxErrorExists ? (
    <FormHelperText>
      <LocalizedTypography sx={{ fontSize: '0.75rem' }}>{translations.sma.acceptAcknowledgement}</LocalizedTypography>
    </FormHelperText>
  ) : null;

  return (
    <Collapsible title={translations.sma.orderDetails} sx={{ p: 0 }} HeaderProps={{ sx: { p: 0 } }} defaultExpanded>
      <Stack alignItems="center" direction="row" sx={{ pb: 1 }}>
        <Stack sx={{ pr: 8 }} direction="column">
          <LocalizedTypography variant="caption" color="text.secondary">
            {translations.sma.receiptDateAndTime}
          </LocalizedTypography>
          <Stack direction="row">
            <LocalizedTypography fontWeight="bold" sx={{ pr: 2 }}>
              {currentDate}
            </LocalizedTypography>
            <LocalizedTypography fontWeight="bold">{currentTime}</LocalizedTypography>
          </Stack>
        </Stack>
        <LocalizedTextField {...advisorNameField} label={translations.sma.orderReceivedFrom} sx={{ width: '300px' }} />
      </Stack>
      <FormControl error={eitherCheckboxErrorExists}>
        <Stack direction="column" justifyContent="start">
          <Stack direction="row" alignItems="center" sx={investmentSx}>
            <Checkbox {...checkInvestmentObj} checked={checkInvestmentObj.value ?? false} />
            <LocalizedTypography variant="body2">
              {translations.sma.acceptStrategyInvestmentObjectives}
            </LocalizedTypography>
          </Stack>
          <Stack direction="row" alignItems="center" sx={advSx}>
            <Checkbox {...checkAdv} checked={checkAdv.value ?? false} />
            <LocalizedTypography variant="body2">{translations.sma.acceptStrategyDeliveredADVs}</LocalizedTypography>
          </Stack>
          {renderCheckboxError}
        </Stack>
      </FormControl>
    </Collapsible>
  );
};

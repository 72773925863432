import { ReactElement, useEffect, useRef } from 'react';

import {
  executionHubEditReofferModalAtom,
  executionHubFillingMapAtom,
  executionHubFillModalAtom,
  executionHubPDMModalAtom,
  executionHubQueryAtom,
} from '@halo-atoms/executionHub';
import { ActionButton, V2AdminCalendarCard } from '@halo-common/components';
import { COMBINED_DATE_TIME_ZONE_FORMAT } from '@halo-common/constants';
import { ExecutionHubJanneyStatusEnum } from '@halo-common/enums';
import { useWhiteLabelDateConfiguration } from '@halo-common/hooks';
import { CalendarModel } from '@halo-common/models';
import { useWebSocketContext } from '@halo-common/providers';
import { ExecutionHubOrder } from '@halo-data-sources/queries';
import { useEHOrderColumns } from '@halo-modules/admin';
import { HaloDataGrid, Iconography, useSnackbar } from '@halodomination/halo-fe-common';
import { Button } from '@mui/material';
import { GridPinnedRowsProp } from '@mui/x-data-grid-pro';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateTime } from 'luxon';

import { V2ExecutionHubStatusIndicator } from './V2ExecutionHubStatusIndicator';

const cardSx = {
  m: 2,
};

export type V2ExecutionHubCalendarCardProps = {
  calendar?: CalendarModel;
  orders?: Array<ExecutionHubOrder>;
  setOrderPrice?: number;
  totalQuantity?: number;
};

export const V2ExecutionHubCalendarCard = ({
  calendar,
  orders = [],
  setOrderPrice,
  totalQuantity,
}: V2ExecutionHubCalendarCardProps): ReactElement | null => {
  const cardRef = useRef<HTMLDivElement | null>(null);

  const { events } = useWebSocketContext();
  const { enqueueSuccessEvent, enqueueWarningEvent } = useSnackbar();
  const configureDateTime = useWhiteLabelDateConfiguration();

  const [fillingMap, setFillingMap] = useAtom(executionHubFillingMapAtom);
  const executionHubQuery = useAtomValue(executionHubQueryAtom);
  const setFillModalState = useSetAtom(executionHubFillModalAtom);
  const setPDMModalState = useSetAtom(executionHubPDMModalAtom);
  const setEditModalState = useSetAtom(executionHubEditReofferModalAtom);

  const calendarId = calendar?.id;
  const calendarCusip = calendar?.cusip;
  const closeDateTime = configureDateTime(calendar?.expirationDate);
  const settleDateTime = configureDateTime(calendar?.settleDate);
  const closeDate = closeDateTime?.toFormat(COMBINED_DATE_TIME_ZONE_FORMAT) ?? '-';

  const calendarCardColumns = [{ label: 'Closes', value: closeDate }];

  const footerRow: GridPinnedRowsProp = {
    bottom: [{ id: 'footer', reoffer: `Source ${setOrderPrice}`, quantity: totalQuantity }],
  };

  const isFilling = Boolean(calendarId && fillingMap[calendarId]);
  const isCalendarExpired = !settleDateTime || DateTime.local() > settleDateTime;
  const isCalendarActive = orders.some(({ executionOrder }) => executionOrder.status === 'pending');
  const isFillDisabled = isCalendarExpired || !isCalendarActive;
  const isEditDisabled = isCalendarExpired || executionHubQuery?.status !== ExecutionHubJanneyStatusEnum.active;
  const isOrderQueueEmpty = orders.every((order) => !events.execOrder.pending(order.executionOrder.id));
  const isFinishedFilling = calendarId && fillingMap[calendarId] === true && isOrderQueueEmpty;
  const isFillWarning = isFinishedFilling && orders.some(({ executionOrder }) => executionOrder.status === 'warning');

  const editIconColor = isEditDisabled ? 'text.faded' : 'primary.main';

  const handleFillAll = () => {
    setFillModalState({ open: true, orders, calendar });
  };

  const handleEditReoffer = () => {
    setEditModalState({ open: true, calendar, order: orders[0] });
  };

  const handlePreview = () => {
    setPDMModalState({ open: true, calendar });
  };

  const columns = useEHOrderColumns([
    {
      field: 'status',
      headerName: '',
      width: 112,
      renderCell: ({ row }) =>
        row?.executionOrder ? (
          <V2ExecutionHubStatusIndicator calendar={calendar} order={row} hideAction={isCalendarExpired} />
        ) : null,
    },
  ]);

  const cardActions = [
    <Button
      key={`${calendarId}-preview-button`}
      variant="text"
      color="primary"
      type="button"
      onClick={handlePreview}
      startIcon={<Iconography prefix="fas" iconName="eye" color="primary.main" />}
    >
      Preview
    </Button>,
    <Button
      key={`${calendarId}-edit-reoffer-button`}
      variant="text"
      color="primary"
      type="button"
      disabled={isEditDisabled}
      onClick={handleEditReoffer}
      startIcon={<Iconography prefix="fas" iconName="edit" color={editIconColor} />}
    >
      Edit Reoffer
    </Button>,
    <ActionButton
      key={`${calendarId}-fill-button`}
      variant="outlined"
      color="primary"
      disabled={isFillDisabled}
      onClick={handleFillAll}
      loading={isFilling}
    >
      Fill All Indications
    </ActionButton>,
  ];

  const table = orders?.length ? (
    <HaloDataGrid
      columns={columns}
      rows={orders}
      density="compact"
      pinnedRows={footerRow}
      isCellEditable={() => false}
      initialState={{
        pinnedColumns: {
          left: ['status', 'orderId'],
          right: ['quantity', 'commission'],
        },
      }}
    />
  ) : null;

  useEffect(() => {
    const showSuccessMessage = isFinishedFilling && !isFillWarning;
    const showErrorMessage = isFinishedFilling && isFillWarning;

    if (isFinishedFilling) setFillingMap((prev) => ({ ...prev, [calendarId]: false }));

    if (showSuccessMessage) {
      enqueueSuccessEvent({
        message: 'Filled All Indications',
        subMessage: `CUSIP ${calendarCusip}`,
      });
    } else if (showErrorMessage) {
      enqueueWarningEvent({
        message: 'Indication(s) were not filled.',
        subMessage: `There were errors filling orders on CUSIP ${calendarCusip}`,
        children: (
          <Button
            variant="text"
            endIcon={<Iconography iconName="arrow-right" />}
            onClick={() => {
              cardRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }}
          >
            View Now
          </Button>
        ),
      });
    }
  }, [isFillWarning, isFinishedFilling]);

  return (
    <div ref={cardRef}>
      <V2AdminCalendarCard sx={cardSx} calendar={calendar} actions={cardActions} columns={calendarCardColumns}>
        {table}
      </V2AdminCalendarCard>
    </div>
  );
};

import { ReactElement } from 'react';

import { dynamicSideBarManagerAtom } from '@halo-atoms/common';
import { CollapsibleFilters, GroupedCheckbox } from '@halo-common/components';
import { DynamicTypeFiltersSideBarTitlesDict, PrincipalProtectionSliderFilter } from '@halo-common/layouts';
import { translations } from '@halo-common/translations';
import { Stack } from '@mui/material';
import { useAtom } from 'jotai';

export type PrincipalProtectionFiltersProps = {
  tag: string;
};

export const PrincipalProtectionFilters = ({ tag }: PrincipalProtectionFiltersProps): ReactElement => {
  const [getSideBarFilters, setSideBarFilters] = useAtom(dynamicSideBarManagerAtom);

  const sideBarFilters = getSideBarFilters(tag);

  const handleCheckboxChange = (value: string) => {
    setSideBarFilters({ tag, checkbox: value });
  };

  const principalProtectionTypeCheckboxes = [
    {
      isChecked: sideBarFilters.checkboxes.softProtection,
      value: 'softProtection',
      name: translations.common.softProtection,
    },
    {
      isChecked: sideBarFilters.checkboxes.hardProtection,
      value: 'hardProtection',
      name: translations.common.hardProtection,
    },
    {
      isChecked: sideBarFilters.checkboxes.dailyProtection,
      value: 'dailyProtection',
      name: translations.common.dailyProtection,
    },
    {
      isChecked: sideBarFilters.checkboxes.continuousProtection,
      value: 'continuousProtection',
      name: translations.common.continuousProtection,
    },
  ];

  return (
    <CollapsibleFilters
      title={DynamicTypeFiltersSideBarTitlesDict.principalProtection}
      walkMeClassName="wm-productshelf-filters-principalprotection"
    >
      <Stack direction="column" spacing={2}>
        <PrincipalProtectionSliderFilter tag={tag} />
        <GroupedCheckbox onChange={handleCheckboxChange} values={principalProtectionTypeCheckboxes} />
      </Stack>
    </CollapsibleFilters>
  );
};

import { NoteProtectionTypeEnum } from '@halo-common/enums';
import { translations } from '@halo-common/translations';

import { useCommonUserSetting } from '../useCommonUserSetting';

export type MoneynessResult = {
  calculateProtection: (value?: number | null, protectionType?: NoteProtectionTypeEnum) => number | null;
  calculateCouponProtection: (value?: number | null) => number | null;
  isMoneynessEnabledFor: (protectionType?: NoteProtectionTypeEnum) => boolean;
  convertNMEProtectionToNoteProtectionTypeEnum: (label?: string) => NoteProtectionTypeEnum | undefined;
  formatters: {
    getProtectionText: (value?: number | null, protectionType?: string) => string;
    getCouponProtectionText: (value?: string | number | null) => string;
  };
};

/**
 * useMoneyness provides functions to calculate protection values based on user settings.
 *
 * This hook returns the following functions:
 * - `calculateProtection`: Calculates the protection value based on the provided value and protection type excluding values of 0 and 100.
 * - `calculateCouponProtection`: Calculates the coupon protection value based on the provided value using the "Soft" setting excluding values of 0 and 100.
 * - `isMoneynessEnabledFor`: Checks if moneyness is enabled for the provided protection type.
 * - `convertNMEProtectionToNoteProtectionTypeEnum`: Converts an NME protection label to a NoteProtectionTypeEnum or undefined.
 *   - Passing undefined will return undefined
 *   - Passing a supported string will return the corresponding NoteProtectionTypeEnum
 *   - Passing in an unsupported string will default to 'Hard'
 * - `formatters`: An object containing text formatters to make generating moneyness strings easy.
 *   - `getProtectionText`: Returns the correct protection text based on the provided protection value and type.
 *   - `getCouponProtectionText`: Returns the correct coupon protection text based on the provided protection value.
 *
 * The calculations are influenced by the user's protection display style settings.
 *
 * @returns {MoneynessResult} An object containing the `calculateProtection`, `calculateCouponProtection`, `isMoneynessEnabledFor`,
 * and `convertNMEProtectionToNoteProtectionTypeEnum` utility functions. Plus all text formatters to make generating moneyness
 * strings easy.
 *
 * @example
 * const { calculateProtection, calculateCouponProtection, formatters } = useMoneyness();
 *
 * const protectionValue = calculateProtection(80, NoteProtectionTypeEnum.Hard);
 * // If the user's protection display style setting for 'Hard' is 'MONEYNESS', protectionValue will be 20.
 * // Otherwise, protectionValue will be 80.
 *
 * const couponProtectionValue = calculateCouponProtection(90);
 * // If the user's protection display style setting for 'Soft' is 'MONEYNESS', couponProtectionValue will be 10.
 * // Otherwise, couponProtectionValue will be 90.
 *
 * const protectionString = formatters.getProtectionText(note.protectionPercent, note.protectionType);
 * // If the protection is 0 it will return the no protection string
 * // If the protection is 100 it will return the full protection string
 * // If moneyness is enabled for the given protection type, this will return the correct percentage text
 *
 * const couponProtectionString = formatters.getCouponProtectionText(note.protectionPercent);
 * // If the protection is 0 it will return the no protection string
 * // If the protection is 100 it will return the full protection string
 * // If moneyness is enabled for soft protection, this will return the correct percentage text
 */
export const useMoneyness = (): MoneynessResult => {
  const settingGroup = 'Moneyness';
  const continuous = useCommonUserSetting('continuous_protection_moneyness', settingGroup);
  const daily = useCommonUserSetting('daily_protection_moneyness', settingGroup);
  const hard = useCommonUserSetting('hard_protection_moneyness', settingGroup);
  const soft = useCommonUserSetting('soft_protection_moneyness', settingGroup);

  const protectionDisplayStyleSetting = {
    [NoteProtectionTypeEnum.Continuous]: continuous,
    [NoteProtectionTypeEnum.Daily]: daily,
    [NoteProtectionTypeEnum.Hard]: hard,
    [NoteProtectionTypeEnum.Soft]: soft,
  };

  const calculateProtection = (value: number | null = null, protectionType?: NoteProtectionTypeEnum) => {
    if (value === 0 || value === 100) return value;
    if (value === null || protectionType === undefined) return null;
    if (protectionDisplayStyleSetting?.[protectionType] === true) {
      return 100 - value;
    }
    return value;
  };

  const calculateCouponProtection = (value: number | null = null) => {
    if (value === null) return null;
    if (value === 0 || value === 100) return value;
    if (protectionDisplayStyleSetting?.[NoteProtectionTypeEnum.Soft] === true) {
      return 100 - value;
    }
    return value;
  };

  const isMoneynessEnabledFor = (protectionType?: NoteProtectionTypeEnum) => {
    if (protectionType === undefined) return false;
    return protectionDisplayStyleSetting?.[protectionType] === true;
  };

  const convertNMEProtectionToNoteProtectionTypeEnum = (label?: string) => {
    if (label === undefined) return undefined;
    return (
      {
        [NoteProtectionTypeEnum.Continuous]: NoteProtectionTypeEnum.Continuous,
        [NoteProtectionTypeEnum.Daily]: NoteProtectionTypeEnum.Daily,
        [NoteProtectionTypeEnum.Soft]: NoteProtectionTypeEnum.Soft,
        [NoteProtectionTypeEnum.Hard]: NoteProtectionTypeEnum.Hard,
        floor: NoteProtectionTypeEnum.Hard,
      }[label.toLowerCase()] ?? NoteProtectionTypeEnum.Hard
    );
  };

  const moneynessUtilities = {
    calculateProtection,
    calculateCouponProtection,
    isMoneynessEnabledFor,
    convertNMEProtectionToNoteProtectionTypeEnum,
    formatters: {
      getProtectionText: (value: number | null = null, protectionType?: string) => {
        if (value === 0) return translations.pdm.common.noProtection;
        if (value === 100) return translations.pdm.common.fullProtection;

        const protectionString = convertNMEProtectionToNoteProtectionTypeEnum(protectionType);

        if (protectionString === undefined) return '';

        return {
          [NoteProtectionTypeEnum.Soft]: translations.pdm.common.protectionSoft,
          [NoteProtectionTypeEnum.Hard]: translations.pdm.common.protectionHard,
          [NoteProtectionTypeEnum.Daily]: translations.pdm.common.protectionDaily,
          [NoteProtectionTypeEnum.Continuous]: translations.pdm.common.protectionContinuous,
        }[protectionString];
      },
      getCouponProtectionText: (value: string | number | null = null) => {
        if (value === null) return '';
        if (`${value}` === '0') return translations.common.no;
        if (`${value}` === '100') return translations.common.full;
        return `${value}%`;
      },
    },
  };

  return moneynessUtilities;
};

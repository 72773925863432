import { V2PayoffChartDataModel } from '@halo-common/models';
import { ApiGetV2PayoffDataModel } from '@halo-data-sources/models';

export const mapApiGetV2PayoffDataModelToV2PayoffChartModel = (
  model: ApiGetV2PayoffDataModel,
): V2PayoffChartDataModel => {
  return {
    curveNames: model.curve_names,
    title: model.title,
    x: model.x,
    xLabel: model.x_axis_label,
    yLabel: model.y_axis_label,
    y: model.y,
  };
};

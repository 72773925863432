import { CommonUserSettingGroupSetting, useCommonUserSettingsQuery } from '@halo-data-sources/queries';

/**
 * useCommonUserSetting is a custom hook that retrieves user settings based on the provided field and group.
 *
 * This hook fetches user settings using the `useCommonUserSettingsQuery` and returns the value of the specified field & group.
 *
 * @param {string} field - The field to retrieve the value for.
 * @param {string} group - The group to search within.
 * @returns {CommonUserSettingGroupSetting['value'] | undefined} The value of the specified field, or undefined if not found.
 *
 * @example
 * // Retrieve a setting value
 * const groupSettingValue = useCommonUserSetting('theme', 'appearance');
 * console.log(groupSettingValue); // e.g., 'dark'
 */
export const useCommonUserSetting = (field: string, group: string): CommonUserSettingGroupSetting['value'] => {
  const { data: settings } = useCommonUserSettingsQuery();

  if (!settings) return undefined;

  const groupSettings = settings.settingGroups.find((settingGroup) => settingGroup.name === group);

  if (!groupSettings) return undefined;

  return groupSettings.settings.find((setting) => setting.field === field)?.value;
};

import { ReactElement, ReactNode } from 'react';

import { DocusignBooleanEnum } from '@halo-common/enums/docusign';
import { translations } from '@halo-common/translations';
import { OnboardingSuitabilityForm } from '@halo-modules/app/v2/onboarding/subPages/suitability/components/OnboardingSuitabilityForm';
import type { SuitabilityStepModel } from '@halo-modules/app/v2/onboarding/subPages/suitability/types';
import { LocalizedFormControlLabel } from '@halodomination/halo-fe-common';
import { Radio, RadioGroup, Stack } from '@mui/material';
import { get } from 'lodash';
import { useController } from 'react-hook-form';

interface YesNoStepProps extends SuitabilityStepModel {
  field: string;
  conditionalYesContent?: ReactNode;
  conditionalNoContent?: ReactNode;
}

const YesNoInput = ({
  name,
  conditionalYesContent,
  conditionalNoContent,
}: {
  name: string;
  conditionalYesContent?: ReactNode;
  conditionalNoContent?: ReactNode;
}): ReactElement => {
  const { field } = useController({ name });

  const { value } = field;

  const yesConditional =
    value === DocusignBooleanEnum.YES && conditionalYesContent ? (
      <Stack spacing={3} mt={1} mb={2}>
        {conditionalYesContent}
      </Stack>
    ) : null;

  const noConditional =
    value === DocusignBooleanEnum.NO && conditionalNoContent ? (
      <Stack spacing={3} mt={1} mb={2}>
        {conditionalNoContent}
      </Stack>
    ) : null;

  return (
    <RadioGroup {...field}>
      <LocalizedFormControlLabel value={DocusignBooleanEnum.YES} control={<Radio />} label={translations.common.yes} />
      {yesConditional}
      <LocalizedFormControlLabel value={DocusignBooleanEnum.NO} control={<Radio />} label={translations.common.no} />
      {noConditional}
    </RadioGroup>
  );
};

export const yesNoStep = ({
  field,
  conditionalYesContent,
  conditionalNoContent,
  ...props
}: YesNoStepProps): SuitabilityStepModel => ({
  component: (
    <OnboardingSuitabilityForm>
      <YesNoInput
        name={field}
        conditionalYesContent={conditionalYesContent}
        conditionalNoContent={conditionalNoContent}
      />
    </OnboardingSuitabilityForm>
  ),
  isCompleted: (formData) => {
    const value = get(formData, field);
    return value === DocusignBooleanEnum.YES || value === DocusignBooleanEnum.NO;
  },
  ...props,
});
